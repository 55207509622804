import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CitadelValidationErrors } from '@app/shared/models/citadel-validation-errors.model';
import { momentTransformer } from '../moment-transformer.function';

export const ENDDATE_BEFORE_STARTDATE_MESSAGE: CitadelValidationErrors = {
  endBeforeStartInvalid: {
    title: 'Invalid Date Range',
    message: 'End Date cannot preceed Start Date - Reselect Dates',
    blocking: true,
  },
};

/**
 * @function dateOrderValidator Custom validator endDate is < startDate.
 *
 * @returns null for validation success, ValidationErrors otherwise
 */
export function dateOrderValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate: Date | null = momentTransformer(
      control.get('farStartDate')?.value
    );
    const endDate: Date | null = momentTransformer(
      control.get('farEndDate')?.value
    );

    if (!endDate || !startDate) return null;

    if (endDate < startDate) return ENDDATE_BEFORE_STARTDATE_MESSAGE;

    return null;
  };
}
