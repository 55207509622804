import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * @Deprecated to be removed
 */
@Injectable()
export class ColumnChooserEventObservable {
  private _ColumnChooserObservable$ = new Subject<any>();
  ColumnChooserObservable$: Observable<any> =
    this._ColumnChooserObservable$.asObservable();
}
