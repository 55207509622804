import { NetworkDomain } from '@app/shared/models/network-domain';
import { FVViewRoles } from '@app/shared/models/role-permissions';
import { BaseViewDef } from '../model/config-view-definitions.model';

export interface HomeTabDef extends BaseViewDef {}

export const HOME_TAB: HomeTabDef = {
  viewDefKey: 'home',
  viewName: 'Home',
  pageLink: '/',
  roles: FVViewRoles,

  columns: {},
  tableName: '',
  domains: [NetworkDomain.J, NetworkDomain.U],
};
