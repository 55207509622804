import { FAR_TAB } from '../config/far.config.const';
import { FV_TAB } from '../config/fv.config.const';
import { GROUP_TAB } from '../config/group.config.const';
import { HOME_TAB } from '../config/home.config.const';
import { LOCATIONS_TAB } from '../config/location.config.const';
import { METRICS_TAB } from '../config/metrics.config.const';
import { ORGANIZATION_TAB } from '../config/organization.config.const';
import { SCREENING_TAB } from '../config/screening.config.const';
import { SYSTEM_ALERT_TAB } from '../config/system-alerts.config.const';
import { SYSTEM_MONITORING_TAB } from '../config/system-monitoring.config.const';
import { USER_ADMIN_ACCOUNT_TAB } from '../config/user-admin-account.config.const';
import { USER_ADMIN_APPROVAL_TAB } from '../config/user-admin-approval.config.const';
import { USER_FEEDBACK_TAB } from '../config/user-feedback.config.const';
import { UserPrefViewDefinitions } from '../model/config-view-definitions.model';

export const VIEW_DEF_DEFINITIONS: UserPrefViewDefinitions = {
  home: HOME_TAB,
  fv: FV_TAB,
  far: FAR_TAB,
  screening: SCREENING_TAB,
  group: GROUP_TAB,
  location: LOCATIONS_TAB,
  metrics: METRICS_TAB,
  systemMonitoring: SYSTEM_MONITORING_TAB,
  organization: ORGANIZATION_TAB,
  systemAlert: SYSTEM_ALERT_TAB,
  userAdministration: USER_ADMIN_ACCOUNT_TAB,
  userApproval: USER_ADMIN_APPROVAL_TAB,
  userFeedback: USER_FEEDBACK_TAB,
};

export type VIEW_DEF_KEYS = keyof typeof VIEW_DEF_DEFINITIONS;
