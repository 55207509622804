<div class="my-2">
  <mat-table
    [dataSource]="organization"
    aria-label="'organizations-table"
    #organizationsTable
    multiTemplateDataRows
    matSort
    [matSortActive]="
      filterParams.sort && filterParams.sort.active
        ? filterParams.sort.active
        : ''
    "
    [matSortDirection]="
      filterParams.sort && filterParams.sort.direction
        ? filterParams.sort.direction
        : ''
    "
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.name.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.organization?.showColumn?.name === false"
      >
        {{ ORGANIZATION_COLUMNS.name.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.name === false"
      >
        {{ organization.name | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.shortName.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.organization?.showColumn?.shortName === false"
      >
        {{ ORGANIZATION_COLUMNS.shortName.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center white-arrow"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.shortName === false"
      >
        {{ organization.shortName | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.parentOrg.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.organization?.showColumn?.parentOrg === false"
      >
        {{ ORGANIZATION_COLUMNS.parentOrg.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center white-arrow"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.parentOrg === false"
      >
        {{ organization.parentOrganization?.name | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.subOrgs.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.organization?.showColumn?.subOrgs === false"
      >
        {{ ORGANIZATION_COLUMNS.subOrgs.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center white-arrow"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.subOrgs === false"
      >
        <span *ngIf="!organization.subOrgCount">N/A</span>
        <button
          mat-button
          color="primary"
          *ngIf="organization.subOrgCount > 0"
          matTooltip="Click to Filter"
          (click)="applyParentOrgFilter(organization.id)"
        >
          {{ organization.subOrgCount }}
          <mat-icon iconPositionEnd svgIcon="filter"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.createdDate.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.organization?.showColumn?.createdDate === false"
      >
        {{ ORGANIZATION_COLUMNS.createdDate.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.createdDate === false"
      >
        {{ organization.createdDate | date: "MMM d, y, HH:mm:ss" | default }}
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="ORGANIZATION_COLUMNS.lastModifiedDate.sortField"
    >
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="
          savedPrefs?.organization?.showColumn?.lastModifiedDate === false
        "
      >
        {{ ORGANIZATION_COLUMNS.lastModifiedDate.label }}
      </mat-header-cell>
      <mat-cell
        style="text-align: center"
        class="justify-content-center"
        *matCellDef="let organization"
        [hidden]="
          savedPrefs?.organization?.showColumn?.lastModifiedDate === false
        "
      >
        {{
          organization.lastModifiedDate | date: "MMM d, y, HH:mm:ss" | default
        }}
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="ORGANIZATION_COLUMNS.restrictedAccess.sortField"
    >
      <mat-header-cell
        mat-sort-header
        class="justify-content-center text-align-center white-arrow"
        *matHeaderCellDef
        [hidden]="
          savedPrefs?.organization?.showColumn?.restrictedAccess === false
        "
      >
        {{ ORGANIZATION_COLUMNS.restrictedAccess.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let organization"
        [hidden]="
          savedPrefs?.organization?.showColumn?.restrictedAccess === false
        "
      >
        <div matTooltip="Records view restricted to Organization Users">
          <mat-checkbox
            disabled
            color="primary"
            [(ngModel)]="organization.restrictedAccess"
          ></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.fnRecordCount.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.organization?.showColumn?.fnRecordCount === false"
      >
        {{ ORGANIZATION_COLUMNS.fnRecordCount.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.fnRecordCount === false"
      >
        <span *ngIf="!organization.fnRecordCount">0</span>
        <button
          mat-button
          color="primary"
          matTooltip="Click to View Foreign National records"
          *ngIf="organization.fnRecordCount > 0 && canViewFN"
          (click)="
            navigateToFNs(
              organization.fnRecordCount,
              '../fvs',
              'organizationId',
              organization.id
            )
          "
        >
          {{ organization.fnRecordCount }}
          <mat-icon iconPositionEnd svgIcon="open-in-new"></mat-icon>
        </button>
        <span *ngIf="organization.fnRecordCount > 0 && !canViewFN">{{
          organization.fnRecordCount
        }}</span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.contactsCount.sortField">
      <mat-header-cell
        mat-sort-header
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        [hidden]="savedPrefs?.organization?.showColumn?.contactsCount === false"
      >
        {{ ORGANIZATION_COLUMNS.contactsCount.label }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let organization"
        style="text-align: center"
        class="justify-content-center"
        [hidden]="savedPrefs?.organization?.showColumn?.contactsCount === false"
      >
        <span *ngIf="organization.contacts.length === 0">0</span>
        <button
          mat-button
          color="primary"
          *ngIf="organization.contacts.length > 0"
          matTooltip="Click to Toggle Display"
          (click)="toggleRow(organization)"
        >
          {{ organization.contacts?.length }}
          <mat-icon iconPositionEnd svgIcon="arrow-expand-vertical"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="ORGANIZATION_COLUMNS.actions.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="savedPrefs?.organization?.showColumn?.actions === false"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let organization"
        [hidden]="savedPrefs?.organization?.showColumn?.actions === false"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
        >
          <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            [appContactEdit]="{ organization: { id: organization.id } }"
            (appContactEdited)="refresh()"
            id="add-contact-button"
          >
            <mat-icon svgIcon="plus-circle-outline"></mat-icon> Add Contact
          </button>
          <button
            mat-menu-item
            [appOrganizationEdit]="organization.id"
            (appOrganizationEdited)="onEdited($event)"
            id="view-button"
          >
            <mat-icon svgIcon="square-edit-outline"></mat-icon> Edit
          </button>
          <!--Dev Note: API does a Soft Delete here, may need API update-->
          <!--<button
            mat-menu-item
            (click)="deleteOrganization(organization.id)"
            id="delete-button"
          >
            <mat-icon>delete</mat-icon> Delete
          </button>-->
        </mat-menu>
      </mat-cell>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell
        *matCellDef="let organization"
        [attr.colspan]="dataColumns.length"
      >
        <div
          class="contact-details"
          [@detailExpand]="
            organization === expandedOrg ? 'expanded' : 'collapsed'
          "
        >
          <app-contact-table
            [contacts]="organization.contacts"
            [showActions]="showContactActions"
            (appContactEdited)="refresh()"
          ></app-contact-table>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="dataColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: dataColumns"
      class="organization"
    ></mat-row>
    <mat-row
      class="contacts"
      *matRowDef="let row; columns: ['expandedDetail']"
    ></mat-row>
  </mat-table>
</div>
