import { USER_ADMIN_ACCOUNT_COLUMNS } from '@app/user-preference/config/user-admin-account.config.const';
import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';
import {
  AccountStatus,
  AccountStatuses,
  AccountStatusLabels,
  Role,
} from '@shared/models';

export const AccountsAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Given Name',
    name: 'givenName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Surname',
    name: 'surname',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Email Address',
    name: 'email',
    maxLength: 256,
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Account Status',
    name: 'accountStatus',
    multiple: true,
    options: AccountStatuses,
    optLabel: (opt: AccountStatus) => AccountStatusLabels[opt],
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
    ifRole: [Role.sv_admin, Role.sv_help_desk, Role.sv_org_admin],
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Expiring Soon',
    name: 'expiringSoon',
    tooltip: 'Accounts Expiring within 30 days',
  },
  {
    type: FilterConfigType.USER_ROLE,
    label: 'Role',
    name: 'roles',
    col: 12,
  },
];
