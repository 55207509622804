import { ACTION_COLUMN } from '@app/shared/constants/standard-column.const';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { ScreeningRoles } from '@app/shared/models/role-permissions';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';
import { FV_TABLE_NAME } from './fv.config.const';

export interface ScreeningColumnDefs {
  surname: CitadelColumnDef;
  latestScreeningRequestDate: CitadelColumnDef;
  latestScreeningModifiedDate: CitadelColumnDef;
  latestScreeningCompleteDate: CitadelColumnDef;
  screeningStatus: CitadelColumnDef;
  latestScreeningResult: CitadelColumnDef;
  screeningCount: CitadelColumnDef;
  latestScreeningNotes: CitadelColumnDef;
  networkDomain: CitadelColumnDef;

  // Hidden Columns that may not be needed
  owningOrgShortName: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  citizenshipCountry: CitadelColumnDef;
  residenceCountry: CitadelColumnDef;
  birthDate: CitadelColumnDef;
  passport: CitadelColumnDef;
  linkedVisitorsCount: CitadelColumnDef;

  actions: CitadelColumnDef;
}

export const SCREENING_COLUMNS: ScreeningColumnDefs = {
  surname: {
    label: 'Foreign National',
    hidden: false,
    isSortable: true,
    sortField: 'surname',
    columnIndex: 0,
  },
  latestScreeningRequestDate: {
    label: 'Last Screening Requested',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.requestedDate',
    columnIndex: 1,
  },
  latestScreeningModifiedDate: {
    label: 'Last Screening Modified',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.lastModifiedDate',
    columnIndex: 2,
  },
  latestScreeningCompleteDate: {
    label: 'Last Screening Completed',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.completedDate',
    columnIndex: 3,
  },
  screeningStatus: {
    label: 'Screening Status',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.status',
    columnIndex: 4,
  },
  latestScreeningResult: {
    label: 'Screening Results',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.result',
    columnIndex: 5,
  },
  screeningCount: {
    label: 'Total Screenings',
    columnIndex: 6,
    hidden: false,
    isSortable: true,
    sortField: 'totalScreeningCount',
  },
  latestScreeningNotes: {
    label: 'Last Screening Notes',
    columnIndex: 7,
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.notes',
  },
  networkDomain: {
    label: 'Network',
    hidden: false,
    isSortable: true,
    sortField: 'networkDomain',
    columnIndex: 8,
  },

  // Columns potentially not needed
  owningOrgShortName: {
    label: 'Organization',
    hidden: true,
    isSortable: true,
    sortField: 'owningOrgShortName',
    columnIndex: 50,
  },
  createdDate: {
    label: 'Created Date',
    hidden: true,
    isSortable: true,
    sortField: 'createdDate',
    columnIndex: 51,
  },
  citizenshipCountry: {
    label: 'Citizenship Country',
    hidden: true,
    isSortable: true,
    sortField: 'citizenshipCountry',
    columnIndex: 52,
  },
  residenceCountry: {
    label: 'Residence Country',
    hidden: true,
    isSortable: true,
    sortField: 'residenceCountryObject.name',
    columnIndex: 53,
  },
  birthDate: {
    label: 'Date of Birth',
    hidden: true,
    isSortable: true,
    sortField: 'dateOfBirth',
    columnIndex: 54,
  },
  passport: {
    label: 'Passport',
    hidden: true,
    isSortable: true,
    sortField: 'latestPassportNumber',
    columnIndex: 55,
  },
  linkedVisitorsCount: {
    label: 'Linked Nationals Count',
    hidden: true,
    isSortable: true,
    sortField: 'linkedVisitorCount',
    columnIndex: 56,
  },

  // Actions
  actions: ACTION_COLUMN,
};

export type SCREENING_COLUMN_KEYS = keyof typeof SCREENING_COLUMNS;

export interface ScreeningPrefSaved extends BaseSavedDef {
  showColumn: { [key in SCREENING_COLUMN_KEYS]: boolean };
}

export interface ScreeningTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in SCREENING_COLUMN_KEYS]: CitadelColumnDef };
}

export const SCREENING_TAB: ScreeningTabDef = {
  viewDefKey: 'screening',
  defaultMineOnly: false,
  viewType: 'card',
  columns: SCREENING_COLUMNS,
  viewName: 'Screening Review',
  tableName: FV_TABLE_NAME,
  pageLink: '/screening',
  roles: ScreeningRoles,
  /**
   * @TODO Screening is using the ScreeningRoles, but c/should use roles + domain (J only)
   */
  domains: [NetworkDomain.J, NetworkDomain.U],
};
