<div class="container mt-2">
  <app-release-notes-2-8-2></app-release-notes-2-8-2>
  <app-release-notes-2-8-1></app-release-notes-2-8-1>
  <app-release-notes-2-8-0></app-release-notes-2-8-0>
  <app-release-notes-2-7-10></app-release-notes-2-7-10>
  <app-release-notes-2-7-9></app-release-notes-2-7-9>
  <app-release-notes-2-7-8></app-release-notes-2-7-8>
  <app-release-notes-2-7-7></app-release-notes-2-7-7>
  <app-release-notes-2-7-6></app-release-notes-2-7-6>
  <app-release-notes-2-7-5></app-release-notes-2-7-5>
  <app-release-notes-2-7-4></app-release-notes-2-7-4>
  <app-release-notes-2-7-3></app-release-notes-2-7-3>
  <app-release-notes-2-7-2></app-release-notes-2-7-2>
  <app-release-notes-2-7-1></app-release-notes-2-7-1>
  <app-release-notes-2-7-0></app-release-notes-2-7-0>
  <app-release-notes-2-6-2></app-release-notes-2-6-2>
  <app-release-notes-2-6-1></app-release-notes-2-6-1>
  <app-release-notes-2-6-0></app-release-notes-2-6-0>
  <app-release-notes-2-5-2></app-release-notes-2-5-2>
</div>
