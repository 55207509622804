import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FAR,
  FilterParams,
  ForeignVisitor,
  PageableCollection,
} from '@app/shared/models';
import { environment } from '@environments/environment';
import { FarCommentService } from '@shared/services/comment.service';
import { Crud, CrudService } from '@shared/services/crud.service';
import { FarLocationService } from '@shared/services/farlocation.service';
import { SponsorService } from '@shared/services/sponsor.service';
import { Observable, merge } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FarMetrics } from '../models/far-metrics.model';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/fars`,
  hasIdPathUpdate: true,
  entity: 'Foreign Access Request',
})
export class FarService extends CrudService<FAR> {
  modelChanges$ = merge(
    this.changeEvent$,
    this.farLocationService.changeEvent$,
    this.sponsorService.changeEvent$,
    this.commentService.changeEvent$
  );

  constructor(
    http: HttpClient,
    private farLocationService: FarLocationService,
    private sponsorService: SponsorService,
    private commentService: FarCommentService
  ) {
    super(http);
  }

  /**
   * @param farId
   * @param model
   * @returns
   */
  public addFvToFar(farId: string, fvs: ForeignVisitor[]): Observable<FAR> {
    return this.http
      .put<FAR>(
        `${this.apiUrl}/${farId}/fvs/add`,
        { foreignVisitors: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: farId,
          })
        )
      );
  }

  public removeFvFromFar(
    farId: string,
    fvs: ForeignVisitor[]
  ): Observable<FAR> {
    return this.http
      .put(
        `${this.apiUrl}/${farId}/fvs/remove`,
        { foreignVisitors: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: farId,
          })
        )
      );
  }

  public addGroupToFar(farId: string, fvgs: string[]): Observable<FAR> {
    return this.http.put<FAR>(
      `${this.apiUrl}/${farId}/fvgs/add`,
      { ids: fvgs },
      { context: this.context }
    );
  }

  public get(farId: string): Observable<FAR> {
    return this.http.get<FAR>(`${this.apiUrl}/${farId}`, {}).pipe(
      map((data) => {
        data.foreignAccessRequestMetadata = {};
        data.foreignAccessRequestMetadata.createdByGivenName =
          data.auditMetadata?.createdByGivenName;
        data.foreignAccessRequestMetadata.createdBySurname =
          data.auditMetadata?.createdBySurname;
        data.foreignAccessRequestMetadata.owningOrganizationShortName =
          data.auditMetadata?.owningOrganizationShortName;

        if (data.primaryFar) {
          if (data.primaryFar.organizationLocation) {
            if (data.primaryFar.organizationLocation.address) {
              data.primaryFar.city =
                data.primaryFar.organizationLocation.address.city;
              data.primaryFar.state =
                data.primaryFar.organizationLocation.address.stateProvince;
            }
            data.primaryFar.locationName =
              data.primaryFar.organizationLocation.name;
          }
        }
        return data;
      })
    );
  }

  public changeCancelState(farId: string, state: boolean): Observable<FAR> {
    return this.http.put<FAR>(
      `${this.apiUrl}/${farId}/cancel?isCanceled=${state}`,
      {}
    );
  }

  public find(
    filterParams?: FilterParams
  ): Observable<PageableCollection<FAR>> {
    return this.http.get<PageableCollection<FAR>>(`${this.apiUrl}`, {
      params: filterParams?.httpParams,
    });
  }

  getFarMetrics(): Observable<FarMetrics> {
    return this.http.get<FarMetrics>(`${this.apiUrl}/metrics`);
  }

  compareObjs(objA: any, objB: any): number {
    return objA.toUpperCase().localeCompare(objB.toUpperCase());
  }
}
