import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { hasValue } from '@app/shared/helpers/has-values';
import { User } from '@app/shared/models';
import { AlertService, UserService } from '@app/shared/services';
import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import {
  AuthEventResponse,
  AuthEventType,
  AuthSummary,
} from '@shared/models/auth-event.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { get } from 'lodash';
import { firstValueFrom } from 'rxjs';

export enum ChallengeNameType {
  ADMIN_NO_SRP_AUTH = 'ADMIN_NO_SRP_AUTH',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
  DEVICE_PASSWORD_VERIFIER = 'DEVICE_PASSWORD_VERIFIER',
  DEVICE_SRP_AUTH = 'DEVICE_SRP_AUTH',
  MFA_SETUP = 'MFA_SETUP',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  PASSWORD_VERIFIER = 'PASSWORD_VERIFIER',
  SELECT_MFA_TYPE = 'SELECT_MFA_TYPE',
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export enum AuthFlow {
  INITIAL_LOGIN = 'INITIAL_LOGIN',
  LOGIN_WITH_MFA = 'LOGIN_WITH_MFA',
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  TEMPORARY_PASSWORD = 'TEMPORARY_PASSWORD',
  BLOCKED = 'BLOCKED',
  HELP = 'HELP',
}

//Need to include any attribues that are selected in Cognito User Pool
interface UserAttributes {
  email: string;
  family_name: string;
  given_name: string;
  phone_number: string;
  email_verified?: boolean;
}
// Uses to extend the Cognito user so that we have access to more properties.
interface CitadelCognitoUser extends CognitoUser {
  challengeParam: {
    email?: string;
    requiredAttributes?: string[];
    userAttributes?: UserAttributes;
  };
}

interface Credentials {
  email: string;
  password: string;
}

@Injectable({ providedIn: 'root' })
export class CognitoAuthService extends AuthService {
  cognitoUser: CitadelCognitoUser;
  allowChangePassword: boolean = true;
  private currentCredentials: Credentials;

  constructor(
    protected config: AppConfigService,
    protected userService: UserService,
    protected router: Router,
    protected alert: AlertService
  ) {
    super(config, userService, router);
  }

  protected async _loadUser(): Promise<User> {
    this.cognitoUser = await Auth.currentAuthenticatedUser();
    return super._loadUser();
  }

  protected async _clearUser() {
    await Auth.signOut();
    super._clearUser();
  }

  private _summary: AuthSummary;
  public async getAuthSummary(refresh = false): Promise<AuthSummary> {
    if (this._summary && !refresh) {
      return this._summary;
    }

    const events = await firstValueFrom(this.userService.getAuthEvents());

    const loginEventId = await this.getAuthEventId();
    const loginIdx = events.findIndex((e) => e.id === loginEventId);
    const lastLoginIdx = events.findIndex(
      (e, i) =>
        e.type === AuthEventType.SIGN_IN &&
        e.response === AuthEventResponse.PASS &&
        i > loginIdx
    );
    const lastLogin = lastLoginIdx < 0 ? null : events[lastLoginIdx];

    const failuresSince = events.filter((e, i) => {
      return (
        e.response === AuthEventResponse.FAIL &&
        i > loginIdx &&
        (!lastLogin || i < lastLoginIdx)
      );
    });

    this._summary = {
      loginEventId,
      lastLogin: lastLogin ? lastLogin.createdDate : null,
      failuresSince: failuresSince.length,
    };
    return this._summary;
  }

  private async currentSession(): Promise<CognitoUserSession | null> {
    try {
      return await Auth.currentSession();
    } catch (ex) {
      return null;
    }
  }

  async showLoginInfo(): Promise<void> {
    const summary = await this.getAuthSummary(true);
    this.alert.alertLoginInfo(summary);
  }

  private async _checkSession() {
    let validSession = false;
    try {
      const session = await this.currentSession();
      if (session !== null) {
        validSession = session && session.isValid();
      }
    } catch {}

    if (validSession) {
      this.authenticatedSubject$.next(true);
      await this._loadUser();
      return true;
    }

    this.authenticatedSubject$.next(false);
    return false;
  }

  async init(): Promise<void> {
    Amplify.configure({
      Auth: {
        identityPoolId: this.config.get('identityPoolId'),
        region: this.config.get('awsRegion'),
        userPoolId: this.config.get('userPoolId'),
        userPoolWebClientId: this.config.get('userPoolWebClientId'),
        authenticationFlowType: this.config.get('mfa', true)
          ? 'CUSTOM_AUTH'
          : 'USER_SRP_AUTH',
      },
    });

    await this._checkSession();
  }

  private async getAuthEventId(): Promise<string> {
    return this.getClaim('event_id');
  }

  public async getAccessToken(): Promise<string> {
    const session = await this.currentSession();
    return session?.getAccessToken().getJwtToken() as string;
  }

  public async getIdToken(): Promise<string> {
    const session = await this.currentSession();
    return session?.getIdToken().getJwtToken() as string;
  }

  async getClaim(key: string): Promise<any> {
    const session = await this.currentSession();
    return get(session?.getIdToken()?.payload, key);
  }

  public async signIn(email: string, password?: string) {
    this.cognitoUser = await Auth.signIn(email, password);
    if (this.config.get('mfa', true)) {
      this.currentCredentials = {
        email: email,
        password: password as string,
      };
    }
    if (!this.cognitoUser?.challengeName) {
      await this._checkSession();
    }
    return this.cognitoUser;
  }

  async resendMFA() {
    if (hasValue(this.currentCredentials)) {
      try {
        await this.signIn(
          this.currentCredentials.email,
          this.currentCredentials.password
        );
        this.alert.successAlert('Please check your email for a new MFA code!');
      } catch (err) {
        const msg = err.message || err;
        this.alert.errorAlert(msg);
      }
    }
  }

  //#region Flows
  /**
   * **Flow:** Initial Login Flow
   * @param password - new Password as string
   * @param  reqAttributes - Attributes that Cognito Requires
   * @returns - if the User is Authenticated
   */
  public async answerNewPassword(password: string, reqAttributes: any) {
    this.cognitoUser = await Auth.completeNewPassword(
      this.cognitoUser,
      password,
      reqAttributes
    );

    await this._checkSession();
    this.redirectFromLogin();
    return this.cognitoUser;
  }

  /**
   * **Flow:** Login with MFA
   * @param answer - is a string of 6 digits
   * @returns
   */
  public async answerCustomChallenge(answer: string): Promise<boolean> {
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(
      this.cognitoUser,
      answer
    );
    /*
      If there is a session, Then the authentication has succeded.
      The the current User can be loaded, and the authenticatedSubject$ is set to true.
      Otherwise no user is loaded and the authenticatedSubject$ is set to false.
    */
    return this._checkSession();
  }

  /**
   * **Flow:** Forgot Password
   * initialiates the flow for Forgot Password
   * @param username
   */
  public async forgotPassword(username: string) {
    await Auth.forgotPassword(username);
  }

  /**
   * **Flow:** Forgot Password
   * @param username as an email
   * @param code numeric code as a string
   * @param newPassword password as a string
   */
  public async answerForgotPassword(
    username: string,
    code: string,
    newPassword: string
  ) {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  get canChangePassword(): boolean {
    return this.isAuthenticated();
  }

  /**
   * **Flow:** Change Password
   * @param oldPassword
   * @param newPassword
   * @returns
   */
  public async changePassword(oldPassword: string, newPassword: string) {
    return Auth.changePassword(this.cognitoUser, oldPassword, newPassword);
  }

  /**
   * gets challenge parameters of Cognito User
   * @returns
   */
  public getPublicChallengeParameters() {
    return this.cognitoUser.challengeParam;
  }

  public getRequiredAttributes(): string[] {
    return this.getPublicChallengeParameters().requiredAttributes as string[];
  }

  async login(targetUrl: string = '/') {
    this._storeLoginRedirect(targetUrl);
    await this.router.navigate(['/login']);
  }
  async logout() {
    await this._clearUser();
    await this.login();
    this.alert.successAlert('User Has Successfully Signed Out');
  }
}
