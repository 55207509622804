import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterParams, PageableCollection } from '@app/shared/models';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { CrudService, FvService, Repository } from '@app/shared/services';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ScreeningDrawerObservable } from './screening-drawer-observable.service';

@Component({
  selector: 'app-screening-review',
  templateUrl: 'screening.component.html',
  styleUrls: ['screening.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FvService }],
})
export class ScreeningComponent implements OnInit, OnDestroy {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  showHistory: boolean = false;
  loading: boolean = true;
  userPreference: UserPrefSaved | undefined;
  filterParams = new FilterParams({
    sortBy: 'latestScreening.requestedDate',
    pageSize: 20,
    pageNum: 0,
    screeningEligible: true,
  });
  fvs$: Observable<PageableCollection<ForeignVisitor>>;
  get queryParamsSnapshot() {
    return this.repo.getQueryParamsSnapshot();
  }

  private unSubscribe = new Subject<void>();

  constructor(
    private repo: Repository<ForeignVisitor>,
    private _ScreeningDrawerObservable: ScreeningDrawerObservable
  ) {}

  ngOnInit(): void {
    /**
     * @TODO this is throwing a value change after change detection error.
     * It's probably cleaner to subscribe to the routing changes (as params change)
     * to show/hide the loading component so that this can be removed and centralized
     * for all these collection components.
     *  */
    this.fvs$ = this.repo.collection$.pipe(
      tap((_) => {
        this.loading = false;
      })
    );

    const savedValue: UserPrefSaved =
      this.route.snapshot.data.userPrefFormGroup.value;
    this.filterParams.currentUserCreatedBy =
      savedValue.screening?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);

    this._ScreeningDrawerObservable.ScreeningDrawerObservable$.pipe(
      takeUntil(this.unSubscribe)
    ).subscribe((data: any) => {
      if (data !== 'refresh') this.showHistory = data;
    });
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
