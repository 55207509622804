import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { GroupViewRoles } from '@shared/models/role-permissions';
import { GroupsComponent } from './groups/groups.component';
import { GROUP_TAB } from '@app/user-preference/config/group.config.const';

const routes: Routes = [
  {
    path: 'groups',
    title: 'Groups',
    component: GroupsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: GroupViewRoles,
      viewDefKey: GROUP_TAB.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupsRoutingModule {}
