import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { dateOrderValidator } from './date-rules/date-order.validator';
import { eventDurationValidator } from './date-rules/event-duration.validator';
import { farEligibilityDateValidator } from './date-rules/far-eligibity-date.validator';
import { startExceedsOneYearValidator } from './date-rules/start-exceeds-one-year.validator';
import { CitadelValidationErrors } from '@app/shared/models/citadel-validation-errors.model';

/**
 * @function farDateValidator Custom validator testing testing date conditions for the itinerary
 *
 * @returns null for validation success, ValidationErrors otherwise
 */
export function farDateValidator(
  farScreeningEligibility: boolean | undefined = undefined
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startExceedsOneYearMessage: ValidationErrors | null =
      startExceedsOneYearValidator()(control);
    const endBeforeStartMessage: ValidationErrors | null =
      dateOrderValidator()(control);
    const durationExceedsOneYearMessage: ValidationErrors | null =
      eventDurationValidator()(control);
    const farScreeningEligibilityMessage: CitadelValidationErrors | null =
      farEligibilityDateValidator(farScreeningEligibility)(control);

    if (startExceedsOneYearMessage) {
      return startExceedsOneYearMessage;
    }

    if (endBeforeStartMessage) {
      return endBeforeStartMessage;
    }

    if (durationExceedsOneYearMessage) {
      return durationExceedsOneYearMessage;
    }

    if (farScreeningEligibilityMessage) {
      return farScreeningEligibilityMessage;
    }

    return null;
  };
}
