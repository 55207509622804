import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { LocationAdvancedSearch } from '@app/locations/collection/location-advanced-search';
import { getArrayKeys } from '@app/shared/components/advanced-search/filter-config';
import { FilterParams, OrgLocation } from '@app/shared/models';
import {
  CrudService,
  OrgLocationService,
  Repository,
} from '@app/shared/services';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-location-workspace',
  templateUrl: './location-workspace.component.html',
  styleUrls: ['./location-workspace.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useExisting: OrgLocationService },
  ],
})
export class LocationWorkspaceComponent implements OnInit, AfterContentChecked {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly authService: AuthService = inject(AuthService);

  loading: boolean = false;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
  });
  locations$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  constructor(
    private repo: Repository<OrgLocation>,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    const userId: string = this.authService.getUser()?.id as string;
    this.filterParams.currentUserCreatedBy =
      savedValue?.location?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot(
      ...getArrayKeys(LocationAdvancedSearch)
    );
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
