<div [formGroup]="tabFormGroup" *ngIf="tabFormGroup">
  <div class="py-2 spacing">
    <app-user-preference-column-checkboxes
      #checkboxComponent
      [viewDefKey]="viewDefKey"
      checkboxLayout="checkbox-columns"
    >
    </app-user-preference-column-checkboxes>
  </div>
  <div class="mt-2" [id]="tabId + '_Toggle_All_Buttons'">
    <button
      mat-stroked-button
      color="primary"
      class="mr-2"
      (click)="toggleAll(true)"
    >
      Select All
    </button>
    <button mat-stroked-button color="primary" (click)="toggleAll(false)">
      Deselect All
    </button>
  </div>
  <div
    class="mt-5"
    *ngIf="tabFormGroup.get('viewType')"
    [id]="tabId + '_View_Display_Toggle_Buttons'"
  >
    <mat-label class="ml-2 mt-2 row">
      Select how to display {{ tabTitle }}
    </mat-label>
    <mat-button-toggle-group
      [hideSingleSelectionIndicator]="true"
      class="mt-2 mb-4"
      formControlName="viewType"
    >
      <mat-button-toggle value="card" matTooltip="Card View">
        <mat-icon>apps</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="table" matTooltip="Table View">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div
    class="mt-2"
    [id]="tabId + '_MineOnly_Container'"
    *ngIf="tabFormGroup.get('defaultMineOnly')"
  >
    <mat-label class="ml-2 mt-2 row">
      Limit {{ tabTitle }} records to those created by you
    </mat-label>
    <mat-slide-toggle
      color="primary"
      [id]="tabId + '_MineOnly'"
      formControlName="defaultMineOnly"
      matTooltip="Limit results to your records by default"
    >
      Only Mine
    </mat-slide-toggle>
  </div>
</div>
