import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { Role } from '@app/shared/models';
import { NetworkDomain } from '@app/shared/models/network-domain';
import {
  FarViewRoles,
  GroupViewRoles,
  LocViewRoles,
  OrgViewRoles,
  ScreeningRoles,
  UserApproverRoles,
  UserEditRoles,
  UserFeedbackRoles,
} from '@app/shared/models/role-permissions';
import { AppConfigService } from '@app/shared/services';
import { FAR_TAB, FARPrefSaved } from '../config/far.config.const';
import { FV_TAB, FVPrefSaved } from '../config/fv.config.const';
import { GROUP_TAB, GroupPrefSaved } from '../config/group.config.const';
import {
  LocationPrefSaved,
  LOCATIONS_TAB,
} from '../config/location.config.const';
import {
  ORGANIZATION_TAB,
  OrganizationPrefSaved,
} from '../config/organization.config.const';
import {
  SCREENING_TAB,
  ScreeningPrefSaved,
} from '../config/screening.config.const';
import {
  USER_ADMIN_ACCOUNT_TAB,
  UserAdminAccountPrefSaved,
} from '../config/user-admin-account.config.const';
import {
  USER_ADMIN_APPROVAL_TAB,
  UserAdminApprovalPrefSaved,
} from '../config/user-admin-approval.config.const';
import {
  USER_FEEDBACK_TAB,
  UserFeedbackPrefSaved,
} from '../config/user-feedback.config.const';
import { ViewType } from '../constants/user-preference-tabs.const';
import { VIEW_DEF_KEYS } from '../constants/view-properties.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  UserPrefSaved,
} from '../model/config-view-definitions.model';
/**
 * This service provides an injectable approach to using the UserPreference data and
 * removes the structural code (i.e., the code that allows the UserPreferenceComponent to be rendered) from
 * the data retrieval (i.e., the UserPreferenceService).
 *
 * This service does:
 * 1. Creates the UserPreferenceForm form group
 * 2. Populates the UserPreferenceForm from the data retrieved by the UserPreferenceService
 */
@Injectable({
  providedIn: 'root',
})
export class UserPreferenceFormService {
  formGroup: FormGroup;
  private readonly fb: FormBuilder = inject(FormBuilder);
  private readonly authService: AuthService = inject(AuthService);
  private readonly configService: AppConfigService = inject(AppConfigService);
  private readonly currentNetwork: NetworkDomain = this.configService.get(
    'highEnvFeatures'
  )
    ? NetworkDomain.J
    : NetworkDomain.U;
  constructor() {}

  private initColumnsFormGroup(
    viewDef: BaseViewDef,
    tabSavedPref?: BaseSavedDef
  ): FormGroup {
    const columnFormGroup: FormGroup = this.fb.group({});
    Object.keys(viewDef.columns).forEach((key: string) => {
      const keyOfValue: VIEW_DEF_KEYS = key as VIEW_DEF_KEYS;
      if (tabSavedPref?.showColumn) {
        columnFormGroup.addControl(
          key,
          this.fb.control<boolean>({
            value: tabSavedPref.showColumn[keyOfValue],
            disabled: viewDef.columns[keyOfValue].hidden,
          })
        );
      } else {
        columnFormGroup.addControl(
          key,
          this.fb.control<boolean>({
            value: true,
            disabled: viewDef.columns[keyOfValue].hidden,
          })
        );
      }
    });
    return columnFormGroup;
  }

  private initFVFormGroup(tabSavedPref?: FVPrefSaved): FormGroup {
    const viewType: ViewType = tabSavedPref?.viewType
      ? tabSavedPref.viewType
      : 'card';

    return this.fb.group({
      defaultMineOnly: this.fb.control<boolean>(
        tabSavedPref?.defaultMineOnly ? tabSavedPref.defaultMineOnly : false
      ),
      viewType: this.fb.control<ViewType>(viewType),
      showColumn: this.initColumnsFormGroup(FV_TAB, tabSavedPref),
    });
  }

  private initFARFormGroup(tabSavedPref?: FARPrefSaved): FormGroup {
    const viewType: ViewType = tabSavedPref?.viewType
      ? tabSavedPref.viewType
      : 'card';

    return this.fb.group({
      defaultMineOnly: this.fb.control<boolean | undefined>(
        tabSavedPref?.defaultMineOnly
      ),
      viewType: this.fb.control<ViewType | undefined>(viewType),
      showColumn: this.initColumnsFormGroup(FAR_TAB, tabSavedPref),
    });
  }

  private initGroupFormGroup(tabSavedPref?: GroupPrefSaved): FormGroup {
    return this.fb.group({
      defaultMineOnly: this.fb.control<boolean | null | undefined>(
        tabSavedPref?.defaultMineOnly === false ? false : true // make the default to be true to be consistent with Nov 2024 functionality
      ),
      showColumn: this.initColumnsFormGroup(GROUP_TAB, tabSavedPref),
    });
  }

  private initLocationFormGroup(tabSavedPref?: LocationPrefSaved): FormGroup {
    return this.fb.group({
      defaultMineOnly: this.fb.control<boolean | undefined>(
        tabSavedPref?.defaultMineOnly
      ),
      showColumn: this.initColumnsFormGroup(LOCATIONS_TAB, tabSavedPref),
    });
  }

  private initOrganizationFormGroup(
    tabSavedPref?: OrganizationPrefSaved
  ): FormGroup {
    return this.fb.group({
      showColumn: this.initColumnsFormGroup(ORGANIZATION_TAB, tabSavedPref),
    });
  }

  private initScreeningFormGroup(tabSavedPref?: ScreeningPrefSaved): FormGroup {
    return this.fb.group({
      showColumn: this.initColumnsFormGroup(SCREENING_TAB, tabSavedPref),
      defaultMineOnly: this.fb.control<boolean | undefined>(
        tabSavedPref?.defaultMineOnly
      ),
    });
  }

  private initUserAdminApprovalFormGroup(
    tabSavedPref?: UserAdminApprovalPrefSaved
  ): FormGroup {
    return this.fb.group({
      showColumn: this.initColumnsFormGroup(
        USER_ADMIN_APPROVAL_TAB,
        tabSavedPref
      ),
    });
  }

  private initUserAdminAccountFormGroup(
    tabSavedPref?: UserAdminAccountPrefSaved
  ): FormGroup {
    return this.fb.group({
      showColumn: this.initColumnsFormGroup(
        USER_ADMIN_ACCOUNT_TAB,
        tabSavedPref
      ),
    });
  }

  private initUserFeedbackFormGroup(
    tabSavedPref?: UserFeedbackPrefSaved
  ): FormGroup {
    return this.fb.group({
      showColumn: this.initColumnsFormGroup(USER_FEEDBACK_TAB, tabSavedPref),
    });
  }

  /***
   * Initialize the user preference form group.  This is called by the UserPreferenceFormResolver that
   * supplies the userPreferenceFormGroup where it is needed via the route configuration.
   * @todo make this formgroup more type-safe if posssible.
   */
  initUserPreferenceFormGroup(savedPref?: UserPrefSaved): FormGroup {
    const roles: Role[] = this.authService.getUser()!.roles as Role[];
    const formGroup: FormGroup = this.fb.group({
      defaultRoutePath: this.fb.control<string | undefined>(
        savedPref?.defaultRoutePath
      ),
      fv: this.initFVFormGroup(savedPref?.fv),
    });

    if (roles.some((role: Role) => FarViewRoles.includes(role))) {
      formGroup.addControl('far', this.initFARFormGroup(savedPref?.far));
    }

    if (roles.some((role: Role) => LocViewRoles.includes(role))) {
      formGroup.addControl(
        'location',
        this.initLocationFormGroup(savedPref?.location)
      );
    }

    if (roles.some((role: Role) => GroupViewRoles.includes(role))) {
      formGroup.addControl('group', this.initGroupFormGroup(savedPref?.group));
    }

    if (roles.some((role: Role) => OrgViewRoles.includes(role))) {
      formGroup.addControl(
        'organization',
        this.initOrganizationFormGroup(savedPref?.organization)
      );
    }

    if (roles.some((role: Role) => ScreeningRoles.includes(role))) {
      formGroup.addControl(
        'screening',
        this.initScreeningFormGroup(savedPref?.screening)
      );
    }

    if (roles.some((role: Role) => UserEditRoles.includes(role))) {
      formGroup.addControl(
        'userAdministration',
        this.initUserAdminAccountFormGroup(savedPref?.userAdministration)
      );
    }

    if (roles.some((role: Role) => UserApproverRoles.includes(role))) {
      formGroup.addControl(
        'userApproval',
        this.initUserAdminApprovalFormGroup(savedPref?.userApproval)
      );
    }

    if (roles.some((role: Role) => UserFeedbackRoles.includes(role))) {
      formGroup.addControl(
        'userFeedback',
        this.initUserFeedbackFormGroup(savedPref?.userFeedback)
      );
    }

    return formGroup;
  }

  /**
   * Generate the dataColumns that are needed for the table.
   * This will check the current users roles against those configured
   * as permitted to view the columns.  Columns that are not permitted
   * for the current user or the current domain are filtered out.
   * @param viewDef
   * @returns string[]
   * @todo - generate comprehensive tests for this function!
   */
  generateDataColumns(viewDef: BaseViewDef): string[] {
    const roles: Role[] = this.authService.getUser()?.roles as Role[];

    /**
     * If the user somehow got to a view to that requires roles they lack,
     * return an empty dataColumns array.
     */
    if (!roles.some((role: Role) => viewDef.roles.includes(role))) {
      throw `User not permitted to view this content (403)`;
    }

    return Object.keys(viewDef.columns)
      .filter((key: string) => {
        const keyValue: VIEW_DEF_KEYS = key as VIEW_DEF_KEYS;
        const columnDef: CitadelColumnDef = viewDef.columns[keyValue];
        return (
          columnDef.hidden === false &&
          (!columnDef.restrictedToNetwork ||
            this.currentNetwork === columnDef.restrictedToNetwork) &&
          (!columnDef.requiredRoles ||
            roles.some((role: Role) => columnDef.requiredRoles?.includes(role)))
        );
      })
      .map((key: string) => {
        const keyValue: VIEW_DEF_KEYS = key as VIEW_DEF_KEYS;
        return viewDef.columns[keyValue].sortField;
      });
  }
}
