import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { AdminComponent, NewAccountComponent } from './admin';
import { HomePageComponent } from './home';
import { LandingPageComponent } from './home/landing-page/landing-page.component';
import { SplashPageComponent } from './home/splash-page/splash-page.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { AuthGuard } from './shared/helpers';
import { Roles } from './shared/models';
import { userPreferenceFormResolver } from './user-preference/resolvers/user-preference-form.resolver';
const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
    runGuardsAndResolvers: 'always',
    title: 'Home',
    data: {
      roles: Roles,
    },
  },
  {
    path: 'simple',
    component: LandingPageComponent,
    canActivate: [AuthGuard],
    data: {
      roles: Roles,
    },
  },
  {
    path: 'release-notes',
    title: 'Release Notes',
    component: ReleaseNotesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: Roles,
    },
  },
  {
    path: 'accept',
    title: 'Notice Acceptance',
    // @TODO Why isn't this protected with AuthGuard?
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
    component: SplashPageComponent,
  },
  {
    path: 'user-preferences',
    loadChildren: () =>
      import('./user-preference/user-preference.module').then(
        (m) => m.UserPreferenceModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
