<div id="release-2-7-8" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.8 Release Notes</h3>
  <h6>Group Management</h6>
  <p>
    Groups will be created when Bulk Uploading Foreign Nationals. Users will be
    able to view these groups from the Group Management screen. Bulk Uploaded
    groups cannot be manually edited or deleted.
  </p>

  <p>
    Users can now create their own groups as well. Groups can be created from
    the Group Management and Foreign Nationals screen. Foreign Nationals screen
    will require a user to select FNs to make a group first. Group Management
    screen will allow users to make an empty group and then add via the action
    menu on the created group.
  </p>
  <p>
    Groups can also be rescreened. Any Foreign National that is Rescreen
    eligible will make the group eligible to rescreen (any FNs that are already
    In Progress and part of group going to be rescreened would not be
    rescreened).
  </p>

  <h6>Foreign Access Requests</h6>
  <p>Users can now add Foreign Nationals to FARs by their group.</p>

  <h6>Filter Updates</h6>
  <p>
    Foreign Nationals – can now be filtered on whether or not they have Linked
    Foreign National records.
  </p>
  <p>
    FARs – can now filter on user’s own records, sort by network (card view
    only), filter for records by specific users (Admin, Org Admin, Helpdesk),
    sort by Organization and Visits.
  </p>
</div>
