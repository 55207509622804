<div id="release-2-6-1" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.6.1 Release Notes</h3>
  <h6>Location Table</h6>
  <ul>
    <li>
      Location Table header color and sort arrows have been updated to new
      standard.
    </li>
    <li>Read Only Icon has been updated to new standard.</li>
    <li>
      Far Count, if it has a count greater than zero and the user has the
      privileges, can now be clicked on and the user will be taken to the FAR
      Card page with the location filtered on
    </li>
  </ul>
  <h6>Location Create / Edit Window</h6>
  <p>Add Network Icon to Window Header</p>
  <h6>Foreign National View</h6>
  <ul>
    <li>
      Delayed and Ineligible status have been rolled into the main status and
      result card and should now display like the FN Card does in the Card
      display.
    </li>
    <li>
      The banner that previously displayed Ineligible, Delayed, and Read Only
      has been removed.
    </li>
    <li>
      Read Only has been updated to the new icon standard and placed next to the
      Modifying User Details button (like the FAR View)
    </li>
  </ul>
  <h6>Foreign National Table</h6>
  <p>
    Last Screening Requested and Completed has been removed from the Column
    Chooser
  </p>
  <h6>Foreign Access Request View</h6>
  <ul>
    <li>
      The Add Button in the Foreign Nationals section has been updated to a menu
      with an Add Available and Add New FN options (Note: Add New option is only
      available for users of the same organization as the owning org of the FAR.
      Org Admin or Admins of a different Organization editing the FAR will not
      be allowed to create a new Foreign National from this menu since the
      Owning Org of any Foreign National created would be of a different
      organization).
    </li>
    <li>
      Org Admins and Admins that are editing a FAR with a different org than
      their own can no longer create Locations from Location Select dropdown and
      the locations available to select will be locations of the owning
      organization of the FAR.
    </li>
    <li>
      Org Admins and Admins can no longer assign Foreign Nationals of their
      organization to FARs of a different organization. Only Foreign Nationals
      of the owning organization of the FAR should be available to be assigned.
    </li>
  </ul>
  <h6>Foreign Access Request Creation</h6>
  <ul>
    <li>
      Placeholder text of Event Description field has been updated to give a
      better idea of Purpose.
    </li>
    <li>
      Placeholder text of Visit Details Description field has been updated to
      give a better idea of Purpose.
    </li>
  </ul>
  <h6>Foreign Access Request Card and Table View</h6>
  <p>
    Hover over tooltip for Machine Red has been updated to indicate that it
    requires Human review.
  </p>
  <h6>Metrics</h6>
  <ul>
    <li>
      Screening Results Table now has a Details Section that will give the total
      count of Screenings for the selected time frame.
    </li>
    <li>
      Screening Results by Organization Table has a new button added on the
      right of its toolbar (looks like a bar graph) that when clicked will
      display the total counts of the selected time frame.
    </li>
  </ul>
</div>
