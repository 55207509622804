import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortSelect } from '@app/shared/constants/shared.const';
import { FilterParams } from '@app/shared/models/filter-params.model';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
})
export class SortComponent {
  @Input() sortFields: SortSelect[];
  @Input() filterParams: FilterParams = new FilterParams();
  @Output() filterParamsChange: EventEmitter<FilterParams> = new EventEmitter();

  toggleDirection() {
    this.filterParams.direction = this.isAsc ? 'DESC' : 'ASC';
    this.emitChangeEvent();
  }

  get isAsc(): boolean {
    return this.filterParams?.direction !== 'DESC';
  }

  get activeSort(): string {
    return this.filterParams?.sortBy as string;
  }

  set activeSort(_sort: string) {
    this.filterParams.sortBy = _sort;
    this.emitChangeEvent();
  }

  emitChangeEvent() {
    this.filterParamsChange.emit(this.filterParams);
  }
}
