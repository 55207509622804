import {
  FilterConfig,
  FilterConfigType,
} from '@app/shared/components/advanced-search/filter-config';

export const UserFeedbackAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Given Name',
    name: 'givenName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Surname',
    name: 'surname',
    maxLength: 256,
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Feedback Type',
    name: 'feedbackType',
    multiple: true,
    options: ['comment', 'suggestion', 'question', 'other'],
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Feedback Subject Title',
    name: 'subjectTitle',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Feedback',
    name: 'feedback',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Email Address',
    name: 'email',
    maxLength: 256,
  },
  {
    type: FilterConfigType.DATE,
    label: 'Created Date',
    name: 'createdDate',
    max: new Date(),
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
  },
];
