<div id="release-2-6-0" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.6.0 Release Notes</h3>
  <h5>FAR Enhancements Summary</h5>
  <h6>FAR View</h6>
  <p>The following changes have implemented:</p>
  <ul>
    <li>
      Tabbed Section at the bottom has been broken up, the footer at the bottom
      now only contains Comments.
    </li>
    <li>
      Sponsor, FAR Description and Attachments have been moved to collapsible
      sections on the right side of the page.
    </li>
    <li>
      FAR Description can now be independently updated from the FAR title and
      Purpose Type
    </li>
    <li>
      FAR Itinerary Items Edit and Add will be greyed out and a tooltip message
      explaining what to do: “Please Select a Purpose Type” will be displayed to
      the users on FARs with an Itinerary Item, but lacking a Purpose Type
    </li>
    <li>
      The FAR Event Title and Purpose Type have been moved up into the Toolbar.
    </li>
    <li>User Details will now display to all users regardless of role.</li>
    <li>
      Network domain icon has been added to the toolbar and will display next to
      the User Details icon – viewable by all roles.
    </li>
    <li>
      Read Only Icon has been moved into the toolbar. It will display next to
      the Network Icon.
    </li>
    <li>
      FAR Title and FAR Purpose Type edit button has been moved into the toolbar
      along with the respective data. This data is now independently updated
      from the FAR Description.
    </li>
    <li>
      When Editing a FAR Purpose Type, Options that require Locations will be
      greyed out until Locations are set on all Itinerary items. These options
      will have a tooltip hover over explaining what to do.
    </li>
  </ul>
  <h6>FAR Card</h6>
  <p>
    The FAR Card has been updated to bring it more in line with the FN Card
    changes in terms of look and style. As such, the following changes have
    implemented:
  </p>
  <ul>
    <li>
      A rollup of only Four Foreign Nationals Statuses will be displayed:
      Derogatory, Machine Red, No Derogatory, and Other. Other will contain a
      roll up of Foreign Nationals in any other state that is not the preceding
      three.
    </li>
    <li>Events have been renamed as Visits.</li>
    <li>
      One Visit will always be shown on the Card. The Visit shown if there are
      multiple visits works as follows: the closest visit in the future to the
      current date (if there is a visit on the current date, it will show the
      next date, not the current visit). If there are no visits in the future,
      it will show the last most recent visit available.
    </li>
    <li>
      If there are no Visits available (Legacy Data only), it will show N/A down
      the board in the Visit info section.
    </li>
    <li>
      The Number of Visits a FAR has will always display on the FAR Card event
      if it has none or only one.
    </li>
    <li>
      Sponsor information along with Deletion date has been moved into the Card
      Footer.
    </li>
    <li>
      Sponsor now has two icons depending on whether there is data or not: one
      with a question mark (no data) and one with a check mark (data).
    </li>
    <li>
      If there is Sponsor information available, users can hover over the
      Sponsor Check Icon to view the available sponsor data.
    </li>
    <li>
      Number of Comments and Attachments on the FAR have been added to the FAR
      Footer. Read Only icon will display on the footer as well.
    </li>
    <li>
      Network and the User who created the FAR will now be displayed on the
      card.
    </li>
    <li>
      Owning Organization of the FAR Card has been moved on a line with Network
      and Created by User like the FN Card.
    </li>
    <li>Purpose Type is now a selectable sort from Sort Dropdown</li>
  </ul>
  <h6>FAR Table</h6>
  <ul>
    <li>
      Table Header changed to primary blue color with white text (this is
      intended to be the new standard going forward for tables, and all other
      tables will be updated accordingly in future updates).
    </li>
    <li>
      Status has been changed to Foreign National State. This column can no
      longer be sorted as it wasn’t exactly clear to the user what exactly was
      being sorted on (it was a rollup of the FN Status, ie New, In Process,
      Completed, etc which when clicked could lead to confusion as the sort
      could end up not actually changing anything depending on the rollup
      status).
    </li>
    <li>
      Foreign National State column will display the 4 FN states like the FAR
      Card: Derog, No Derog, Machine Red, and Other.
    </li>
    <li>
      Additional Data column has been added. This will display the number of
      Comments, Attachments, Network Domain, and Deletion Date (if applicable).
    </li>
    <li>
      Locations and Itinerary Dates Columns have been removed. This has been
      replaced with a Visits Column, which will display the number of Visits.
      When an entry is clicked, this will expand into a nested Visits table
      which will display Start and End Date, Location Name, Address, and the
      Owning Organization of the Location.
    </li>
    <li>
      Sponsor column has been updated to show the Sponsor’s Given Name and
      Surname within the column. Clicking will still display the Sponsor’s Email
      and Phone Number.
    </li>
    <li>
      Owning Organization should now display to all users regardless of Role.
    </li>
    <li>Purpose Type is now sortable from the Table Column Header.</li>
  </ul>
  <h6>FAR Advanced Search</h6>
  <p>Purpose Type can now be filtered on.</p>
  <h5>Foreign Nationals Enhancements Summary</h5>
  <p>
    The Foreign Nationals table view has undergone some redesign changes to
    bring it into line with the changes that are being rolled out as part of the
    FAR Table View as part of this release. This is the first part of an
    intended broader redesign effort for Foreign Nationals. Additional Foreign
    National updates are slated to be rolled out in 2.6.1 and beyond.
  </p>
  <h6>Foreign Nationals Table</h6>
  <p>The following changes have implemented:</p>
  <ul>
    <li>
      Table Header changed to primary blue color with white text (this is
      intended to be the new standard going forward for tables, and all other
      tables will be updated accordingly in future updates).
    </li>
    <li>
      The Deletion In and FAR Count columns have been removed, and have been
      replaced with the Additional Data column.
    </li>
    <li>
      Additional Data column has been added. This will display the following
      data:
      <ul>
        <li>Comment Count</li>
        <li>Attachment Count</li>
        <li>Linked Nationals Count</li>
        <li>Count of FARs that Foreign National is attached to</li>
        <li>Deletion Date</li>
        <li>Network</li>
      </ul>
    </li>
    <li>
      Last Screening Requested and Last Screening Completed Columns have been
      removed. This data still can be viewed by clicking on the Screening Status
      button.
    </li>
    <li>
      Screening Status column has been updated to only show Icons with the
      corresponding state. Ineligible and Delayed have been added to the list of
      available icons to be displayed
    </li>
    <li>
      The Completed Icon for Status has been updated to display as the Primary
      Blue Color along with a new corresponding Icon to prevent confusing it
      with the No Derogatory icon for Results.
    </li>
    <li>
      Results column has been updated to show only the Result state icon. Icon
      can be clicked on for additional data.
    </li>
    <li>
      The passport column will now show a Red triangle Alert icon when there is
      no Passport available.
    </li>
    <li>
      The Read Only Icon has been updated to the new Standard. It will continue
      to be displayed in the Foreign National column next to the name.
    </li>
    <li>
      Previous versions of the Ineligible and Delayed icons that displayed with
      the Foreign Nationals name have been removed in favor of the newly
      implemented icons in the Screening Status column that are consistent with
      the new FN Card design.
    </li>
  </ul>
</div>
