<div id="release-2-7-6" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.6 Release Notes</h3>
  <h6>Locations</h6>
  <p>
    Location type ahead feature has been added. When typing an address into
    Address Line 1, suggestions will now be populated in a dropdown menu which
    can be selected from and will auto fill the rest of the information. If a
    country is selected first, auto suggestions will be limited to the country.
    An information icon has been added to address line 1 to explain this.
  </p>
  <p>
    Additionally, any location selected in this manner will automatically have
    latitude and longitude coordinates attached to them.
  </p>
  <ng-container *appIfRoles="Role.sv_admin">
    <p>
      <b>Note:</b> this data is not intended to be displayed to the user (it can
      be viewed by looking at the Network calls in a browser’s Developer tools.
      The ability to view this data may be added for admins only through the UI
      at a later date).
    </p>
    <p>
      Any Location that does not have a Latitude and Longitude assigned will now
      display with an Orange Question mark icon next to it. This icon is
      viewable only for users with the Admin and Helpdesk role (eventually all
      users will be able to see this once location deduplication has been
      completed).
    </p>
  </ng-container>
  <h6>Foreign Access Requests</h6>
  <p>
    The ability to cancel a FAR has been added. A menu option has been added in
    the FAR View Menu. When using it, a window will display requiring a Change
    in Status reason. This reason will be added to the FAR as a Comment. Users
    can also have the ability to uncancel any Canceled FARs, which requires a
    reason for the change in status.
  </p>
  <p>
    <b>Note:</b> once a change in status comment has been made (viewable in the
    comment section of the FAR View), the User who Canceled/Uncanceled a FAR can
    Edit the comment to add additional information or update previously entered
    information.
  </p>
  <p>
    Filters have been added to the FAR Advanced Search to allow a user to Filter
    on Canceled and Non-canceled FARs.
  </p>
  <p>
    Icons have been added to the FAR Card, Table, and View pages denoting if the
    FAR is Canceled or not. 
  </p>
</div>
