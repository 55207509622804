<div id="release-2-7-5" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.5 Release Notes</h3>
  <ng-container *appIfRoles="[Role.sv_admin, Role.sv_help_desk]">
    <h6>System Monitoring</h6>
    <p>
      A new page has been added to the application that is available to Admins
      and Helpdesk. This page, viewable on both high and low sides, will allow
      these users to be able to see transactions within the application itself
      as well as any potential outstanding issues that occur within application
      such as delayed responses or potential API errors that normally only
      display within the server logs.
    </p>
  </ng-container>
  <h6>FAR Advanced Search</h6>
  <p>
    Location selection dropdown has been added to FAR Advanced Filter. This will
    allow the user to select one existing location to filter on for FARs.
  </p>
</div>
