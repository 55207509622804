import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  FilterParams,
  ForeignVisitor,
  Role,
  ScreeningDateFormat,
} from '@app/shared/models';
import { NetworkDomain } from '@app/shared/models/network-domain';
import {
  FV_COLUMNS,
  FV_TAB,
} from '@app/user-preference/config/fv.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { getName } from 'i18n-iso-countries';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-fv-table',
  templateUrl: './fv-table.component.html',
  styleUrls: ['./fv-table.component.scss'],
})
export class FvTableComponent implements OnInit, OnDestroy {
  @Input() fvs: ForeignVisitor[] = [];
  @Input() filterParams: FilterParams;
  @Input() farScreeningEligibility: boolean = false;
  @Input() currentNetwork: NetworkDomain = NetworkDomain.U;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  private userPrefFormGroup$!: Subscription;
  currentOrgId: string;
  Role = Role;
  ScreeningDateFormat = ScreeningDateFormat;
  @Input() groupSelectionMode: boolean = false;

  FV_COLUMNS = FV_COLUMNS;
  savedPrefs?: UserPrefSaved;
  dataColumns: string[] = [
    'select',
    ...inject(UserPreferenceFormService).generateDataColumns(FV_TAB),
  ];

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.currentOrgId = this.auth.getOrganization()?.id!;
  }

  viewRow(id: string) {
    this.router.navigateByUrl(`fv/${id}`);
  }

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  hasDocumentData(fv: ForeignVisitor) {
    if (fv?.passports && fv.passports[0]) return true;
    if (fv?.visas && fv.visas[0]) return true;
    if (fv?.diplomaticIds && fv?.diplomaticIds[0]) return true;
    if (fv?.dacaIds && fv.dacaIds[0]) return true;
    if (fv?.nationalIds && fv.nationalIds[0]) return true;
    return false;
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  getCountryName(country: string) {
    if (country) return getName(country, 'en');
    return null;
  }

  isAllSelected() {
    const numSelected = this.getNumSelected();
    const numSelectableRows = this.fvs.filter(
      (fv) => !this.isSelectDisabled(fv)
    ).length;
    return numSelected === numSelectableRows;
  }

  isSelectDisabled(fn: ForeignVisitor) {
    return (
      fn.networkDomain !== this.currentNetwork ||
      fn.ownerOrganizationId !== this.currentOrgId
    );
  }

  getNumSelected() {
    let cnt = 0;
    for (let fn of this.fvs) if (fn.selected) ++cnt;
    return cnt;
  }

  setSelection(isSelected: boolean) {
    for (let fn of this.fvs) {
      if (!this.isSelectDisabled(fn)) fn.selected = isSelected;
    }
  }

  toggleSelection(fv: ForeignVisitor) {
    if (!this.isSelectDisabled(fv)) fv.selected = !fv.selected;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) this.setSelection(false);
    else this.setSelection(true);
  }
}
