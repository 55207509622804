<div
  class="fn-card__metadata component text-primary"
  [ngClass]="tableDisplay ? 'background-white' : ''"
>
  <div class="d-flex justify-content-around pt-2" *ngIf="!tableDisplay">
    <app-field label="created by" [value]="createdByFullName"></app-field>
    <app-field
      label="organization"
      [value]="owningOrgShortName"
      [matTooltip]="owningOrgName"
      matTooltipPositionAtOrigin
    ></app-field>
    <app-field label="network" [value]="networkDomain"></app-field>
  </div>
  <div class="meta-icon-list d-flex pt-3 px-2 justify-content-between">
    <div
      class="meta-icon-list__item read-only"
      *ngIf="!far.isEditable && !tableDisplay"
    >
      <mat-icon svgIcon="pencil-off-outline" matTooltip="Read Only"></mat-icon>
    </div>
    <div class="flex-fill" *ngIf="!tableDisplay"></div>
    <div class="meta-icon-list__item">
      <mat-icon
        [svgIcon]="far.isActiveFar ? 'calendar-check' : 'calendar-remove'"
        [matTooltip]="far.isActiveFar ? 'FAR is Active' : 'FAR is Inactive'"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <!--Sponsor Details-->
    <ng-container *ngIf="!tableDisplay">
      <div
        class="meta-icon-list__item"
        *ngIf="far.sponsor?.givenName || far.sponsor?.surname"
        style="margin-right: -5px"
        matTooltip="{{ far.sponsor?.givenName }} {{ far.sponsor?.surname }}&#13;
      {{ far.sponsor?.phone }}&#13;
      {{ far.sponsor?.email }}"
        matTooltipClass="sponsor-tooltip"
      >
        <mat-icon svgIcon="account-check" aria-hidden="false"></mat-icon>
      </div>
      <div
        class="meta-icon-list__item"
        *ngIf="!far.sponsor?.givenName && !far.sponsor?.surname"
        style="margin-right: -5px"
      >
        <mat-icon
          svgIcon="account-question"
          matTooltip="No Sponsor Details Available"
          aria-hidden="false"
        ></mat-icon>
      </div>
    </ng-container>
    <div class="meta-icon-list__item" *ngIf="tableDisplay">
      <mat-icon
        [svgIcon]="'alpha-' + far.networkDomain?.toLowerCase() + '-box'"
        matTooltip="Network"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div class="meta-icon-list__item" [class]="tableDisplay ? 'mr-2' : ''">
      <mat-icon
        svgIcon="paperclip"
        [matBadge]="attachmentCount"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Attachments: {{ attachmentCount }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div class="meta-icon-list__item">
      <mat-icon
        svgIcon="comment-outline"
        [matBadge]="comments"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Comments: {{ comments }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div
      *ngIf="far.deletionDate"
      class="meta-icon-list__item"
      [class]="tableDisplay ? 'ml-2' : ''"
    >
      <mat-icon
        svgIcon="delete-clock-outline"
        [matBadge]="far.deletionDate | daysUntil"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Days until Delete: {{ far.deletionDate | daysUntil }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
  </div>
</div>
