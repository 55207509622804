import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserFeedbackComponent } from './user-feedback.component';

@Directive({
  selector: '[appUserFeedback]',
  standalone: true,
})
export class UserFeedbackDirective {
  @Input() userFeedback: any;
  @Output() appFeedbackDialogClosed = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['event'])
  onClick() {
    const dialog = UserFeedbackComponent.openDialog(
      this.dialog,
      this.userFeedback
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appFeedbackDialogClosed.emit();
    });
  }
}
