import { Component, Input, ViewChild } from '@angular/core';
import { UserPrefColumnSortType } from '@app/shared/models';
import { UserPreferenceColumnCheckboxesComponent } from '@app/user-preference/components/user-preference-column-checkboxes/user-preference-column-checkboxes.component';
import { VIEW_DEF_KEYS } from '@app/user-preference/constants/view-properties.const';

@Component({
  selector: 'app-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss'],
})
export class ColumnChooserComponent {
  @Input() viewDefKey: VIEW_DEF_KEYS;
  @ViewChild('columnCheckboxes')
  readonly columnCheckboxes: UserPreferenceColumnCheckboxesComponent;

  columnDisplayOrder: UserPrefColumnSortType = 'ColumnOrderProperty';
  filter: string = '';

  constructor() {}

  changeDisplayOrder(type: UserPrefColumnSortType) {
    this.columnCheckboxes.changeDisplayOrder(type);
  }

  filterList() {
    this.columnCheckboxes.filterValue = this.filter;
  }

  clear() {
    this.filter = '';
    this.filterList();
  }

  toggleAll(state: boolean) {
    this.columnCheckboxes.toggleAll(state);
  }
}
