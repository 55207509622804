<mat-toolbar class="bg-white user-preference-toolbar">
  <mat-icon svgIcon="account-cog-outline"> </mat-icon>
  <h1 class="headerStyles" style="font: 400 24px / 32px Roboto, sans-serif">
    User Preferences
  </h1>
</mat-toolbar>
<form
  [formGroup]="userPreferenceForm"
  id="userPreferenceForm"
  (ngSubmit)="onSubmit()"
  class="d-flex flex-column mt-2 mx-5"
  *ngIf="userPreferenceForm"
>
  <ng-container [formGroup]="userPreferenceForm">
    <h3 class="font-weight-bold text-primary"><mat-label> View </mat-label></h3>
    <mat-label class="py-2">Select Starting Page</mat-label>
    <div class="mt-3 row">
      <mat-form-field class="dropdown col-5">
        <mat-select
          formControlName="defaultRoutePath"
          placeholder="Select a View"
        >
          @for (view of viewDefs | keyvalue; track $index; let i = $index) {
          <mat-option
            [value]="view.value.pageLink"
            *appIfRoles="viewDefs[view.key].roles"
          >
            {{ view.value.viewName }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div data-cy="userpreferences-tabgroup">
      <mat-tab-group
        dynamicHeight
        [preserveContent]="true"
        mat-stretch-tabs="true"
        mat-align-tabs="start"
        #tabGroup
        id="preferenceTabGroup"
      >
        <mat-tab [label]="viewDefs.fv.viewName" *appIfRoles="viewDefs.fv.roles">
          <app-user-preference-tab
            [viewDefKey]="viewDefs.fv.viewDefKey"
            *appIfRoles="viewDefs.fv.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.far.viewName"
          *appIfRoles="viewDefs.far.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.far.viewDefKey"
            *appIfRoles="viewDefs.far.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.screening.viewName"
          *appIfRoles="viewDefs.screening.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.screening.viewDefKey"
            *appIfRoles="viewDefs.screening.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.location.viewName"
          *appIfRoles="viewDefs.location.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.location.viewDefKey"
            *appIfRoles="viewDefs.location.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.group.viewName"
          *appIfRoles="viewDefs.group.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.group.viewDefKey"
            *appIfRoles="viewDefs.group.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.organization.viewName"
          *appIfRoles="viewDefs.organization.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.organization.viewDefKey"
            *appIfRoles="viewDefs.organization.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.userAdministration.viewName"
          *appIfRoles="viewDefs.userAdministration.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.userAdministration.viewDefKey"
            *appIfRoles="viewDefs.userAdministration.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.userApproval.viewName"
          *appIfRoles="viewDefs.userApproval.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.userApproval.viewDefKey"
            *appIfRoles="viewDefs.userApproval.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
        <mat-tab
          [label]="viewDefs.userFeedback.viewName"
          *appIfRoles="viewDefs.userFeedback.roles"
        >
          <app-user-preference-tab
            [viewDefKey]="viewDefs.userFeedback.viewDefKey"
            *appIfRoles="viewDefs.userFeedback.roles"
          >
          </app-user-preference-tab>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</form>
<mat-toolbar class="flex-container bottom-bar">
  <button mat-button color="primary" (click)="cancel()">Cancel</button>
  <button
    mat-raised-button
    id="save-user-preference-changes"
    color="primary"
    (click)="saveChanges()"
  >
    Save Changes
  </button>
</mat-toolbar>
