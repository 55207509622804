import { IEnvironment } from '@environments/ienvironment';
import { AppConfigService } from '../services/app-config.services';

// label is the what is displayed as a selection for a Sort. Value is what is emitted from the sort.
export interface SortSelect {
  label: string;
  value: string;
}

export function AppConfigValue<K extends keyof IEnvironment>(key: K): any {
  return function (target: any, propertyKey: string): any {
    let defaultVal: IEnvironment[K] | undefined = target[propertyKey];

    Object.defineProperty(target, propertyKey, {
      get: function () {
        return AppConfigService.getInstance().get(
          key,
          defaultVal
        ) as IEnvironment[K];
      },

      set: function (val: IEnvironment[K] | undefined) {
        defaultVal = val;
      },
    });
  };
}
