import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserPreference } from '@app/shared/models';
import { UserAcceptedObservable } from '@app/shared/services/user-accepted-observable.service';

import { ViewDetails } from '@app/shared/services/view.service';
import { ViewNamesConstants } from '@app/user-preference/components/user-preference/user-preference.component';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceService } from '@app/user-preference/service/user-preference.service';
@Component({
  selector: 'app-splash-page',
  templateUrl: 'splash-page.component.html',
  styleUrls: ['splash-page.component.scss'],
})
export class SplashPageComponent implements OnInit {
  userAccepted: boolean = false;
  userPreference: UserPrefSaved | undefined;
  viewNameArray = ViewNamesConstants;
  viewDetails: ViewDetails | undefined;
  userPreferenceLink: string = '/';
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  constructor(
    private router: Router,
    private auth: AuthService,
    private userAccept: UserAcceptedObservable,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.auth.authenticated$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) this.router.navigate(['/login']);
    });
    const acceptedItem = JSON.parse(
      localStorage.getItem('userAccepted') as string
    );
    this.userAccepted = acceptedItem ? JSON.parse(acceptedItem) : null;
    const userPreference = this.userPreferenceService.userPreferences$
      .value as UserPreference;
    this.userPreference = this.route.snapshot.data.userPrefFormGroup
      ?.value as UserPrefSaved;
    if (this.userAccepted) this.userPreferenceService.navigateToDefaultPage();
  }

  accept() {
    this.userAccepted = true;
    this.userAccept.accepted();
    localStorage.setItem('userAccepted', JSON.stringify(this.userAccepted));
    localStorage.setItem('userAcceptedDate', JSON.stringify(Date.now()));
    if (this.userPreference) {
      this.userPreferenceService.navigateToDefaultPage();
    } else {
      this.auth.redirectFromLogin();
      this.auth.showLoginInfo();
    }
  }

  decline() {
    this.auth.logout();
  }
}
