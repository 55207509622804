import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CitadelValidationErrors } from '@app/shared/models/citadel-validation-errors.model';
import { momentTransformer } from '../moment-transformer.function';
import { dateOrderValidator } from './date-order.validator';

export const PAST_DATE_MESSAGE: ValidationErrors = {
  pastDate: 'Invalid date was supplied',
};

export const PAST_DATE_FAR_ELIGIBLE_MESSAGE: CitadelValidationErrors = {
  farScreeningIneligible: {
    title: 'Screening Eligibility Warning',
    message:
      'You have entered a date that would make the FAR ineligible for screening',
    blocking: false,
  },
};

/**
 * @function Custom validator used to determine if the dates supplied will trigger far screening ineligibility (date is in the past)
 * @param farScreeningEligibility boolean value indicating whether farScreeningEligibility is enabled.  Validator will not trigger validations
 * when parameter is falsy.
 * @returns null | CitadelValidationErrors:
 * dateOrderValidator @see dateOrderValidator
 * currentDate < startDate returns valid
 * startDate <= currentDate <= endDate --> null (valid)
 * currentDate < startDate --> null (valid)
 */
export function farEligibilityDateValidator(
  farScreeningEligibility: boolean | undefined = undefined
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
    const startDate: Date | null = momentTransformer(
      control.get('farStartDate')?.value
    );
    const endDate: Date | null = momentTransformer(
      control.get('farEndDate')?.value
    );

    //Don't trigger if either dates are null.  This is already handled by the valdation on the individual fields
    if (!startDate || !endDate) return null;

    // if (dateOrderValidator()) return ENDDATE_BEFORE_STARTDATE_MESSAGE;

    const isValidDate: boolean =
      (startDate <= currentDate && currentDate <= endDate) ||
      currentDate < startDate;

    // Is farScreeningEligibility enabled (true) and are the dates valid?
    if (farScreeningEligibility && !isValidDate) {
      return PAST_DATE_FAR_ELIGIBLE_MESSAGE;
    }

    return null;
  };
}
