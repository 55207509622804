import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CitadelValidationErrors } from '@app/shared/models/citadel-validation-errors.model';
import { momentTransformer } from '../moment-transformer.function';

export const START_EXCEEDS_ONE_YEAR_IN_FUTURE_MESSAGE: CitadelValidationErrors =
  {
    startExceedsOneYear: {
      title: 'Invalid Date Range',
      message: 'Start Date cannot be more than one year from current date',
      blocking: true,
    },
  };

/**
 * @function startExceedsOneYearValidator Custom validator testing duration between startDate and now is > 1 year
 *
 * @returns null for validation success, ValidationErrors otherwise
 */
export function startExceedsOneYearValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate: Date | null = momentTransformer(
      control.get('farStartDate')?.value
    );

    if (!startDate) return null;

    const currentDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
    const futureOneYear: Date = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (startDate > futureOneYear)
      return START_EXCEEDS_ONE_YEAR_IN_FUTURE_MESSAGE;

    return null;
  };
}
