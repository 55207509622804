<div id="release-2-8-2" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.8.2 Release Notes</h3>
  <h6>Foreign Access Request Updates</h6>
  <ul>
    <li>
      Icons have been added to FAR Card, Table Views, FAR Selectors to denote
      whether the FAR is Active or not.
    </li>
    <li>
      A Filter has been added to FAR Advanced Search to allow for filtering on
      Active and Inactive FARs.
    </li>
    <li *appIfRoles="Role.sv_admin">
      A warning has been added during FAR creation and FAR Itinerary Date Range
      updates for Inactive FARs when the user selects a date range that would
      make the FAR inactive (Disabled - Only active when Mandatory FAR active)
    </li>
  </ul>
  <ng-container *appIfRoles="Role.sv_admin">
    <h6>Locations</h6>
    <p>
      The ability to normalize existing location data in an attempt to add
      geocode coordinates to said data has been added (Normalize button in
      Locations - Admin Only).
    </p>
    <h6>User Feedback</h6>
    <p>
      Feedback Review - The ability to quick search and sort by email and
      feedback type has been added (Admin Only).
    </p>
  </ng-container>
</div>
