import { Component } from '@angular/core';
import { ReleaseNotes252Component } from './versions/2.5.2/release-notes-2-5-2.component';
import { ReleaseNotes260Component } from './versions/2.6.0/release-notes-2-6-0.component';
import { ReleaseNotes261Component } from './versions/2.6.1/release-notes-2-6-1.component';
import { ReleaseNotes262Component } from './versions/2.6.2/release-notes-2-6-2.component';
import { ReleaseNotes270Component } from './versions/2.7.0/release-notes-2-7-0.component';
import { ReleaseNotes271Component } from './versions/2.7.1/release-notes-2-7-1.component';
import { ReleaseNotes2710Component } from './versions/2.7.10/release-notes-2-7-10.component';
import { ReleaseNotes272Component } from './versions/2.7.2/release-notes-2-7-2.component';
import { ReleaseNotes273Component } from './versions/2.7.3/release-notes-2-7-3.component';
import { ReleaseNotes274Component } from './versions/2.7.4/release-notes-2-7-4.component';
import { ReleaseNotes275Component } from './versions/2.7.5/release-notes-2-7-5.component';
import { ReleaseNotes276Component } from './versions/2.7.6/release-notes-2-7-6.component';
import { ReleaseNotes277Component } from './versions/2.7.7/release-notes-2-7-7.component';
import { ReleaseNotes278Component } from './versions/2.7.8/release-notes-2-7-8.component';
import { ReleaseNotes279Component } from './versions/2.7.9/release-notes-2-7-9.component';
import { ReleaseNotes280Component } from './versions/2.8.0/release-notes-2-8-0.component';
import { ReleaseNotes281Component } from './versions/2.8.1/release-notes-2-8-1.component';
import { ReleaseNotes282Component } from './versions/2.8.2/release-notes-2-8-2.component';

@Component({
  selector: 'app-release-notes',
  standalone: true,
  imports: [
    ReleaseNotes2710Component,
    ReleaseNotes279Component,
    ReleaseNotes278Component,
    ReleaseNotes280Component,
    ReleaseNotes281Component,
    ReleaseNotes277Component,
    ReleaseNotes276Component,
    ReleaseNotes275Component,
    ReleaseNotes274Component,
    ReleaseNotes273Component,
    ReleaseNotes272Component,
    ReleaseNotes271Component,
    ReleaseNotes270Component,
    ReleaseNotes262Component,
    ReleaseNotes261Component,
    ReleaseNotes260Component,
    ReleaseNotes252Component,
    ReleaseNotes282Component,
  ],
  templateUrl: './release-notes.component.html',
  styleUrl: './release-notes.component.scss',
})
export class ReleaseNotesComponent {}
