import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import { FilterParams, PageableCollection } from '@app/shared/models';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { CrudService, FvService } from '@app/shared/services';
import { Repository } from '@app/shared/services/repository.service';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { getArrayKeys } from '@shared/components/advanced-search/filter-config';
import { tap } from 'rxjs';

@Component({
  selector: 'app-fv-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [Repository, { provide: CrudService, useClass: FvService }],
})
export class DashboardComponent implements OnInit, AfterContentChecked {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly authService: AuthService = inject(AuthService);
  filterParams = this.repo.defaultFilterParams;
  fvs$ = this.repo.collection$.pipe(
    tap(() => {
      this.loading = false;
    })
  );

  loading = false;

  constructor(
    private repo: Repository<ForeignVisitor>,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    const userId: string = this.authService.getUser()?.id as string;
    this.filterParams.currentUserCreatedBy = savedValue?.fv?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot(...getArrayKeys(FvAdvancedSearch));
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
function tab(
  arg0: () => void
): import('rxjs').OperatorFunction<
  PageableCollection<ForeignVisitor>,
  PageableCollection<ForeignVisitor>
> {
  throw new Error('Function not implemented.');
}
