<div id="release-2-8-1" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.8.1 Release Notes</h3>
  <ng-container *appIfRoles="Role.sv_admin">
    <h6>
      ***Foreign Nationals now Require an Active Foreign Access Request to be
      Eligible for Screening <b>(Deferred - Deployment date TBD)</b>
    </h6>
    <p>
      Any Foreign National that does not have one or has a FAR that has visit
      dates in the past will not be considered eligible. An Active FAR is a FAR
      that has a visit Start Date and End Date range that the current date falls
      into or a future visit Start or End Date. Please note, for Rescreening
      purposes, this will also apply. A Foreign National <b>MUST</b> be attached
      to an Active FAR for it to be eligible to be sent out for screening or
      rescreening.
    </p>
  </ng-container>
  <h6>
    Foreign Nationals can now use National IDs, DACA ID, Diplomatic ID or a Visa
    instead of a Passport and be Screening Eligible
  </h6>
  <p>
    Instead of just a passport, any one of a passport, visa, national ID,
    diplomatic ID or DACA ID can now be used on a Foreign National to make it
    eligible for screening. Only one is needed. Other criteria remain unchanged,
    Given Name, Surname, and Date of Birth are still required (along with the
    new active FAR requirement).
  </p>
  <h6>User Feedback</h6>
  <p>
    User Feedback option has been added to the Help Menu. The only required
    field is the feedback field itself.
  </p>
  <p *appIfRoles="Role.sv_admin">
    Feedback Review page has been added to the Navigation Menu. This is only
    accessible for users with the admin role to review user feedback within the
    application.
  </p>
  <h6>User Preferences</h6>
  <p>
    The ability to default to seeing only items created by a user has been added
    for Foreign Nationals, Foreign Access Requests, Locations, Screening Review
    and Group Management.
  </p>
  <p>
    When changing the default starting view, the 'Are you sure?' popup should
    now display as intended when attempting to navigate away from the page
    without saving.
  </p>
  <ng-container
    *appIfRoles="[Role.sv_admin, Role.sv_help_desk, Role.sv_vetter]"
  >
    <h6>Metrics</h6>
    <p>The Dashboard for users with the Vetter role has been removed.</p>
  </ng-container>
  <h6>Foreign National and Foreign Access Request Search Enhancements</h6>
  <p>
    Work continued on efficency efforts to further improve search performance
    for FARs with large amounts of Foreign Nationals. Work is still ongoing, and
    we look to continue further improvements.
  </p>
</div>
