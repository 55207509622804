import { NetworkDomain } from '@app/shared/models/network-domain';
import { MonitoringViewRoles } from '@app/shared/models/role-permissions';
import { BaseViewDef } from '../model/config-view-definitions.model';

export interface SystemMonitoringTabDef extends BaseViewDef {}

export const SYSTEM_MONITORING_TAB: SystemMonitoringTabDef = {
  viewDefKey: 'systemMonitoring',
  viewName: 'System Monitoring',
  pageLink: '/monitoring',
  roles: MonitoringViewRoles,
  columns: {},
  tableName: '',
  domains: [NetworkDomain.J, NetworkDomain.U],
};
