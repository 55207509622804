import { SortSelect } from '@app/shared/constants/shared.const';
import { without } from 'lodash';

/**
 * @deprecated
 */
export enum GroupSortField {
  NAME = 'name',
  DESCRIPTION = 'description',
  CREATED_DATE = 'createdDate',
  TYPE = 'foreignVisitorGroupType',
  FN_COUNT = 'foreignVisitorCount',
  NETWORK = 'networkDomain',
}

export const GroupTableColumns: string[] = [
  GroupSortField.NAME,
  GroupSortField.DESCRIPTION,
  GroupSortField.CREATED_DATE,
  GroupSortField.TYPE,
  GroupSortField.FN_COUNT,
  GroupSortField.NETWORK,
  'actions',
];

export const GroupSortLabels: { [key in GroupSortField]: string } = {
  [GroupSortField.NAME]: 'Name',
  [GroupSortField.DESCRIPTION]: 'Description',
  [GroupSortField.CREATED_DATE]: 'Created Date',
  [GroupSortField.TYPE]: 'Group Type',
  [GroupSortField.FN_COUNT]: 'Foreign National Count',
  [GroupSortField.NETWORK]: 'Network',
};

export const GroupSorts: SortSelect[] = without(
  Object.values(GroupSortField),
  // Non-sortable fields
  GroupSortField.NETWORK
).map((value) => {
  return { label: GroupSortLabels[value], value };
});

export const GroupTableName = 'groupTable';
