<div id="release-2-8-0" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.8.0 Release Notes</h3>
  <h6>DACA IDs can be added to Foreign Nationals</h6>
  <p>
    This can be done from the Foreign National view. Since this is a special Id,
    the Foreign National will need to be created through the Citadel FAME
    application first, and then once created, it can be added from the Foreign
    National View.
  </p>
  <h6>Foreign National and Foreign Access Request Search Enhancements</h6>
  <p>
    Several changes have been made to FN and FAR search in an attempt to make
    search more efficient and speed up returning of results. Functionality
    should remain the same, instead there should be an increase in performance
    particularly for FARs with large amounts of FNs attached to them. Continued
    efficency efforts are on going to try to make further improvements.
  </p>
</div>
