import { Organization, Role, User } from '@app/shared/models';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { UserService } from '@app/shared/services';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthSummary } from '@shared/models/auth-event.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { throwError } from 'rxjs/internal/observable/throwError';

export abstract class AuthService {
  protected authenticatedSubject$ = new BehaviorSubject(false);
  public authenticated$ = this.authenticatedSubject$.asObservable();
  public userSubject$ = new BehaviorSubject<User | null>(null);
  public user$: Observable<User | null> = this.userSubject$.asObservable();
  public allowChangePassword = false;

  protected constructor(
    protected config: AppConfigService,
    protected userService: UserService,
    protected router: Router
  ) {
    this.user$.subscribe((user) => {
      this.authenticatedSubject$.next(!!user);
    });
  }

  protected async _loadUser(): Promise<User> {
    const user$ = this.userService.getCurrent().pipe(
      tap((u) => this.userSubject$.next(u)),
      catchError((err, caught) => {
        this._clearUser();
        if ([403, 412].includes(err?.status)) {
          const errorMsg = err.status === 412 ? err?.error?.message : null;
          this.router.navigateByUrl('/error/403', {
            state: { errorMsg },
          });
          return of() as Observable<User>;
        }
        return throwError(() => err);
      })
    );
    return firstValueFrom(user$);
  }

  protected _clearUser() {
    this.userSubject$.next(null);
  }

  getUser(): User | null {
    return this.userSubject$.value;
  }

  hasUser(): boolean {
    return !!this.getUser();
  }

  getOrganization(): Organization | undefined {
    return this.getUser()?.organization;
  }

  isAuthenticated(): boolean {
    return this.authenticatedSubject$.value;
  }

  getRoles(): Role[] {
    return this.getUser()?.roles || [];
  }

  get isAdmin(): boolean {
    return this.hasAnyRole(Role.sv_admin);
  }

  hasAnyRole(...roles: Role[]): boolean {
    return !roles.length || !!this.getRoles().find((r) => roles.includes(r));
  }

  abstract getAuthSummary(refresh?: boolean): Promise<AuthSummary | null>;

  abstract getAccessToken(): Promise<string>;

  abstract getIdToken(): Promise<string>;

  getToken(): Promise<string> {
    return this.config.get('useIdToken', true)
      ? this.getIdToken()
      : this.getAccessToken();
  }

  abstract getClaim(key: string): Promise<any>;

  abstract init(): Promise<void>;

  abstract login(targetUrl?: string): Promise<void>;

  abstract logout(): Promise<void>;

  public navigateToSplash(targetUrl: string = '/') {
    this._storeLoginRedirect(targetUrl);
    this.router.navigate(['/accept']);
  }

  /**
   * @TODO move this to user preference service
   * @param targetUrl
   */
  public navigateToUserPreference(targetUrl: string) {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
      this.router.navigate([targetUrl]);
    });
  }

  protected _storeLoginRedirect(targetUrl?: string) {
    const redirectUrl = targetUrl || this.router?.url || '/';
    localStorage.setItem('loginRedirectUrl', redirectUrl);
  }

  protected _hasStoreRedirect(): boolean {
    return !!localStorage.getItem('loginRedirectUrl')?.length;
  }

  abstract showLoginInfo(): Promise<void>;

  redirectFromLogin(defaultUrl = '/') {
    if (!this.isAuthenticated() || !this.hasUser()) return;
    const returnUrl = localStorage.getItem('loginRedirectUrl') || defaultUrl;
    localStorage.removeItem('loginRedirectUrl');
    this.router.navigateByUrl(returnUrl);
  }

  // Change Password specific methods

  getRequiredAttributes(): string[] {
    return [];
  }

  get canChangePassword(): boolean {
    return false;
  }

  async changePassword(
    _oldPassword: string,
    _newPassword: string
  ): Promise<any> {}

  async answerNewPassword(
    _password: string,
    _reqAttributes: any
  ): Promise<any> {}
}
