import { CitadelColumnDef } from '../../user-preference/model/config-view-definitions.model';

export const ADDITIONAL_COLUMN: CitadelColumnDef = {
  label: 'Additional Data',
  hidden: false,
  isSortable: false,
  sortField: 'additionalData',
  columnIndex: 100,
};

export const ACTION_COLUMN: CitadelColumnDef = {
  label: 'Actions',
  hidden: false,
  isSortable: false,
  sortField: 'actions',
  columnIndex: 101,
};

export const NETWORK_COLUMN: CitadelColumnDef = {
  label: 'Network',
  columnIndex: 99,
  hidden: false,
  isSortable: true,
  sortField: 'networkDomain',
};
