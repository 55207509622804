import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FAR,
  FilterParams,
  Role,
  ScreeningDateFormat,
  Sponsor,
} from '@app/shared/models';
import {
  FAR_COLUMNS,
  FAR_TAB,
  FARColumnDefs,
} from '@app/user-preference/config/far.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-far-table',
  templateUrl: './far-table.component.html',
  styleUrls: ['./far-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', padding: '5px 0' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class FarTableComponent implements OnInit, OnDestroy {
  @Input() fars: FAR[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  Role = Role;
  private ngUnsubscribe = new Subject<void>();
  expandedFar: FAR | null;

  ScreeningDateFormat = ScreeningDateFormat;

  FAR_COLUMNS: FARColumnDefs = FAR_COLUMNS;

  /**
   * NOTE: 6-16-2022 - Removed Name and Address sort fields.
  bc of the nesting, the sort seems to be buggy, adding multiple items for the
  table depending on what data is referenced with the sort.
   *  */
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    FAR_TAB
  );
  savedPrefs: UserPrefSaved;
  private userPrefFormGroup$!: Subscription;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  editRow(id: string) {
    this.router.navigateByUrl(`far/${id}/edit`);
  }
  viewRow(id: string) {
    this.router.navigateByUrl(`far/${id}`);
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  checkMultiNoDate(farLocations: any[]) {
    if (farLocations.length > 1) return false;
    else if (!farLocations[0].startDate && !farLocations[0].endDate)
      return true;
    return false;
  }

  getMarginClass(item: any): string {
    if (!this.isCityState(item)) {
      return 'mb-0';
    }
    return '';
  }

  isCityState(item: any): boolean {
    if (item.organizationLocation && item.organizationLocation.address) {
      if (
        !item.organizationLocation.address.city &&
        !item.organizationLocation.address.stateProvince
      ) {
        return false;
      }
    }
    return true;
  }

  showSponsor(sponsor: Sponsor): string {
    let result: string = '';
    let hasName: boolean | undefined =
      sponsor.surname || sponsor.givenName ? true : false;
    let hasBothNames: boolean | undefined =
      sponsor.surname && sponsor.givenName ? true : false;
    if (hasName) {
      result = sponsor.surname ? result + sponsor.surname : '';
      result = hasBothNames ? result + ', ' : '';
      result = sponsor.givenName ? result + sponsor.givenName : '';
    }
    if (!hasName) {
      result += 'N/A';
    }
    return result;
  }

  toggleRow(far: FAR) {
    far.locationCount! > 0
      ? (this.expandedFar = this.expandedFar === far ? null : far)
      : null;
    this.cd.detectChanges();
  }
}
