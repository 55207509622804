<ng-container *ngIf="passports$ | async as passports">
  <ng-container *ngIf="passports.length; else emptyCollectionButtonStyle">
    <mat-expansion-panel
      class="flex-fill mt-3 panel-250-mh"
      #passportsPanel="matExpansionPanel"
      id="fv-view-passports-panel"
      *ngIf="passports.length"
      [expanded]="passports.length > 0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-content-between py-2">
          <span>Passports</span>
          <mat-icon
            svgIcon="card-account-details-outline"
            [matBadge]="passports.length"
            matBadgeSize="small"
            matBadgeColor="accent"
          ></mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider class="panel-header-divider"></mat-divider>
      <div class="passports-body">
        <ng-container *ngFor="let item of passports; index as i">
          <div class="content row">
            <div class="col">
              <div class="row">
                <div class="col-6">
                  <dl>
                    <dt>Passport Number</dt>
                    <dd>{{ item.number | default }}</dd>
                    <dt>Issue Country</dt>
                    <dd class="d-flex align-items-center">
                      <mat-icon
                        class="mr-1"
                        *ngIf="
                          item.issuingCountryCode
                            | country: 'alpha2' as countryCode
                        "
                        [flagIcon]="countryCode"
                      ></mat-icon>
                      {{ item.issuingCountryCode | country }}
                    </dd>
                    <dt>Issue Date</dt>
                    <dd>
                      {{ item.issueDate | militaryDate | default }}
                    </dd>
                  </dl>
                </div>
                <div class="col-6">
                  <dl>
                    <dt>Passport Type</dt>
                    <dd>
                      {{ item.type | default }}
                    </dd>
                    <dt>Issue Authority</dt>
                    <dd>
                      {{ item.issueAuthority | default }}
                    </dd>
                    <dt>Expiration Date</dt>
                    <dd>
                      {{ item.expirationDate | militaryDate | default }}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <mat-divider
              [vertical]="true"
              class="panel-vertical-divider"
            ></mat-divider>
            <div
              class="col-2 d-flex justify-content-center align-items-center"
              style="flex-direction: column"
            >
              <button
                *ngIf="editable"
                mat-icon-button
                (click)="updatePassport(item)"
                matTooltip="Edit Passport"
              >
                <mat-icon svgIcon="square-edit-outline"></mat-icon>
              </button>

              <div class="flex-fill"></div>
              <div *ngIf="i === 0 && editable" class="mb-2">
                <button
                  mat-mini-fab
                  matTooltip="Add Passport"
                  class="bg-warning"
                  style="color: white"
                  (click)="createPassport()"
                >
                  <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-divider
            *ngIf="i + 1 < passports.length"
            class="panel-header-divider"
          ></mat-divider>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </ng-container>
  <ng-template #emptyCollectionButtonStyle>
    <button
      *ngIf="additionalDocumentsScreeningEligibility"
      class="mt-3 w-100"
      style="height: 48px"
      mat-raised-button
      [matTooltip]="
        hasDocument
          ? 'Add New Passport'
          : 'Add New Passport - One Identification Document Required for Screening'
      "
      [color]="hasDocument ? 'primary' : 'error'"
      (click)="createPassport()"
      [disabled]="!editable"
    >
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2" svgIcon="alert" *ngIf="!hasDocument"></mat-icon>
        <span>Add Passport</span>
        <mat-icon
          class="ml-2"
          svgIcon="card-account-details-outline"
        ></mat-icon>
      </div>
    </button>
    <button
      *ngIf="!additionalDocumentsScreeningEligibility"
      class="mt-3 w-100"
      style="height: 48px"
      mat-raised-button
      matTooltip="Add New Passport - Required for Screening"
      color="error"
      (click)="createPassport()"
      [disabled]="!editable"
    >
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2" svgIcon="alert"></mat-icon>
        <span>Add Passport</span>
        <mat-icon
          class="ml-2"
          svgIcon="card-account-details-outline"
        ></mat-icon>
      </div>
    </button>
  </ng-template>
</ng-container>
