<h1
  class="d-flex align-items-center pt-2"
  mat-dialog-title
  [class]="
    data.status === 'Completed'
      ? 'bg-primary text-white'
      : 'bg-error text-white'
  "
>
  <mat-icon
    class="mr-2 text-white"
    [svgIcon]="
      data.status === 'Completed' ? 'progress-check' : 'alert-circle-outline'
    "
  ></mat-icon>
  <span *ngIf="data.type !== 'Location Bulk Resolve'"
    >Bulk Upload Details - {{ data.status }}</span
  >
  <span *ngIf="data.type === 'Location Bulk Resolve'"
    >Address Normalization Details - {{ data.status }}</span
  >
</h1>
<mat-dialog-content class="mt-2">
  <div class="container mt-2">
    <div class="d-flex" style="justify-content: space-around">
      <dl>
        <dt>File Name</dt>
        <dd>{{ data.fileName }}</dd>
      </dl>
      <dl>
        <dt>Created Date</dt>
        <dd>{{ data.createdDate | date: "MMM d, y, HH:mm:ss" | default }}</dd>
      </dl>
    </div>
    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="w-100"
      *ngIf="data.processingError"
    >
      <mat-label>Processing Error</mat-label>
      <textarea
        id="processing-error-input"
        disabled
        #processingError="ngModel"
        matInput
        rows="5"
        [(ngModel)]="data.processingError"
        name="processingError"
        maxlength="2000"
      ></textarea>
    </mat-form-field>
    <div *ngIf="data.messages?.length">
      <table mat-table [dataSource]="data.messages" class="full-width">
        <ng-container matColumnDef="row">
          <th mat-header-cell *matHeaderCellDef>Row Number</th>
          <td mat-cell *matCellDef="let item">{{ item.rowNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="messages">
          <th mat-header-cell *matHeaderCellDef>Validation Messages</th>
          <td mat-cell *matCellDef="let item">
            <div class="small row justify-content-center">
              {{ item.message }}
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="errorTableColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let myRowData; columns: errorTableColumns"></tr>
      </table>
    </div>
    <div
      *ngIf="
        !data.processingError &&
        (data.messages?.length === 0 || data.messages === null)
      "
    >
      Data Uploaded with no issues
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close color="primary">Close</button>
  <button
    mat-raised-button
    color="error"
    matTooltip="Remove job from notifications list and close window"
    (click)="markAsRead()"
  >
    Mark As Read
  </button>
</mat-dialog-actions>
