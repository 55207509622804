<div id="release-2-7-1" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.1 Release Notes</h3>
  <h6>FAR Screening Eligibilty</h6>
  <p>
    Code to require all Foreign Nationals being attached to an Active Foreign
    Access Request (FAR) to be eligible for screening introduced. An Active FAR
    is any FAR that has a Date Range that is current or ongoing or a Date range
    in the Future. FAR Screening Eligibility disabled until later date.
  </p>
  <h6>Foreign National Creation Workflow</h6>
  <p>
    Baseline Step 1 has been reorganized with the FAR first on the left side and
    the Foreign National Data on the right side.
  </p>
  <h6>Bulk Upload</h6>
  <p>
    US States Abbreviations – when an abbreviation for a US state is entered as
    part of the address for a Bulk Upload location, the state abbreviation will
    now be converted to the full state name.
  </p>
  <h6>Metrics</h6>
  <p>
    Reviewed Green and Machine Green have been broken out into their own
    categories with corresponding counts (Total Green count still remains).
  </p>
  <p>
    Any date within the date range specified that does not have any data
    available will now return a zero and display in the graphs instead of not
    being displayed at all.
  </p>
</div>
