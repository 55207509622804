<div class="itinerary-control">
  <div
    class="itinerary-list-container d-flex flex-column"
    *ngIf="value?.length && !editMode"
  >
    <mat-toolbar class="itinerary-view-toolbar bg-transparent d-flex flex-row">
      <span class="small">Itinerary ({{ value.length }})</span>
      <span class="col"></span>
      <button
        mat-button
        color="primary"
        (click)="edit()"
        id="itinerary-add-btn"
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon svgIcon="plus-circle-outline"></mat-icon>
          <span>Add</span>
        </div>
      </button>
    </mat-toolbar>
    <ng-container *ngIf="value?.length">
      <mat-nav-list class="itinerary-list bordered-list">
        <mat-list-item
          style="border-bottom: 1px solid lightgrey"
          class="itinerary-list-item"
          (click)="edit(i)"
          *ngFor="
            let item of value | orderByImpure: ['startDate', 'endDate'];
            let i = index
          "
        >
          <mat-icon
            color="primary"
            class="itinerary-list-icon"
            matListItemIcon
            svgIcon="office-building-marker"
          ></mat-icon>
          <mat-icon
            class="itinerary-list-edit-icon"
            matListItemIcon
            svgIcon="square-edit-outline"
          ></mat-icon>
          <h3
            matListItemTitle
            class="flex-row"
            *ngIf="item.organizationLocation"
          >
            {{ item.organizationLocation.name }}
            -
            {{
              item.organizationLocation && item.organizationLocation.address
                ? (item.organizationLocation.address | address: "cityState")
                : "N/A"
            }}
          </h3>
          <p matListItemLine>
            <span
              ><ng-container
                *ngIf="
                  !item?.startDate && !item?.endDate;
                  then noDates;
                  else hasDates
                "
              >
              </ng-container>
              <ng-template #noDates>N/A</ng-template>
              <ng-template #hasDates>
                {{ item?.startDate | date | default }} -
                {{ item?.endDate | date | default }}
              </ng-template></span
            >
          </p>
          <p mat-line *ngIf="item.description?.length">
            <span>{{ item?.description }}</span>
          </p>
          <button
            matListItemMeta
            mat-icon-button
            [id]="'itinerary-remove-' + i"
            color="warn"
            matTooltip="Remove Item"
            [stopPropagation]
            (click)="remove(i)"
          >
            <mat-icon class="mat-24">remove_circle_outline</mat-icon>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </ng-container>
  </div>
  <ng-container *ngIf="!value?.length || editMode">
    <app-itinerary-control
      #itineraryControl
      [owningFarId]="owningOrg"
      [(ngModel)]="location"
      [locationRequired]="locationRequired"
    >
    </app-itinerary-control>
    <mat-toolbar
      class="itinerary-edit-footer bg-transparent d-flex flex-row pr-0"
    >
      <span class="col"></span>
      <button
        mat-button
        (click)="cancel()"
        *ngIf="value?.length"
        id="itinerary-cancel-btn"
      >
        Cancel
      </button>
      <button
        [disabled]="!isValidDates()"
        mat-flat-button
        id="itinerary-update-btn"
        color="primary"
        class="ml-3"
        (click)="submit()"
      >
        Submit
      </button>
    </mat-toolbar>
  </ng-container>
</div>
