import { SortSelect } from '@app/shared/constants/shared.const';
import { without } from 'lodash';
/**
 * @deprecated
 */
export enum OrgSortField {
  NAME = 'name',
  SHORT_NAME = 'shortName',
  PARENT_ORG = 'parentOrganization.name',
  SUB_ORGS = 'subOrgCount',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  FN_RECORD_COUNT = 'fnRecordCount',
  CONTACTS_COUNT = 'contactsCount',
  RESTRICTED_ACCESS = 'restrictedAccess',
}

export const OrgSortFieldLabels: { [key in OrgSortField]: string } = {
  [OrgSortField.NAME]: 'Name',
  [OrgSortField.SHORT_NAME]: 'Short Name',
  [OrgSortField.PARENT_ORG]: 'Parent Organization',
  [OrgSortField.SUB_ORGS]: 'Sub Orgs',
  [OrgSortField.CREATED_DATE]: 'Created Date',
  [OrgSortField.LAST_MODIFIED_DATE]: 'Last Modified Date',
  [OrgSortField.FN_RECORD_COUNT]: 'FN Records',
  [OrgSortField.CONTACTS_COUNT]: 'Contacts',
  [OrgSortField.RESTRICTED_ACCESS]: 'Restricted Access',
};

export const OrgSorts: SortSelect[] = Object.values(OrgSortField).map(
  (value) => {
    return { label: OrgSortFieldLabels[value], value };
  }
);

export enum OrgLocationSortField {
  NAME = 'name',
  ADDRESS = 'address.line1',
  CITY = 'address.city',
  STATE_PROVINCE = 'address.stateProvince',
  CITY_STATE_PROVINCE = 'address.cityStateProvince',
  POSTAL_CODE = 'address.postalCode',
  COUNTRY = 'address.countryCode',
  ORG = 'organization.shortName',
  NETWORK = 'networkDomain',
  FAR_COUNT = 'farcount',
}

export const OrgLocationSortLabels: { [key in OrgLocationSortField]: string } =
  {
    [OrgLocationSortField.NAME]: 'Name',
    [OrgLocationSortField.ADDRESS]: 'Street Address',
    [OrgLocationSortField.CITY]: 'City',
    [OrgLocationSortField.STATE_PROVINCE]: 'State/Province',
    [OrgLocationSortField.CITY_STATE_PROVINCE]: 'City/State/Province',
    [OrgLocationSortField.POSTAL_CODE]: 'Zip Code',
    [OrgLocationSortField.COUNTRY]: 'Country',
    [OrgLocationSortField.ORG]: 'Organization',
    [OrgLocationSortField.NETWORK]: 'Network',
    [OrgLocationSortField.FAR_COUNT]: 'FAR Count',
  };

export const OrgLocationSorts: SortSelect[] = without(
  Object.values(OrgLocationSortField),
  // Non-sortable fields
  OrgLocationSortField.CITY_STATE_PROVINCE
).map((value) => {
  return { label: OrgLocationSortLabels[value], value };
});

export const OrgTableColumns: string[] = [
  OrgSortField.NAME,
  OrgSortField.SHORT_NAME,
  OrgSortField.PARENT_ORG,
  OrgSortField.SUB_ORGS,
  OrgSortField.CREATED_DATE,
  OrgSortField.LAST_MODIFIED_DATE,
  OrgSortField.FN_RECORD_COUNT,
  OrgSortField.CONTACTS_COUNT,
  OrgSortField.RESTRICTED_ACCESS,
];

export const OrgTableName = 'organizationTable';
