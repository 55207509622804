import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Sort } from '@angular/material/sort';
import {
  MatCell,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FilterParams, Role } from '@app/shared/models';
import { UserFeedback } from '@app/shared/models/user-feedback.model';
import { AlertService } from '@app/shared/services';
import { UserFeedbackService } from '@app/shared/services/user-feedback.service';
import { SharedModule } from '@app/shared/shared.module';
import {
  USER_FEEDBACK_COLUMNS,
  USER_FEEDBACK_TAB,
  UserFeedbackColumnDefs,
} from '@app/user-preference/config/user-feedback.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { Subject, Subscription } from 'rxjs';
import { UserFeedbackViewDirective } from './user-feedback-view.directive';

export const UserFeedbackTableName = 'feedbackTable';
@Component({
  selector: 'app-user-feedback-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTable,
    MatHeaderCell,
    MatCell,
    MatColumnDef,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuTrigger,
    MatRow,
    SharedModule,
    RouterModule,
    MatFormFieldModule,
    MatMenu,
    UserFeedbackViewDirective,
  ],
  templateUrl: './user-feedback-table.component.html',
  styleUrl: './user-feedback-table.component.scss',
})
export class UserFeedbackTableComponent implements OnDestroy, OnInit {
  onEdited(result: UserFeedback) {
    const data = this.data.find(
      (d) => d.userFeedbackId === result.userFeedbackId
    );
    if (data) {
      Object.assign(data, result);
    }
  }

  @Input() data: UserFeedback[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  Role = Role;
  user = inject(AuthService);

  private ngUnsubscribe = new Subject<void>();
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  USER_FEEDBACK_COLUMNS: UserFeedbackColumnDefs = USER_FEEDBACK_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    USER_FEEDBACK_TAB
  );

  constructor(
    public dialog: MatDialog,
    public feedbackService: UserFeedbackService,
    public alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  @Output() sortChange = new EventEmitter<Sort>();

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  deleteFeedback(id: string) {
    this.alert.confirmDelete({
      title: 'Delete Feedback',
      message: 'Are you sure you would like to delete this feedback?',
      performAction: () =>
        this.feedbackService.deleteFeedback(
          id,
          new FilterParams({ hardDelete: true })
        ),
    });
  }
}
