import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatFormField,
  MatFormFieldAppearance,
  MatFormFieldModule,
  MatLabel,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { Role } from '@app/shared/models';
import { UserFeedback } from '@app/shared/models/user-feedback.model';

@Component({
  selector: 'app-user-feedback-form',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioGroup,
    MatFormField,
    MatRadioButton,
    MatLabel,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './user-feedback-form.component.html',
  styleUrl: './user-feedback-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UserFeedbackFormComponent,
      multi: true,
    },
  ],
})
export class UserFeedbackFormComponent extends BaseControlComponent<UserFeedback> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;

  constructor() {
    super();
  }

  writeValue(value: UserFeedback): void {
    this.value = value || {};
  }

  hasValidValues() {
    if (this.value && this.value.ownerOrganizationId) {
      return true;
    }
    return false;
  }
}
