<h1
  mat-dialog-title
  *ngIf="userFeedback"
  class="d-flex align-items-center mt-1"
>
  <ng-template #readOnly>View Feedback (Read-only) </ng-template>
  <div style="flex: 1"></div>
</h1>
<mat-dialog-content>
  <app-user-feedback-form
    class="user-feedback-form"
    #userFeedbackForm
    [(ngModel)]="userFeedback"
    (emitChangeEvent)="onUserFeedbackChange($event)"
  ></app-user-feedback-form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-button
    color="primary"
    mat-dialog-close
    id="cancel-user-feedback-dialog"
    (click)="onClose()"
  >
    {{ !feedbackId ? "Cancel" : "Close" }}
  </button>
  <span class="col"></span>
</mat-dialog-actions>
