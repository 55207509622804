import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  VIEW_DEF_DEFINITIONS,
  VIEW_DEF_KEYS,
} from '@app/user-preference/constants/view-properties.const';
import { BaseViewDef } from '@app/user-preference/model/config-view-definitions.model';
import { uniqueId } from 'lodash';
import { UserPreferenceColumnCheckboxesComponent } from '../user-preference-column-checkboxes/user-preference-column-checkboxes.component';
@Component({
  selector: 'app-user-preference-tab',
  templateUrl: './user-preference-tab.component.html',
  styleUrls: ['./user-preference-tab.component.scss'],
})
export class UserPreferenceTabComponent implements OnInit {
  @Input() tabId: string = uniqueId(UserPreferenceTabComponent.name);
  tabFormGroup: FormGroup;
  tabViewDef: BaseViewDef;
  @Input() viewDefKey: VIEW_DEF_KEYS;
  @ViewChild('checkboxComponent')
  checkboxComponent: UserPreferenceColumnCheckboxesComponent;
  tabTitle: string = 'Tab Title';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.tabTitle = VIEW_DEF_DEFINITIONS[this.viewDefKey].viewName;
    this.tabViewDef = VIEW_DEF_DEFINITIONS[this.viewDefKey];
    this.tabFormGroup = this.route.snapshot.data.userPrefFormGroup.get(
      this.viewDefKey
    );
  }

  toggleAll(select: boolean) {
    this.checkboxComponent.toggleAll(select);
  }
}
