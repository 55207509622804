import { NetworkDomain } from '@app/shared/models/network-domain';
import { LocViewRoles } from '@app/shared/models/role-permissions';
import {
  ACTION_COLUMN,
  NETWORK_COLUMN,
} from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const LOCATION_TABLE_NAME = 'locationTable';

export interface LocationColumnDefs {
  name: CitadelColumnDef;
  addressLine: CitadelColumnDef;
  city: CitadelColumnDef;
  cityStateProvince: CitadelColumnDef;
  postalCode: CitadelColumnDef;
  country: CitadelColumnDef;
  organization: CitadelColumnDef;
  networkDomain: CitadelColumnDef;
  farCount: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const LOCATION_COLUMNS: LocationColumnDefs = {
  name: {
    label: 'Name',
    hidden: false,
    isSortable: true,
    sortField: 'name',
    columnIndex: 0,
  },
  addressLine: {
    label: 'Street Address',
    hidden: false,
    isSortable: true,
    sortField: 'address.line1',
    columnIndex: 1,
  },
  city: {
    label: 'City',
    columnIndex: 0,
    hidden: true,
    isSortable: true,
    sortField: 'address.city',
  },
  cityStateProvince: {
    label: 'City/State/Province',
    hidden: false,
    isSortable: true,
    sortField: 'address.cityStateProvince',
    columnIndex: 2,
  },
  postalCode: {
    label: 'Zip Code',
    hidden: false,
    isSortable: true,
    sortField: 'address.postalCode',
    columnIndex: 3,
  },
  country: {
    label: 'Country',
    hidden: false,
    isSortable: true,
    sortField: 'address.countryCode',
    columnIndex: 4,
  },
  organization: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'organization.shortName',
    columnIndex: 5,
  },
  networkDomain: {
    ...NETWORK_COLUMN,
    hidden: false,
    columnIndex: 6,
  },
  farCount: {
    label: 'FAR Count',
    hidden: false,
    isSortable: true,
    sortField: 'farcount',
    columnIndex: 7,
  },
  actions: ACTION_COLUMN,
};

export type LOCATION_COLUMN_KEYS = keyof typeof LOCATION_COLUMNS;

export interface LocationPrefSaved extends BaseSavedDef {
  showColumn: { [key in LOCATION_COLUMN_KEYS]: boolean };
}

export interface LocationTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in LOCATION_COLUMN_KEYS]: CitadelColumnDef };
}

export const LOCATIONS_TAB: LocationTabDef = {
  viewDefKey: 'location',
  defaultMineOnly: false,
  viewType: 'card',
  columns: LOCATION_COLUMNS,
  viewName: 'Locations',
  tableName: LOCATION_TABLE_NAME,
  pageLink: '/locations',
  roles: LocViewRoles,
  domains: [NetworkDomain.J, NetworkDomain.U],
};
