import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AppConfigDirective,
  CitadelLogoDirective,
  ContactEditDirective,
  DebounceClickDirective,
  FlagIconDirective,
  GroupEditDirective,
  HighlightDirective,
  HighOnlyEnabledDirective,
  LocationEditDirective,
  MatBadgeIconDirective,
  MimeTypeIconDirective,
  NumericOnlyDirective,
  OrganizationEditDirective,
  PostalCodeDirective,
  RestrictDeleteDirective,
  RolesDirective,
  StopPropagationDirective,
} from '@shared/directives';
import { MaterialModule } from '../material/material.module';

const SHARED_DIRECTIVES: any = [
  AppConfigDirective,
  CitadelLogoDirective,
  ContactEditDirective,
  DebounceClickDirective,
  FlagIconDirective,
  HighlightDirective,
  HighOnlyEnabledDirective,
  LocationEditDirective,
  MatBadgeIconDirective,
  MimeTypeIconDirective,
  NumericOnlyDirective,
  OrganizationEditDirective,
  PostalCodeDirective,
  RestrictDeleteDirective,
  RolesDirective,
  StopPropagationDirective,
  GroupEditDirective,
];

@NgModule({
  declarations: SHARED_DIRECTIVES,
  imports: [CommonModule, MaterialModule],
  exports: [SHARED_DIRECTIVES],
})
export class DirectivesModule {}
