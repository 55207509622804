import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParams, PageableCollection } from '@app/shared/models';
import { UserFeedback } from '@app/shared/models/user-feedback.model';
import {
  UserFeedbackSortFieldLabels,
  UserFeedbackTableColumns,
  UserFeedbackTableName,
} from '../constants/user-feedback.const';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UserFeedbackAdvancedSearch } from './user-feedback-advanced-search';
import { UserFeedbackTableComponent } from './user-feedback-table/user-feedback-table.component';
@Component({
  selector: 'app-user-feedback-collection',
  standalone: true,
  imports: [CommonModule, SharedModule, UserFeedbackTableComponent],
  templateUrl: './user-feedback-collection.component.html',
  styleUrl: './user-feedback-collection.component.scss',
})
export class UserFeedbackCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<UserFeedback> | null = null;
  @Input() placeholder: string = 'Filter Criteria (e.g): Name, Org';
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly UserFeedbackAdvancedSearch = UserFeedbackAdvancedSearch;
  protected readonly UserFeedbackTableColumns = UserFeedbackTableColumns;
  protected readonly UserFeedbackSortFieldLabels = UserFeedbackSortFieldLabels;
  protected readonly UserFeedbackTableName = UserFeedbackTableName;
}
