import { FormControl } from '@angular/forms';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { FVViewRoles } from '@app/shared/models/role-permissions';
import {
  ACTION_COLUMN,
  ADDITIONAL_COLUMN,
} from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const FV_TABLE_NAME = 'fvTable';
export interface FVColumnDefs {
  owningOrgShortname: CitadelColumnDef;
  surname: CitadelColumnDef;
  citizenshipCountry: CitadelColumnDef;
  birthDate: CitadelColumnDef;
  passport: CitadelColumnDef;
  latestScreeningModifiedDate: CitadelColumnDef;
  latestScreeningStatus: CitadelColumnDef;
  latestScreeningResult: CitadelColumnDef;
  additionalData: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const FV_COLUMNS: FVColumnDefs = {
  owningOrgShortname: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'owningOrgShortname',
    columnIndex: 0,
  },
  surname: {
    label: 'Foreign National',
    hidden: false,
    isSortable: true,
    sortField: 'surname',
    columnIndex: 1,
  },
  citizenshipCountry: {
    label: 'Citizenship Country',
    hidden: false,
    isSortable: true,
    sortField: 'citizenshipCountry',
    columnIndex: 2,
  },
  birthDate: {
    label: 'Date of Birth',
    hidden: false,
    isSortable: true,
    sortField: 'dateOfBirth',
    columnIndex: 3,
  },
  passport: {
    label: 'Passport',
    hidden: false,
    isSortable: true,
    sortField: 'latestPassportNumber',
    columnIndex: 4,
  },

  latestScreeningModifiedDate: {
    label: 'Last Screening Modified',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.lastModifiedDate',
    columnIndex: 5,
  },
  latestScreeningStatus: {
    label: 'Screening Status',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.status',
    columnIndex: 6,
  },
  latestScreeningResult: {
    label: 'Screening Results',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.result',
    columnIndex: 7,
  },
  additionalData: { ...ADDITIONAL_COLUMN },
  actions: { ...ACTION_COLUMN },
};

export type FV_COLUMN_KEYS = keyof typeof FV_COLUMNS;
export type FV_COLUMN_FORM_GROUP = { [key: string]: FormControl<boolean> };

export interface FVPrefSaved extends BaseSavedDef {
  showColumn: { [keyof in FV_COLUMN_KEYS]: boolean };
}

export interface FVTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [keyof in FV_COLUMN_KEYS]: CitadelColumnDef };
}

export type FVTabFormDef = {
  defaultMineOnly: FormControl<boolean>;
  viewType: FormControl<ViewType>;
  columns: { [key: string]: FormControl<boolean> };
};

export const FV_TAB: FVTabDef = {
  viewDefKey: 'fv',
  defaultMineOnly: false,
  viewType: 'card',
  columns: FV_COLUMNS,
  viewName: 'Foreign Nationals (FN)',
  tableName: FV_TABLE_NAME,
  pageLink: '/fvs',
  roles: FVViewRoles,
  domains: [NetworkDomain.J, NetworkDomain.U],
};
