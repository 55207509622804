<div class="my-2">
  <mat-table
    [dataSource]="fvs"
    class="header-primary-color"
    multiTemplateDataRows
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="SCREENING_COLUMNS.surname.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.screening?.showColumn?.surname === false"
      >
        {{ SCREENING_COLUMNS.surname.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-between"
        *matCellDef="let fv"
        [hidden]="savedPrefs?.screening?.showColumn?.surname === false"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="fv | fullname: 'surnameFirst' as fullName"
        >
          <div
            class="d-flex justify-content-start ml-2"
            [matTooltip]="fullName | titlecase"
            [class.align-wrapped]="fullName.length > 15"
          >
            {{ fullName | titlecase | shorten: 20:"..." }}
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <mat-icon
            matTooltip="FN ineligible for screening"
            color="warn"
            *ngIf="fv.screeningEligible === false"
            >warning</mat-icon
          >
        </div>
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="SCREENING_COLUMNS.latestScreeningRequestDate.sortField"
    >
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningRequestDate ===
          false
        "
      >
        {{ SCREENING_COLUMNS.latestScreeningRequestDate.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        style="text-align: center"
        *matCellDef="let fv"
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningRequestDate ===
          false
        "
      >
        {{
          fv.latestScreening?.requestedDate
            | date: ScreeningDateFormat
            | default
        }}
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="SCREENING_COLUMNS.latestScreeningModifiedDate.sortField"
    >
      <mat-header-cell
        class="justify-content-center"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningModifiedDate ===
          false
        "
      >
        {{ SCREENING_COLUMNS.latestScreeningModifiedDate.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        style="text-align: center"
        *matCellDef="let fv"
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningModifiedDate ===
          false
        "
      >
        {{
          fv.latestScreening?.lastModifiedDate
            | date: ScreeningDateFormat
            | default
        }}</mat-cell
      >
    </ng-container>
    <ng-container
      [matColumnDef]="SCREENING_COLUMNS.latestScreeningCompleteDate.sortField"
    >
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningCompleteDate ===
          false
        "
      >
        {{ SCREENING_COLUMNS.latestScreeningCompleteDate.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        style="text-align: center"
        *matCellDef="let fv"
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningCompleteDate ===
          false
        "
      >
        {{
          fv.latestScreening?.completedDate
            | date: ScreeningDateFormat
            | default
        }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SCREENING_COLUMNS.screeningStatus.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.screening?.showColumn?.screeningStatus === false"
      >
        {{ SCREENING_COLUMNS.screeningStatus.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let fv"
        [hidden]="savedPrefs?.screening?.showColumn?.screeningStatus === false"
      >
        <app-screening-indicator
          id="'screening-indicator-' + {{ fv.id }}"
          *ngIf="fv?.latestScreening as screening"
          [screening]="screening"
          [showStatusIcon]="true"
          [showStatusText]="false"
          [display]="'status'"
          iconSize="large"
          [hasMenu]="true"
        >
        </app-screening-indicator>
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="SCREENING_COLUMNS.latestScreeningResult.sortField"
    >
      <mat-header-cell
        class="justify-content-center"
        style="text-align: center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningResult === false
        "
      >
        {{ SCREENING_COLUMNS.latestScreeningResult.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let fv"
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningResult === false
        "
      >
        <app-screening-indicator
          id="screening-result-{{ fv.id }}"
          *ngIf="fv.latestScreening?.result"
          [screening]="fv.latestScreening"
          [display]="'result'"
        >
        </app-screening-indicator>
        <span *ngIf="!fv.latestScreening?.result">N/A</span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SCREENING_COLUMNS.screeningCount.sortField">
      <mat-header-cell
        class="justify-content-center"
        style="text-align: center"
        mat-sort-header="totalScreeningCount"
        *matHeaderCellDef
        [hidden]="savedPrefs?.screening?.showColumn?.screeningCount === false"
      >
        Total Screenings
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let fv"
        [hidden]="savedPrefs?.screening?.showColumn?.screeningCount === false"
      >
        {{ fv.allScreenings.length }}
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="SCREENING_COLUMNS.latestScreeningNotes.sortField"
    >
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningNotes === false
        "
      >
        {{ SCREENING_COLUMNS.latestScreeningNotes.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let fv"
        [hidden]="
          savedPrefs?.screening?.showColumn?.latestScreeningNotes === false
        "
      >
        <ng-container
          *ngIf="fv.latestScreening?.notes; then hasNotes; else noNotes"
        ></ng-container>
        <ng-template #hasNotes>
          <button
            mat-icon-button
            #noteTrigger="matMenuTrigger"
            [matMenuTriggerFor]="noteMenu"
          >
            <mat-icon
              color="primary"
              [matTooltip]="'View Screening to read Notes'"
              >description</mat-icon
            >
          </button>
          <mat-menu #noteMenu>
            <div class="container">
              <div class="row">
                <div class="col">
                  <span>
                    {{ fv.latestScreening?.notes }}
                  </span>
                </div>
              </div>
            </div>
          </mat-menu>
        </ng-template>
        <ng-template #noNotes
          ><span [matTooltip]="'No Notes Available'"> N/A </span>
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SCREENING_COLUMNS.networkDomain.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.screening?.showColumn?.networkDomain === false"
      >
        {{ SCREENING_COLUMNS.networkDomain.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let fv"
        [hidden]="savedPrefs?.screening?.showColumn?.networkDomain === false"
      >
        <mat-icon
          [svgIcon]="'alpha-' + fv.networkDomain?.toLowerCase() + '-box'"
          matTooltip="Network"
          aria-hidden="false"
          color="primary"
        ></mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SCREENING_COLUMNS.actions.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="savedPrefs?.screening?.showColumn?.actions === false"
        >Actions</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let fv"
        [hidden]="savedPrefs?.screening?.showColumn?.actions === false"
      >
        <button
          *ngIf="fv?.latestScreening"
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
          matTooltip="Click For Actions"
          matTooltipPosition="right"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            [screeningUpdate]="fv"
            (triggerUpdate)="onSortChange(null)"
            id="view-button"
          >
            <mat-icon>visibility</mat-icon>
            View
          </button>
          <button
            [disabled]="!fv.isRescreenable"
            mat-menu-item
            (click)="openConfirmationDialog(fv)"
            id="new-screening-button"
            data-cy="new-screening-vetter-button"
          >
            <mat-icon svgIcon="send-circle-outline"></mat-icon>
            Rescreen
          </button>
          <button
            mat-menu-item
            [screeningHistory]="fv"
            id="open-screening-history-button"
            data-cy="screening-history-vetter-button"
          >
            <mat-icon svgIcon="clipboard-text-clock"></mat-icon>
            Screening History
          </button>
        </mat-menu></mat-cell
      >
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="dataColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
</div>
