<div class="grid-collection">
  <div class="grid-header">
    <app-search-toolbar
      viewDefKey="userApproval"
      [filterFields]="ApprovalAdvancedSearch"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
      [actionButtons]="actionButtons"
      [columns]="ApprovalTableColumns"
      [columnLabels]="ApprovalSortFieldLabels"
      [tableName]="ApprovalTableName"
      filterPlaceholder="Filter Criteria (e.g): Name, Email, Org, Approver, Content"
      hideViewToggle="true"
      [loading]="loading"
      >Org Admin Approvals</app-search-toolbar
    >
  </div>
  <ng-container *ngIf="pageableCollection">
    <div [ngClass]="workspaceView ? 'workspace-main' : 'grid-main'">
      <div class="container">
        <ng-container *ngIf="pageableCollection.content.length; else noData">
          <app-approval-table
            [data]="pageableCollection.content"
            [filterParams]="filterParams"
            class="app-aproval-table-container"
            (filterParamsChange)="onFilterParamChange($event)"
          ></app-approval-table>
        </ng-container>
        <ng-template #noData>
          <app-no-result-found></app-no-result-found>
        </ng-template>
      </div>
    </div>
    <div class="grid-footer">
      <app-paginator
        class="sticky"
        [filterParams]="filterParams"
        (filterParamsChange)="onFilterParamChange($event)"
        [pageIndex]="pageableCollection.number"
        [pageSize]="pageableCollection.size"
        [totalElements]="pageableCollection.totalElements"
      ></app-paginator>
    </div>
  </ng-container>
</div>
