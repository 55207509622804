<h3 mat-dialog-title>
  {{ location.id ? "Edit" : "Add" }} Foreign Access Request (FAR) Itinerary Item
</h3>

<mat-dialog-content>
  <form #form="ngForm" class="mt-2">
    <app-itinerary-control
      #itineraryControl
      name="itinerary"
      [owningFarId]="farOwningOrgId"
      [(ngModel)]="location"
      [locationRequired]="locationRequired"
    >
    </app-itinerary-control>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button color="primary" id="farItineraryCancel" mat-dialog-close>
    Cancel
  </button>
  <button
    id="farItinerarySubmit"
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
    [disabled]="itineraryControl?.invalid || busy"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>
<ng-template #loading>
  <mat-spinner diameter="35"></mat-spinner>
</ng-template>
