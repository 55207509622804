import { NetworkDomain } from '@app/shared/models/network-domain';
import { UserFeedbackRoles } from '@app/shared/models/role-permissions';
import { ACTION_COLUMN } from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const USER_FEEDBACK_TABLE_NAME = 'userFeedbackTable';
export interface UserFeedbackColumnDefs {
  feedback: CitadelColumnDef;
  feedbackType: CitadelColumnDef;
  subjectTitle: CitadelColumnDef;
  name: CitadelColumnDef;
  email: CitadelColumnDef;
  organization: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const USER_FEEDBACK_COLUMNS: UserFeedbackColumnDefs = {
  feedback: {
    label: 'Feedback',
    hidden: false,
    isSortable: true,
    sortField: 'feedback',
    columnIndex: 0,
  },
  feedbackType: {
    label: 'Feedback Type',
    hidden: false,
    isSortable: true,
    sortField: 'feedbackType',
    columnIndex: 1,
  },
  subjectTitle: {
    label: 'Subject Title',
    hidden: false,
    isSortable: true,
    sortField: 'subjectTitle',
    columnIndex: 2,
  },
  name: {
    label: 'Name',
    hidden: false,
    isSortable: true,
    sortField: 'user.surname',
    columnIndex: 3,
  },
  email: {
    label: 'Email',
    hidden: false,
    isSortable: true,
    sortField: 'user.email',
    columnIndex: 4,
  },
  organization: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'auditMetadata.owningOrganizationShortName',
    columnIndex: 5,
  },
  createdDate: {
    label: 'Created Date',
    hidden: false,
    isSortable: true,
    sortField: 'createdDate',
    columnIndex: 6,
  },
  actions: ACTION_COLUMN,
};

export type USER_FEEDBACK_COLUMN_KEYS = keyof typeof USER_FEEDBACK_COLUMNS;

export interface UserFeedbackPrefSaved extends BaseSavedDef {
  showColumn: { [key in USER_FEEDBACK_COLUMN_KEYS]: boolean };
}

export interface UserFeedbackTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in USER_FEEDBACK_COLUMN_KEYS]: CitadelColumnDef };
}

export const USER_FEEDBACK_TAB: UserFeedbackTabDef = {
  viewDefKey: 'userFeedback',
  viewType: 'table',
  columns: USER_FEEDBACK_COLUMNS,
  viewName: 'Feedback Review',
  pageLink: '/feedback',
  roles: UserFeedbackRoles,
  tableName: USER_FEEDBACK_TABLE_NAME,
  domains: [NetworkDomain.J, NetworkDomain.U],
  defaultMineOnly: false,
};
