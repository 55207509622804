import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { farDateValidator } from './far-date.validator';

@Directive({
  selector: '[appFARDateValidator]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: FARDateValidatorDirective,
      multi: true,
    },
  ],
})
export class FARDateValidatorDirective implements Validator {
  @Input('appFARDateValidator') configItemToTestWhenTrue: boolean | undefined =
    undefined;
  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors | null = farDateValidator(
      this.configItemToTestWhenTrue
    )(control);
    control.setErrors(errors);
    return errors;
  }
}
