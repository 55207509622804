import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { UserFeedbackCollectionComponent } from './user-feedback-collection/user-feedback-collection.component';
import { FilterParams } from '@app/shared/models';
import { UserFeedback } from '@app/shared/models/user-feedback.model';
import { CrudService, Repository } from '@app/shared/services';
import { AuthService } from '@app/auth/auth.service';
import { UserFeedbackService } from '@app/shared/services/user-feedback.service';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { AsyncPipe, CommonModule } from '@angular/common';
import { lowerFirst } from 'lodash';

@Component({
  selector: 'app-user-feedback',
  standalone: true,
  imports: [CommonModule, UserFeedbackCollectionComponent, AsyncPipe],
  templateUrl: './user-feedback.component.html',
  styleUrl: './user-feedback.component.scss',
  providers: [
    Repository,
    { provide: CrudService, useExisting: UserFeedbackService },
  ],
})
export class UserFeedbackComponent
  implements OnInit, AfterContentChecked, AfterViewChecked
{
  loading: boolean = false;

  refresh$ = this.repo.refresh$.pipe(startWith({}));
  collection$ = this.refresh$.pipe(
    switchMap(() => {
      return this.repo.collection$;
    }),
    tap((_) => {
      this.loading = false;
    })
  );
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'createdDate',
    direction: 'DESC',
  });
  queryParamsArray: Array<string> = [];
  constructor(
    private repo: Repository<UserFeedback>,
    private changeDetect: ChangeDetectorRef,
    private auth: AuthService
  ) {}
  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.filterParams.direction = 'DESC';
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(this.filterParams);
  }
}
