<div class="my-2">
  <mat-table
    class="header-primary-color"
    [dataSource]="fvs"
    multiTemplateDataRows
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef [hidden]="!groupSelectionMode">
        <mat-checkbox
          class="header-box"
          (change)="$event ? masterToggle() : null"
          [checked]="isAllSelected()"
          [indeterminate]="!isAllSelected() && getNumSelected() > 0"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let fv"
        [hidden]="!groupSelectionMode"
        [matTooltip]="
          isSelectDisabled(fv) ? 'Not eligible for Group Selection' : ''
        "
      >
        <mat-checkbox
          [disabled]="isSelectDisabled(fv)"
          (click)="toggleSelection(fv); $event.stopPropagation()"
          [checked]="fv.selected"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FV_COLUMNS.surname.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.fv?.showColumn?.surname === false"
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        >{{ FV_COLUMNS.surname.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-between"
        *matCellDef="let fv"
        [hidden]="savedPrefs?.fv?.showColumn?.surname === false"
      >
        <div
          class="d-flex align-items-center justify-content-around"
          *ngIf="fv | fullname: 'surnameFirst' as fullName"
        >
          <div
            class="d-flex justify-content-start ml-2"
            [matTooltip]="fullName | titlecase"
            [class.align-wrapped]="fullName.length > 15"
          >
            {{ fullName | titlecase | shorten: 25:"..." }}
          </div>
        </div>
        <div class="ml-1 d-flex">
          <mat-icon
            style="opacity: 0.7"
            *ngIf="!fv.isEditable"
            color="primary"
            matTooltip="Read Only Record"
            svgIcon="pencil-off-outline"
          >
          </mat-icon>
          <mat-icon
            *ngIf="fv.vip"
            svgIcon="star-circle"
            class="text-yellow"
            matTooltip="Expedited"
          ></mat-icon>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FV_COLUMNS.owningOrgShortname.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.fv?.showColumn?.owningOrgShortname === false"
      >
        {{ FV_COLUMNS.owningOrgShortname.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.fv?.showColumn?.owningOrgShortname === false"
        class="justify-content-center"
        *matCellDef="let fv"
        [matTooltip]="
          fv.auditMetadata
            ? fv.auditMetadata.owningOrganizationName ||
              fv.auditMetadata.owningOrganizationShortName
            : 'N/A'
        "
        >{{
          fv.auditMetadata
            ? (fv.auditMetadata.owningOrganizationShortName ||
                fv.auditMetadata.owningOrganizationName | shorten: 8:"...")
            : "N/A"
        }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FV_COLUMNS.birthDate.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.fv?.showColumn?.birthDate === false"
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ FV_COLUMNS.birthDate.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.fv?.showColumn?.birthDate === false"
        class="justify-content-center"
        style="text-align: center"
        *matCellDef="let fv"
        [matTooltip]="
          fv.dateOfBirth
            ? 'DOB: ' + fv.dateOfBirth
            : 'DOB required for screening'
        "
      >
        <ng-container *ngIf="fv.dateOfBirth">{{
          fv.dateOfBirth | date | default
        }}</ng-container>
        <ng-container *ngIf="!fv.dateOfBirth">
          <span class="d-flex align-items-center"
            ><mat-icon color="warn" class="md-16">warning</mat-icon> N/A</span
          >
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FV_COLUMNS.citizenshipCountry.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.fv?.showColumn?.citizenshipCountry === false"
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ FV_COLUMNS.citizenshipCountry.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.fv?.showColumn?.citizenshipCountry === false"
        class="justify-content-center text-center"
        *matCellDef="let fv"
      >
        <div
          class="citizenship-country border-outline"
          [matTooltip]="getCountryName(fv.citizenshipCountry) | default"
        >
          <mat-icon
            *ngIf="fv"
            class="flag"
            matTooltipPositionAtOrigin
            [flagIcon]="fv.citizenshipCountry || 'XX'"
          ></mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="FV_COLUMNS.passport.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.fv?.showColumn?.passport === false"
        >{{ FV_COLUMNS.passport.label }}</mat-header-cell
      >
      <mat-cell
        [hidden]="savedPrefs?.fv?.showColumn?.passport === false"
        class="justify-content-center"
        *matCellDef="let fv"
        [matTooltip]="
          !(fv.passports || [])[0]?.number ||
          !(fv.passports || [])[0]?.issuingCountryCode
            ? ''
            : ((fv.passports || [])[0]?.issuingCountryCode | country | default)
        "
      >
        <ng-container
          *ngIf="
            !(fv.passports || [])[0]?.number ||
            !(fv.passports || [])[0]?.issuingCountryCode
              ? ''
              : ((fv.passports || [])[0]?.issuingCountryCode
                | country
                | default)
          "
        >
          <ng-container
            *ngIf="
              !(fv.passports || [])[0]?.number ||
              !(fv.passports || [])[0]?.issuingCountryCode
            "
          >
            <mat-icon
              matTooltip="More info required for screening"
              color="warn"
              class="md-16"
              >warning</mat-icon
            >
          </ng-container>
          {{ (fv.passports || [])[0]?.number | default }} |
          {{ (fv.passports || [])[0]?.issuingCountryCode | default }}
        </ng-container>
        <span
          class="d-flex align-items-center"
          [matTooltip]="
            hasDocumentData(fv)
              ? ''
              : 'Identification Document Required for Screening'
          "
          *ngIf="!fv.passports[0]"
          ><mat-icon *ngIf="!hasDocumentData(fv)" color="warn" class="md-16"
            >warning</mat-icon
          >N/A</span
        >
      </mat-cell>

      <ng-container
        [matColumnDef]="FV_COLUMNS.latestScreeningModifiedDate.sortField"
      >
        <mat-header-cell
          [hidden]="!savedPrefs?.fv?.showColumn?.latestScreeningModifiedDate"
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ FV_COLUMNS.latestScreeningModifiedDate.label }}
        </mat-header-cell>
        <mat-cell
          [hidden]="
            savedPrefs?.fv?.showColumn?.latestScreeningModifiedDate === false
          "
          class="justify-content-center"
          style="text-align: center"
          *matCellDef="let fv"
        >
          <ng-container *ngIf="fv.latestScreening?.lastModifiedDate">{{
            fv.latestScreening?.lastModifiedDate | date: ScreeningDateFormat
          }}</ng-container>
          <ng-container *ngIf="!fv.latestScreening?.lastModifiedDate"
            >N/A</ng-container
          >
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="FV_COLUMNS.latestScreeningStatus.sortField">
        <mat-header-cell
          [hidden]="savedPrefs?.fv?.showColumn?.latestScreeningStatus === false"
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
          >{{ FV_COLUMNS.latestScreeningStatus.label }}</mat-header-cell
        >
        <mat-cell
          class="justify-content-center"
          *matCellDef="let fv"
          [hidden]="!savedPrefs?.fv?.showColumn?.latestScreeningStatus"
        >
          <app-screening-indicator
            id="'screening-indicator-{{ fv.id }}"
            *ngIf="fv.latestScreening?.status"
            [screening]="fv.latestScreening"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [display]="'status'"
            iconSize="large"
          ></app-screening-indicator>
          <mat-icon
            *ngIf="!fv.latestScreening"
            class="status align-self-center"
            matTooltip="Latest Screening Missing"
            svgIcon="progress-alert"
            style="color: white; background: black"
          >
          </mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="FV_COLUMNS.latestScreeningResult.sortField">
        <mat-header-cell
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
          [hidden]="savedPrefs?.fv?.showColumn?.latestScreeningResult === false"
          >{{ FV_COLUMNS.latestScreeningResult.label }}</mat-header-cell
        >
        <mat-cell
          class="justify-content-center"
          *matCellDef="let fv"
          [hidden]="savedPrefs?.fv?.showColumn?.latestScreeningResult === false"
        >
          <app-screening-indicator
            id="'screening-result-{{ fv.id }}"
            *ngIf="fv.latestScreening?.result"
            [screening]="fv.latestScreening"
            [display]="'result'"
            [showResultText]="false"
            iconSize="large"
          >
          </app-screening-indicator>
          <span *ngIf="!fv.latestScreening?.result">N/A</span>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="FV_COLUMNS.additionalData.sortField">
        <mat-header-cell
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          [hidden]="savedPrefs?.fv?.showColumn?.additionalData === false"
          >Additional Data</mat-header-cell
        >
        <mat-cell
          class="justify-content-center"
          *matCellDef="let fv"
          [hidden]="savedPrefs?.fv?.showColumn?.additionalData === false"
        >
          <app-fn-metadata
            [fn]="fv"
            [tableDisplay]="true"
            [farScreeningEligibility]="farScreeningEligibility"
          ></app-fn-metadata>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="FV_COLUMNS.actions.sortField">
        <mat-header-cell
          class="justify-content-center"
          *matHeaderCellDef
          [hidden]="savedPrefs?.fv?.showColumn?.actions === false"
          >Actions</mat-header-cell
        >
        <mat-cell
          class="justify-content-center"
          *matCellDef="let fv"
          [hidden]="savedPrefs?.fv?.showColumn?.actions === false"
        >
          <button
            mat-icon-button
            type="button"
            #actionTrigger="matMenuTrigger"
            [matMenuTriggerFor]="actionMenu"
            matTooltip="Click For Actions"
            matTooltipPosition="right"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- TODO:  The actions are very similar to the Card Footer.
                    There is an oportunity to outsource this to a single
                    component that has an input for display : 'footer' | 'menu'
        -->
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="viewRow(fv.id)" id="view-button">
              <mat-icon>visibility</mat-icon>
              View
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="dataColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: dataColumns"
      ></mat-row></ng-container
  ></mat-table>
</div>
