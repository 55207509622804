<div class="grid-collection">
  <div class="grid-header">
    <app-search-toolbar
      viewDefKey="userFeedback"
      [filterFields]="UserFeedbackAdvancedSearch"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamsChange($event)"
      [columns]="UserFeedbackTableColumns"
      [columnLabels]="UserFeedbackSortFieldLabels"
      [tableName]="UserFeedbackTableName"
      filterPlaceholder="Filter Criteria (e.g): Given Name, Surname, Feedback, Subject Title"
      hideViewToggle="true"
      [loading]="loading"
      >Feedback Review</app-search-toolbar
    >
  </div>
  <ng-container *ngIf="pageableCollection">
    <div class="container">
      <ng-container *ngIf="pageableCollection.content.length; else noData">
        <app-user-feedback-table
          [data]="pageableCollection.content"
          [filterParams]="filterParams"
          class="app-user-feedback-table-container"
          (filterParamsChange)="onFilterParamsChange($event)"
        ></app-user-feedback-table>
      </ng-container>
      <ng-template #noData>
        <app-no-result-found></app-no-result-found>
      </ng-template>
    </div>
    <div class="grid-footer">
      <app-paginator
        class="sticky"
        [filterParams]="filterParams"
        (filterParamsChange)="onFilterParamsChange($event)"
        [pageIndex]="pageableCollection.number"
        [pageSize]="pageableCollection.size"
        [totalElements]="pageableCollection.totalElements"
      ></app-paginator>
    </div>
  </ng-container>
</div>
