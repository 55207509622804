import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountsAdvancedSearch } from '@app/admin/accounts/accounts-collection/accounts-advanced-search';
import { User } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { UserEditRoles } from '@shared/models/role-permissions';

import { AppConfigValue } from '@app/shared/constants/shared.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import {
  UserSortFieldLabels,
  UserTableColumns,
  UserTableName,
} from '@app/user-profile/constants/user.const';

const newUserAction: ActionButton = {
  label: 'New User Account',
  menuIcon: 'note_add',
  userDialog: true,
  roles: UserEditRoles,
};

const allActions: ActionButton[] = [newUserAction];

@Component({
  selector: 'app-accounts-collection',
  templateUrl: 'accounts-collection.component.html',
  styleUrls: ['accounts-collection.component.scss'],
})
export class AccountsCollectionsComponent {
  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;
  userPreference: UserPrefSaved | undefined;
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<User>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  actionButtons: ActionButton[];
  showingInactive: boolean = false;
  hasUserPreference: boolean = false;

  constructor() {
    this.actionButtons = this.limitedUserAdmin ? [] : allActions;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly AccountsAdvancedSearch = AccountsAdvancedSearch;
  protected readonly UserTableColumns = UserTableColumns;
  protected readonly UserSortFieldLabels = UserSortFieldLabels;
  protected readonly UserTableName = UserTableName;
}
