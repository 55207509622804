import { SortSelect } from '@app/shared/constants/shared.const';
export const FVTableName = 'fvTable';
/**
 * @deprecated
 */
export enum FvSortField {
  ORG = 'owningOrgShortname',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  SURNAME = 'surname',
  CITIZENSHIP_COUNTRY = 'citizenshipCountry',
  RESIDENCE_COUNTRY = 'residenceCountryObject.name',
  SCREENING_STATUS = 'latestScreening.status',
  DATE_OF_BIRTH = 'dateOfBirth',
  PASSPORT = 'latestPassportNumber',
  LAST_SCREENING_COMPLETED = 'latestScreening.completedDate',
  LAST_SCREENING_REQUESTED = 'latestScreening.requestedDate',
  LAST_SCREENING_MODIFIED = 'latestScreening.lastModifiedDate',
  LAST_SCREENING_RESULT = 'latestScreening.result',
  LINKED_NATIONALS_COUNT = 'linkedVisitorCount',
  NETWORK_DOMAIN = 'networkDomain',
}

/**
 * @deprecated
 */
export const FvSortFieldLabels: { [key in FvSortField]: string } = {
  [FvSortField.ORG]: 'Organization',
  [FvSortField.CREATED_DATE]: 'Created Date',
  [FvSortField.LAST_MODIFIED_DATE]: 'Last Modified Date',
  [FvSortField.SURNAME]: 'Foreign National',
  [FvSortField.CITIZENSHIP_COUNTRY]: 'Citizenship Country',
  [FvSortField.RESIDENCE_COUNTRY]: 'Residence Country',
  [FvSortField.SCREENING_STATUS]: 'Screening Status',
  [FvSortField.DATE_OF_BIRTH]: 'Date of Birth',
  [FvSortField.PASSPORT]: 'Passport',
  [FvSortField.LAST_SCREENING_COMPLETED]: 'Last Screening Completed',
  [FvSortField.LAST_SCREENING_REQUESTED]: 'Last Screening Requested',
  [FvSortField.LAST_SCREENING_MODIFIED]: 'Last Screening Modified',
  [FvSortField.LAST_SCREENING_RESULT]: 'Screening Results',
  [FvSortField.LINKED_NATIONALS_COUNT]: 'Linked Nationals Count',
  [FvSortField.NETWORK_DOMAIN]: 'Network',
};
/**
 * @deprecated
 */
export const FvSorts: SortSelect[] = Object.values(FvSortField).map((value) => {
  return { label: FvSortFieldLabels[value], value };
});

/**
 * @deprecated
 */
export const FVTableColumns: string[] = [
  'select',
  FvSortField.ORG,
  FvSortField.SURNAME,
  FvSortField.CITIZENSHIP_COUNTRY,
  FvSortField.DATE_OF_BIRTH,
  FvSortField.PASSPORT,
  FvSortField.LAST_SCREENING_MODIFIED,
  FvSortField.SCREENING_STATUS,
  FvSortField.LAST_SCREENING_RESULT,
  'additionalData',
  'actions',
];
