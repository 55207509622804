import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { LocationsComponent } from './locations/locations.component';
import { LocViewRoles } from '@shared/models/role-permissions';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { LOCATIONS_TAB } from '@app/user-preference/config/location.config.const';

const routes: Routes = [
  {
    path: 'locations',
    title: 'Locations',
    component: LocationsComponent,
    canActivate: [AuthGuard],
    data: { roles: LocViewRoles, viewDefKey: LOCATIONS_TAB.viewDefKey },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LocationRoutingModule {}
