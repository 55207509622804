import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FarAdvancedSearch } from '@app/foreign-access-requests/dashboard/far-advanced-search';
import { getArrayKeys } from '@app/shared/components/advanced-search/filter-config';
import { FAR, FilterParams } from '@app/shared/models';
import { CrudService, FarService, Repository } from '@app/shared/services';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-far-workspace',
  templateUrl: './far-workspace.component.html',
  styleUrls: ['./far-workspace.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FarService }],
})
export class FarWorkspaceComponent implements OnInit {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly authService: AuthService = inject(AuthService);
  filterParams = this.repo.defaultFilterParams;
  far$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  loading: boolean = false;

  constructor(private repo: Repository<FAR>) {}

  ngOnInit(): void {
    this.loading = true;
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    const userId: string = this.authService.getUser()?.id as string;
    this.filterParams.currentUserCreatedBy = savedValue?.far?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot(...getArrayKeys(FarAdvancedSearch));
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
