<div id="release-2-7-0" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.0 Release Notes</h3>
  <h6>Foreign National View</h6>
  <p>
    The Foreign National View has undergone an extensive design change. The
    functionality should remain relatively the same from prior versions, just
    located in different areas and display a bit differently. Any feedback is
    welcome on these changes.
  </p>
  <ul>
    <li>
      <b>Linked Foreign Nationals:</b> this section will not show up unless
      there are linked nationals. When clicking on a Linked National, a new
      Window Tab will pop up and display the linked national. Note: in the
      Linked National View, there will not be a section for linked nationals as
      we’re not currently going to allow linked of linked nationals.
    </li>
    <li>
      <b>Linked Foreign Nationals Card:</b> it is currently intended not to
      display the number of linked Nationals, Fars, Attachments, and Comments
      due to space reasons. This will be reassessed for next patch once Far
      Eligibility for Screening is turned on.
    </li>
    <li>
      <b>Passports:</b> if there are no passports attached to Foreign Nationals,
      a Red Button will now display in the top right corner. This has a Tooltip
      display that will tell the user that it is Required for Screening.
    </li>
    <li>
      <b>Screening Events:</b> this section is basically a Card style version of
      Screening History. Note: for the High Side version, the Screening Codes
      will NOT appear here. A user will still have to click use the Action menu
      to see the Screening Codes due to them potentially having different
      classification levels from the Foreign National’s data.
    </li>
    <li>
      <b>Foreign Access Requests:</b> this entire section has been reworked. It
      now uses the FAR services to retrieve the list of FARs for a Foreign
      National. As such, it will now page requests. Filtering on All or Upcoming
      FAR function should remain relatively the same (aside from potential
      paging).
    </li>
    <li>
      <b
        >Visa, National Id, Education, Employment, Social Media, Attachments,
        and Additional Information:</b
      >
      these sections should all retain the same functionality they had prior to
      this patch.
    </li>
    <li>
      <b>Vehicle and Identifying Information:</b> these two sections have been
      combined for space issues. The functionality will remain the same.
    </li>
    <li>
      <b>Last Modifying User Details:</b> this has been moved from next to the
      Foreign National title to under the Action Menu. Functionality remains the
      same.
    </li>
    <li>
      <b>Network Icon added:</b> located under the Last Modifying User Details
      Icon
    </li>
    <li>
      <b>Personal Info:</b> this information has been moved up in the new larger
      header bar.
    </li>
    <li>
      <b>VIP and Inherited Icons removed:</b> these icons will no longer be
      displayed in this view. They will more than likely be entirely removed in
      the future in a more concerted effort. Inherited can no longer happen to
      begin with as it has been shut off, so it would only appear on legacy
      data, and VIP has zero impact on the automated process.
    </li>
  </ul>
</div>
