import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FvSorts } from '@app/foreign-visitors/constants/foreign-visitors.const';
import { SortSelect } from '@app/shared/constants/shared.const';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';

@Component({
  selector: 'app-screening-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class ScreeningCollectionComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  FvSorts: SortSelect[] = FvSorts;

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  ngOnInit(): void {
    const savedPref: UserPrefSaved =
      this.route.snapshot.data.userPrefFormGroup.value;
    const params: FilterParams = new FilterParams({
      ...this.route.snapshot.params,
    });

    if (savedPref.screening?.defaultMineOnly === true) {
      params.currentUserCreatedBy = true;
      this.onFilterParamChange(params);
    }
  }
}
