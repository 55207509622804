import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpContext } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  ItineraryControlComponent,
  StepperFooterComponent,
} from '@app/shared/components';
import { SUPPRESS_MESSAGE } from '@app/shared/helpers/success-message.interceptor';
import { FarService } from '@app/shared/services';
import { PhoneValidator } from '@app/shared/validators/phone-number.validator';
import {
  FAR,
  FarLocation,
  FarPurposeType,
  ForeignVisitor,
  Organization,
} from '@shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SponsorComponent } from './sponsor/sponsor.component';
@Component({
  selector: 'app-far-creation',
  templateUrl: './far-creation.component.html',
  styleUrls: ['./far-creation.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class FarCreationComponent implements OnInit, OnDestroy {
  /** Emits when the component is destroyed. */
  private readonly _destroyed = new Subject<void>();
  userOrg: Organization = {};

  far: FAR = {
    foreignAccessRequestLocations: [],
  };

  location: FarLocation = {
    startDate: '',
    endDate: '',
  };

  @ViewChild('sponsorControl')
  sponsorControl: SponsorComponent;

  @ViewChild('stepperFooter')
  stepperFooter: StepperFooterComponent;

  @ViewChild('itineraryControl')
  itineraryControl: ItineraryControlComponent;

  constructor(
    private router: Router,
    private farService: FarService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.userOrg = this.auth.getOrganization() || {};
    this.far.foreignAccessRequestLocations?.push(this.location);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onSubmit() {
    this.farService
      .save(this.far, new HttpContext().set(SUPPRESS_MESSAGE, true))
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        (result) => {
          this.router.navigate(['/far', result.id]);
        },
        (err) => (this.stepperFooter.busy = false)
      );
  }

  hasRequirements() {
    if (
      this.far.eventTitle &&
      this.far.purposeType &&
      this.location.startDate &&
      this.location.endDate &&
      this.itineraryControl.valid &&
      this.isLocationValid()
    )
      return true;
    return false;
  }

  isLocationValid() {
    if (
      this.far.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.far.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      if (!this.location.organizationLocation) return false;
    }

    return true;
  }

  hasValidPhoneNumber() {
    let regex = new RegExp(PhoneValidator.getPattern());
    if (this.far?.sponsor?.phone) return regex.exec(this.far?.sponsor?.phone);
    return true;
  }

  hasSponsorRequirements() {
    if (!this.far.sponsor) return true;
    if (
      this.far.sponsor &&
      this.far.sponsor.givenName &&
      this.far.sponsor.surname &&
      this.far.sponsor.email
    ) {
      if (this.far.sponsor.phone) return this.hasValidPhoneNumber();
      return true;
    }

    if (
      this.far.sponsor &&
      !this.far.sponsor.givenName &&
      !this.far.sponsor.surname &&
      !this.far.sponsor.email &&
      !this.far.sponsor.phone
    )
      return true;
    return false;
  }

  isInvalid(farForm: NgForm) {
    if (this.hasRequirements() && this.hasSponsorRequirements()) {
      farForm.form.setErrors(null);
      let sponsorForm: any = farForm.form.controls['farFV'];
      if (sponsorForm) sponsorForm.controls.sponsor.setErrors(null);
      return false;
    }
    farForm.form.setErrors({ incorrect: true });
    return true;
  }

  onRemove(fv: ForeignVisitor) {
    const filtered = this.far.foreignVisitors?.filter((x) => {
      return fv.id != x.id;
    });
    this.far.foreignVisitors = filtered;
  }
}
