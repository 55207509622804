import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { AuthModule } from '@app/auth/auth.module';
import {
  EditContactInfoComponent,
  EditUserContactInfoDirective,
} from './edit-contact-info/edit-contact-info.component';
import { ProfileComponent } from './profile/profile.component';
import { UserAcctComponent } from './profile/user-account/useracct.component';
import { UserProfileRoutingModule } from './user-profile-routing.module';

@NgModule({
  declarations: [
    UserAcctComponent,
    ProfileComponent,
    EditContactInfoComponent,
    EditUserContactInfoDirective,
  ],
  imports: [
    SharedModule,
    AuthModule,
    UserProfileRoutingModule,
    ReactiveFormsModule,
  ],
})
export class UserProfileModule {}
