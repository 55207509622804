<div id="release-2-7-9" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.9 Release Notes</h3>
  <h6>Asynchronous Bulk Upload</h6>
  <p>
    Several large changes have come to Bulk Upload. The Bulk Upload service has
    been converted over to an asynchronous service. The normal ways, menus, and
    views to Bulk Upload still remain. The major change here is that once a Bulk
    Upload job is submitted, the Bulk Upload Window will immediately close, and
    a Blue notification message will immediately tell the user the job has been
    submitted. Users can now submit multiple jobs at the same time without
    having to wait for the previous job to complete.
  </p>

  <p>
    Bulk Upload Notifications have been added to the left of the Help button in
    the Main Citadel Application. This notification Icon will display the number
    of Bulk Upload jobs that are available to view (icon will not display if
    there are no jobs available). Bulk Upload jobs are viewable until they are
    either individually Marked As Read, or all the Viewable Ones are Marked as
    Read (R-check button in the Menu Header).
  </p>
  <p>
    Once a Bulk Upload job is completed, there are potentially 1-2 actions a
    user could take depending on the disposition status. If an Error occurred, a
    user will be able to click on the Error Icon to get a read out of what
    caused an issue, for example: the spreadsheet was uploaded previously, and
    no changes were detected.
  </p>
  <p>
    If the Bulk Upload job is marked with a Blue Completed Icon, a user will be
    able to click into that icon to see any potential validation issues or click
    on the Go to Results icon which will take them to the corresponding Foreign
    National or Locations page depending on type.
  </p>
  <ng-container *appIfConfig="{ key: 'highEnvFeatures', value: true }">
    <h6>Derogatory Metrics</h6>
    <p>
      High Side Only, an additional graph has been added to the Metrics page
      that will break down the Derogatory codes received over a period of time
      by date. This graph has the same functionality as previous graphs with
      Day, Week, and Month date range selectors and follows the same
      restrictions as the other graphs in this vein. There is also a Details
      panel like the other Screening graphs that has the Total Count of each
      Derogatory code over the selected time period.
    </p>
  </ng-container>
  <h6>User Preferences</h6>
  <p>
    A limited selection of User preferences is now available to all users.
    Accessible from the User Menu, this page will allow users to set their view
    (Card/Table) where applicable, starting page (FN, FAR, Location, User Admin,
    etc – should match what a user can see from the App Nav Menu), and default
    table columns for table views (where applicable).
  </p>
</div>
