import { Component } from '@angular/core';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { Role } from '@app/shared/models';

@Component({
  selector: 'app-release-notes-2-7-5',
  standalone: true,
  imports: [DirectivesModule],
  templateUrl: './release-notes-2-7-5.component.html',
})
export class ReleaseNotes275Component {
  Role = Role;
}
