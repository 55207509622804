import { Component, Inject, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { Role, User } from '@app/shared/models';
import { UserFeedback } from '@app/shared/models/user-feedback.model';
import { UserFeedbackFormComponent } from '../user-feedback-form/user-feedback-form.component';
import { UserFeedbackService } from '@app/shared/services/user-feedback.service';
import { AuthService } from '@app/auth/auth.service';
import { take } from 'rxjs';
import { cloneDeep } from 'lodash';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-user-feedback-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogActions,
    MatDialogContent,
    UserFeedbackFormComponent,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './user-feedback-dialog.component.html',
  styleUrl: './user-feedback-dialog.component.scss',
})
export class UserFeedbackDialogComponent implements OnInit {
  public Role = Role;
  userFeedback: UserFeedback = {};
  user: User | null = this.authService.getUser();
  @ViewChild('userFeedbackForm') userFeedbackForm: UserFeedbackFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public feedbackId: string | null,
    public userFeedbackDialog: MatDialogRef<UserFeedbackDialogComponent>,
    private userFeedbackService: UserFeedbackService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.feedbackId && typeof this.feedbackId === 'string') {
      this.getUserFeedback(this.feedbackId);
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (this.user?.roles.includes(Role.sv_admin) && this.feedbackId) {
        return true;
      }
      return false;
    }
    return false;
  }

  getUserFeedback(id: string) {
    this.userFeedbackService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.userFeedback = cloneDeep(data);
      });
  }

  onUserFeedbackChange(userFeedback: any) {
    this.userFeedback = userFeedback;
  }

  hasRequiredData() {
    if (this.userFeedback.feedback) {
      return true;
    }
    return false;
  }

  onClose() {
    this.userFeedbackDialog.close(this.userFeedback);
  }

  static openDialog(
    dialog: MatDialog,
    userFeedback?: UserFeedback
  ): MatDialogRef<UserFeedbackDialogComponent> {
    return dialog.open<UserFeedbackDialogComponent>(
      UserFeedbackDialogComponent,
      {
        data: userFeedback,
        width: '750px',
      }
    );
  }
}
