import { inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ResolveFn } from '@angular/router';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { firstValueFrom } from 'rxjs';
import { UserPreference } from '../model/user-preference.model';
import { UserPreferenceFormService } from '../service/user-preference-form.service';
import { UserPreferenceService } from '../service/user-preference.service';

/**
 * Return the userPreferenceForm as a FormGroup so that controls (e.g., column checkboxes) can be reused.
 * @param route
 * @param state
 * @returns
 */
export const userPreferenceFormResolver: ResolveFn<FormGroup> = async (
  route,
  state
) => {
  const userPrefService: UserPreferenceService = inject(UserPreferenceService);
  const userPrefFormService: UserPreferenceFormService = inject(
    UserPreferenceFormService
  );

  const userPref: UserPreference = (await firstValueFrom(
    userPrefService.getUserPrefs(true)
  )) as UserPreference;
  return userPrefFormService.initUserPreferenceFormGroup(
    userPref?.userPreferenceBlob as UserPrefSaved
  ) as FormGroup;
};
