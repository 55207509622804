import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FnGroupDialogComponent } from '@app/foreign-visitors/fn-group-dialog/fn-group-dialog.component';
import { SortSelect } from '@app/shared/constants/shared.const';
import { FilterParams, Role, View } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import {
  AsyncBulkUploadObservable,
  MetricsObservable,
} from '@app/shared/services';
import { AddressService } from '@app/shared/services/address.service';
import { ViewType } from '@app/user-preference/constants/user-preference-tabs.const';
import { VIEW_DEF_KEYS } from '@app/user-preference/constants/view-properties.const';
import { FilterConfig } from '@shared/components/advanced-search/filter-config';
import { take } from 'rxjs';
import { FilterComponent } from '../filter/filter.component';
import { CollectionView } from '../view/view.component';

@Component({
  selector: 'app-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
})
export class SearchToolbarComponent implements OnInit, AfterViewInit {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  @ViewChild('appFilter') appFilter: FilterComponent;
  @ViewChild('slideToggle', { read: ElementRef }) element:
    | ElementRef
    | undefined;
  @Input() filterPlaceholder: string = 'Given Name, Surname';
  @Input() filterParams: FilterParams;
  @Input() filterFields: FilterConfig[] = [];
  /**
   * @deprecated
   */
  @Input() sortFields: SortSelect[] = [];
  /**
   * @deprecated
   */
  @Input() columns: string[];
  /**
   * @deprecated
   */
  @Input() columnLabels: any;
  /**
   * @deprecated
   */
  @Input() tableName: string = '';
  @Input() actionButtons: ActionButton[];
  @Input() collectionViews: View[];
  @Input() compactCard: boolean = false;
  @Input() view: ViewType = 'table';
  @Input() loading: boolean = false;
  @Input() viewDefKey: VIEW_DEF_KEYS;
  @Output() viewChange = new EventEmitter<CollectionView>();
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Output() groupSelectChange = new EventEmitter<any>();
  _hideViewToggle: boolean = false;
  showAllGroups: boolean = false;
  groupSelectionMode: boolean = false;
  Role = Role;
  userId: string = '';

  @Input() set hideViewToggle(value: any) {
    this._hideViewToggle = coerceBooleanProperty(value);
  }
  get hideViewToggle(): boolean {
    return this._hideViewToggle;
  }

  constructor(
    private _MetricsObservable: MetricsObservable,
    private authService: AuthService,
    private dialog: MatDialog,
    private address: AddressService,
    private poll: AsyncBulkUploadObservable
  ) {}
  ngOnInit(): void {
    this.viewDefKey = this.route.snapshot?.data?.viewDefKey
      ? this.route.snapshot?.data?.viewDefKey
      : this.viewDefKey;
    if (!this.viewDefKey)
      throw `viewDefkey was not provided as an input or through the route data for ${SearchToolbarComponent.name}`;
    const view: ViewType = this.route.snapshot.data.userPrefFormGroup?.get(
      `${this.viewDefKey}.viewType`
    )?.value as ViewType;
    if (view) {
      this.viewChange.emit(view);
    }
  }

  ngAfterViewInit() {
    //Remove Slide Toggle Icons
    if (this.element) {
      this.element.nativeElement
        .querySelector('.mdc-switch__icon--on')
        .firstChild.setAttribute('d', '');
      this.element.nativeElement
        .querySelector('.mdc-switch__icon--off')
        .firstChild.setAttribute('d', '');
    }
  }

  onViewChange(view: CollectionView) {
    this.viewChange.emit(view);
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.refresh();

    this._MetricsObservable.send('update');
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  selectFvGroup() {
    this.groupSelectionMode = true;
    this.groupSelectChange.emit(this.groupSelectionMode);
  }

  cancelSelectFvGroup() {
    this.groupSelectionMode = false;
    this.groupSelectChange.emit(this.groupSelectionMode);
  }

  createFvGroup() {
    this.groupSelectChange.emit(null);
  }

  createEmptyGroup() {
    let dialogRef: MatDialogRef<FnGroupDialogComponent> =
      this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
        data: null,
      });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  normalizeLocations() {
    this.address
      .resolveLocations()
      .pipe(take(1))
      .subscribe(() => this.poll.startPolling(true));
  }
}
