import { NetworkDomain } from '@app/shared/models/network-domain';
import { UserEditRoles } from '@app/shared/models/role-permissions';
import { ACTION_COLUMN } from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const USER_ADMIN_TABLE_NAME = 'userTable';

export interface UserAdminAccountColumnDefs {
  systemUser: CitadelColumnDef;
  email: CitadelColumnDef;
  accountStatus: CitadelColumnDef;
  organization: CitadelColumnDef;
  phone: CitadelColumnDef;
  lastLoginDate: CitadelColumnDef;
  roles: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const USER_ADMIN_ACCOUNT_COLUMNS: UserAdminAccountColumnDefs = {
  systemUser: {
    label: 'System User',
    hidden: false,
    isSortable: true,
    sortField: 'surname',
    columnIndex: 0,
  },
  email: {
    label: 'Email',
    hidden: false,
    isSortable: true,
    sortField: 'email',
    columnIndex: 2,
  },
  organization: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'organization.shortName',
    columnIndex: 3,
  },
  accountStatus: {
    label: 'Account Status',
    columnIndex: 4,
    hidden: false,
    isSortable: true,
    sortField: 'accountStatus',
  },
  phone: {
    label: 'Phone',
    columnIndex: 5,
    hidden: false,
    isSortable: true,
    sortField: 'phone',
  },
  lastLoginDate: {
    label: 'Last Login',
    columnIndex: 6,
    hidden: false,
    isSortable: true,
    sortField: 'lastLogin',
  },
  roles: {
    label: 'Roles',
    columnIndex: 7,
    hidden: false,
    isSortable: false,
    sortField: 'roles',
  },
  actions: ACTION_COLUMN,
};

export type USER_ADMIN_ACCOUNT_COLUMN_KEYS =
  keyof typeof USER_ADMIN_ACCOUNT_COLUMNS;

export interface UserAdminAccountPrefSaved extends BaseSavedDef {
  showColumn: { [key in USER_ADMIN_ACCOUNT_COLUMN_KEYS]: boolean };
}

export interface UserAdminAccountTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in USER_ADMIN_ACCOUNT_COLUMN_KEYS]: CitadelColumnDef };
}

export const USER_ADMIN_ACCOUNT_TAB: UserAdminAccountTabDef = {
  viewDefKey: 'userAdministration',
  defaultMineOnly: false,
  viewType: 'table',
  columns: USER_ADMIN_ACCOUNT_COLUMNS,
  viewName: 'User Administration',
  pageLink: '/admin',
  roles: UserEditRoles,
  tableName: USER_ADMIN_TABLE_NAME,
  domains: [NetworkDomain.J, NetworkDomain.U],
};
