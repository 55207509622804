import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { Approval } from '@app/shared/models/approval';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { AppConfigService } from '@app/shared/services/app-config.services';
import {
  USER_ADMIN_APPROVAL_COLUMNS,
  USER_ADMIN_APPROVAL_TAB,
  UserAdminApprovalColumnDefs,
} from '@app/user-preference/config/user-admin-approval.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { FilterParams, User } from '@shared/models';
import {
  ApprovalService,
  ApprovalSortField,
} from '@shared/services/approval.service';
import { Subject, Subscription } from 'rxjs';

export const ApprovalTableColumns = [
  ApprovalSortField.NAME,
  ApprovalSortField.EMAIL,
  ApprovalSortField.ORGANIZATION,
  ApprovalSortField.APPROVER,
  ApprovalSortField.APPROVAL_DATE,
  ApprovalSortField.EXPIRATION_DATE,
  'actions',
];

export const ApprovalTableName = 'approvalTable';

@Component({
  selector: 'app-approval-table',
  templateUrl: './approval-table.component.html',
  styleUrls: ['./approval-table.component.scss'],
})
export class ApprovalTableComponent implements OnInit, OnDestroy {
  @Input() data: Approval[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  private readonly configService: AppConfigService = inject(AppConfigService);
  private readonly authService: AuthService = inject(AuthService);
  private currentUser: User = this.authService.getUser() as User;

  private ngUnsubscribe = new Subject<void>();

  @Output() sortChange = new EventEmitter<Sort>();

  currentNetWork = this.configService.get('highEnvFeatures')
    ? NetworkDomain.J
    : NetworkDomain.U;
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  USER_ADMIN_APPROVAL_COLUMNS: UserAdminApprovalColumnDefs =
    USER_ADMIN_APPROVAL_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    USER_ADMIN_APPROVAL_TAB
  );

  constructor(
    private approvalService: ApprovalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  extendApproval(approval: Approval) {
    this.approvalService.save(approval).subscribe();
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  protected readonly parseInt = parseInt;
}
