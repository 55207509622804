<div id="release-2-7-4" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.4 Release Notes</h3>
  <h6>Foreign Access Requests</h6>
  <p>
    FARs have been changed to only allow one itinerary item per FAR. Legacy FARs
    with multiple itinerary items will still be supported, however, no new
    itinerary items will be able to be added. Legacy FARs with no itinerary
    items will also still be allowed to add one itinerary item.
  </p>
  <p>FAR creation screen has been updated to only allow one itinerary item.</p>
  <p>
    FAR View Itinerary section has been updated to better display itinerary
    items for single itinerary item FARs. FARs with multiple itinerary items
    will retain the legacy view.
  </p>
  <p>
    Visit Details Description has been removed from the FAR creation screen. Any
    legacy FARs will still display this information if it exists. If this
    information is cleared out of a Legacy FAR, once submitted, the field will
    disappear and no longer allow entry for legacy FARs with this data.
  </p>
  <p>
    Rescreen has been added to the Foreign Nationals section. This button will
    allow all Foreign Nationals (should they be eligible) attached to a FAR to
    be rescreened. Foreign Nationals attached to a FAR must be in a Completed
    Status to be eligible for rescreen. A minimum of one FN needs to be rescreen
    eligible for this button to be enabled (provided user has appropriate
    permissions).
  </p>
  <h6>Metrics</h6>
  <ul>
    <li>
      Machine Green and Reviewed Green have been added to the Screening Graph.
    </li>
    <li>
      Total Human Review Count has been added to the Screening Graph Count
      panel.
    </li>
    <li>
      Help Desk Role should now have access to all Insights with exception of
      the Current Organization and Organizations with FNs (this will be changed
      at a later date)
    </li>
    <li>
      User Role can now view metrics – they will have access to Screening
      Insights (similar to Vetter only role view) and Screening Result and
      Status graphs only (no Screening Results by Organization graph).
    </li>
    <li>
      Metrics returned should now show metrics for the End Date selected as well
    </li>
  </ul>
  <h6>Foreign Nationals</h6>
  <ul>
    <li>
      Screening Results Advanced filter now has Machine Green and Reviewed Green
      options available for selection.
    </li>
    <li>
      Screening Code filters should now work as intended and no longer give
      different total counts
    </li>
  </ul>
</div>
