<div id="release-2-5-2" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.5.2 Release Notes</h3>
  <h5>FAR Enhancements Summary</h5>
  <p>
    FAR has undergone substantial changes this patch with more forthcoming in
    future patches. As such, the changes will be broken down here as well as the
    forthcoming work will be detailed.
  </p>
  <h6>Current Changes</h6>
  <ul>
    <li>
      A Purpose Type field has been added. Types are as follows:
      <ul>
        <li>Facility Access</li>
        <li>In Person Meeting</li>
        <li>Virtual Meeting</li>
        <li>System Access</li>
        <li>Other</li>
      </ul>
    </li>
    <li>
      FARs with a purpose type of Facility Access and In Person Meeting will
      require all itinerary items to have a location specified. Virtual Meeting,
      System Access, and Other purpose types do not require a location on
      itinerary items (however one can be specified if desired).
    </li>
    <li>The previous Purpose field has been renamed Description.</li>
    <li>Purpose Type has been added to the FAR table.</li>
    <li>
      All FAR itinerary items now require a Start Date. End Date is not
      required; however, an End Date cannot be more than one year apart from the
      start date if entered.
    </li>
    <li>Itinerary Items will now be sorted by Start Date</li>
    <li>A minimum of one itinerary item is now required to create a FAR</li>
    <li>
      Adding Foreign Nationals to a FAR is no longer required to create a FAR.
    </li>
    <li>
      Sponsor remains optional, however, if any Sponsor information is entered,
      the Sponsor section will now require a minimum of Given Name, Surname, and
      Email address. Phone remains optional for Sponsors if data is entered.
    </li>
    <li>
      Sponsor has had the following fields removed:
      <ul>
        <li>Component</li>
        <li>Title</li>
        <li>Office</li>
        <li>Program</li>
      </ul>
    </li>
    <li>
      Foreign National View – Add to FAR window has been expanded to include the
      Itinerary portion since one itinerary item is now required.
    </li>
  </ul>
  <h6>FAR Changes with existing FARs</h6>
  <p>
    In general, FARs should function as expected with FARs that currently exist
    but don’t have a Purpose Type. FARs should be able to update the Sponsor,
    Foreign Nationals, Comments, and Attachments sections as expected. The Event
    Title, Description and Purpose Type section will require a Purpose Type to
    be selected. Additionally, Itinerary Items has some quirks when it comes to
    updating without a Purpose Type selected. Please see the documentation
    below.
  </p>
</div>
