import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { FAR, FilterParams, UserPreference } from '@app/shared/models';
import { CrudService, FarService, Repository } from '@app/shared/services';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceService } from '@app/user-preference/service/user-preference.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FarService }],
})
export class DashboardComponent implements OnInit {
  fars$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  filterParams = this.repo.defaultFilterParams;
  loading: boolean = false;

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  constructor(
    private repo: Repository<FAR>,
    private userPrefService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    const userPreference: UserPreference = this.userPrefService.userPreferences$
      .value as UserPreference;
    const savedValue: UserPrefSaved =
      userPreference.userPreferenceBlob as UserPrefSaved;
    this.filterParams.currentUserCreatedBy = savedValue.far?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
