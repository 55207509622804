<div class="container">
  <div class="row d-flex bg-primary">
    <h2 class="d-flex mt-2 align-items-center text-white" mat-dialog-title>
      <mat-icon
        class="mr-2 text-white"
        svgIcon="clipboard-text-outline"
      ></mat-icon>
      User Feedback
    </h2>
  </div>

  <mat-dialog-content style="overflow-y: hidden">
    <form
      #feedBackForm="ngForm"
      id="feedBackForm"
      class="d-flex flex-column flex-grow-1 mt-2"
    >
      <div>
        <mat-label style="font: 14px Roboto, sans-serif"
          >Feedback Type</mat-label
        >
      </div>
      <div>
        <mat-radio-group
          class="d-flex"
          style="justify-content: space-evenly"
          [(ngModel)]="feedbackType"
          name="feedbackType"
        >
          <mat-radio-button value="comment">Comment</mat-radio-button>
          <mat-radio-button value="suggestion">Suggestion</mat-radio-button>
          <mat-radio-button value="question">Question</mat-radio-button>
          <mat-radio-button value="other">Other</mat-radio-button>
        </mat-radio-group>
      </div>

      <div>
        <mat-form-field class="pt-4 w-100" floatLabel="always">
          <mat-label>Subject</mat-label>
          <input
            matInput
            placeholder="Enter subject title"
            ngModel
            [(ngModel)]="feedbackSubject"
            name="feedbackSubject"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          class="w-100"
          hintLabel="Max 2500 characters"
          floatLabel="always"
        >
          <mat-label> Feedback</mat-label>
          <textarea
            matInput
            #input
            required
            placeholder="Enter user feedback here"
            (input)="onInput($event)"
            [(ngModel)]="feedback"
            name="feedback"
            rows="5"
            maxlength="2500"
            ngModel
          ></textarea>
          <mat-hint align="end">{{ value().length }}/2500</mat-hint>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex flex-row">
    <button
      mat-button
      class="mx-1 mt-1"
      color="primary"
      mat-dialog-close
      style="border-radius: 5px"
    >
      Cancel
    </button>
    <span class="flex-grow-1"></span>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      form="feedBackForm"
      style="border-radius: 5px"
      [disabled]="!feedBackForm || feedBackForm.invalid || !feedback || busy"
      (click)="onSubmit(feedBackForm)"
    >
      <span *ngIf="!busy">Submit</span>
      <mat-spinner
        class="mr-1 ml-1"
        *ngIf="busy"
        color="primary"
        diameter="35"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</div>
