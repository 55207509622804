import { OrgLocationSortField } from '@app/organizations/constants/org.const';

/**
 * @deprecated
 */
export const LocationTableColumns: string[] = [
  OrgLocationSortField.NAME,
  OrgLocationSortField.ADDRESS,
  OrgLocationSortField.CITY_STATE_PROVINCE,
  OrgLocationSortField.POSTAL_CODE,
  OrgLocationSortField.COUNTRY,
  OrgLocationSortField.ORG,
  OrgLocationSortField.NETWORK,
  OrgLocationSortField.FAR_COUNT,
  'actions',
];

export const LocationTableName = 'locationTable';
