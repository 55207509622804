import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FarSortFieldLabels,
  FarSorts,
  FARTableColumns,
  FARTableName,
} from '@app/foreign-access-requests/constants/far-const';
import { FarAdvancedSearch } from '@app/foreign-access-requests/dashboard/far-advanced-search';
import { FAR, FilterParams, UserPreferenceBlob } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { ViewType } from '@app/user-preference/constants/user-preference-tabs.const';
import { FarEditRoles } from '@shared/models/role-permissions';

const actionButtons: ActionButton[] = [
  {
    label: 'New FAR',
    routerLink: '/far',
    menuIcon: 'note_add',
    roles: FarEditRoles,
  },
];

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
})
export class CollectionsComponent {
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<FAR>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() loading: boolean = false;
  @Input() workspaceView: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  view: ViewType = this.route.snapshot.data?.userPrefFormGroup?.value?.far
    ?.viewType as ViewType;
  headerActionButtons: ActionButton[] = actionButtons;
  FarSorts = FarSorts;
  label: string = 'Foreign Access Request (FAR)';

  constructor() {}

  onViewChange(view: ViewType) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly FarAdvancedSearch = FarAdvancedSearch;
  protected readonly FARTableColumns = FARTableColumns;
  protected readonly FarSortFieldLabels = FarSortFieldLabels;
  protected readonly FARTableName = FARTableName;
}
