import moment, { Moment } from 'moment';
/**
 * Safely convert a Moment to a Date (or null) value.  We are using Moment objects from our MatCalendarPicker objects.
 * We want to eventually move away from Moment.  Meanwhile, this function will be used to safely transform Moments to dates so
 * we can minimize the use of moment functions in date comparisons, etc.
 * @param date Moment or Date object
 */
export function momentTransformer(
  date: Moment | Date | string | null
): Date | null {
  if (!date) return null;
  if (moment.isMoment(date)) {
    const mDate: Moment = date as Moment;
    return mDate.isValid() ? mDate.toDate() : null;
  }
  return new Date(date);
}
