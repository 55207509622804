<form
  #userFeedbackForm="ngForm"
  id="userFeedbackForm"
  class="d-flex flex-column flex-grow-1 mt-2"
>
  <div>
    <mat-label style="font: 14px Roboto, sans-serif">Feedback Type</mat-label>
  </div>
  <div>
    <mat-radio-group
      class="d-flex"
      style="justify-content: space-evenly"
      [(ngModel)]="value.feedbackType"
      (ngModelChange)="emitChangeEvent()"
      name="feedbackType"
      [disabled]="true"
    >
      <mat-radio-button value="comment">Comment</mat-radio-button>
      <mat-radio-button value="suggestion">Suggestion</mat-radio-button>
      <mat-radio-button value="question">Question</mat-radio-button>
      <mat-radio-button value="other">Other</mat-radio-button>
    </mat-radio-group>
  </div>

  <div>
    <mat-form-field class="pt-4 w-100" floatLabel="always">
      <mat-label>Subject</mat-label>
      <input
        matInput
        placeholder="Enter subject title"
        ngModel
        [disabled]="true"
        [(ngModel)]="value.subjectTitle"
        (ngModelChange)="emitChangeEvent()"
        name="feedbackSubject"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="w-100" floatLabel="always">
      <mat-label> Feedback</mat-label>
      <textarea
        matInput
        #input
        required
        [disabled]="true"
        placeholder="Enter user feedback here"
        [(ngModel)]="value.feedback"
        (ngModelChange)="emitChangeEvent()"
        name="feedback"
        rows="5"
        maxlength="2500"
        ngModel
      ></textarea>
    </mat-form-field>
  </div>
</form>
