import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatChipListbox } from '@angular/material/chips';
import {
  ForeignVisitor,
  Organization,
  PageableCollection,
  ScreeningDisplayMap,
  FilterParams,
  ScreeningResult,
} from '@app/shared/models';
import {
  FvService,
  ScreeningService,
  AlertService,
} from '@app/shared/services';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { FVRescreenRoles } from '@app/shared/models/role-permissions';
import { ConfirmDialogComponent } from '@app/shared/components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfigValue } from '@app/shared/constants/shared.const';

@Component({
  selector: 'app-far-view-associated-fvs',
  templateUrl: './far-view-associated-fvs.component.html',
  styleUrls: ['./far-view-associated-fvs.component.scss'],
})
export class FarViewAssociatedFvsComponent implements OnInit, OnDestroy {
  @ViewChild('matChipListBox') matChipListBox: MatChipListbox;
  private ngUnsubscribe = new Subject<void>();
  baseStatusMap = ScreeningDisplayMap;
  FVRescreenRoles = FVRescreenRoles;
  ScreeningResult = ScreeningResult;
  greenResult: any;
  redResult: any;
  machineRedResult: any;

  @AppConfigValue('additionalDocumentsScreeningEligibility')
  additionalDocumentsScreeningEligibility: boolean = false;
  @Input() isDeletable: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() isRescreenable: boolean = false;
  @Input() fvs: PageableCollection<ForeignVisitor>;
  @Input() farId: string = '';
  @Input() owningOrg?: string = '';
  @Output() addFv = new EventEmitter<void>();
  @Output() addGroup = new EventEmitter<void>();
  @Output() removeFv = new EventEmitter<ForeignVisitor>();
  @Output() refresh = new EventEmitter<void>();
  isSameOrg: boolean = true;
  loading: boolean = false;
  filterParams = new FilterParams({
    sortBy: 'createdDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 10,
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private fvService: FvService,
    private screening: ScreeningService,
    private alert: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.filterParams.farId = this.farId;
    this.doSearch();
    let userOrg: Organization = this.auth.getOrganization() || {};
    if (this.owningOrg !== userOrg.id) this.isSameOrg = false;
    this.greenResult = ScreeningDisplayMap.get(ScreeningResult.Green);
    this.redResult = ScreeningDisplayMap.get(ScreeningResult.Red);
    this.machineRedResult = ScreeningDisplayMap.get(ScreeningResult.MachineRed);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  doSearch() {
    this.loading = true;
    this.fvService
      .find(this.filterParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.fvs = data;
        this.loading = false;
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.doSearch();
  }

  onAdd() {
    this.addFv.emit();
  }

  onAddGroup() {
    this.addGroup.emit();
  }

  addFilter(result: string) {
    if (this.filterParams.result === result) delete this.filterParams.result;
    else this.filterParams.result = result;
    this.filterParams.pageNum = 0;
    this.doSearch();
  }

  onBulkUpload() {
    this.doSearch();
  }

  onAddNew() {
    this.router.navigateByUrl('fv/far/' + this.farId);
  }

  onRemove(fv: ForeignVisitor) {
    this.removeFv.emit(fv);
  }

  openConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for all completed Foreign Nationals attached to this FAR.`,
      performAction: () => this.rescreen(),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  rescreen() {
    this.screening
      .rescreenFnByFar(this.farId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.alert.successAlert(
          res + ' Foreign National(s) have been submitted for rescreen'
        );
        this.doSearch();
      });
  }
}
