<div id="release-2-7-7" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.7 Release Notes</h3>
  <h6>Foreign National Bulk Upload</h6>
  <p>
    Bulk upload messaging should now be more descriptive of the number of
    duplicates found during an upload. Now, not only with duplicates within the
    spreadsheet itself be reported to the user on success, but it will also be
    combined with the number of duplicates found in the system existing already
    that are match via their passport information.
  </p>
  <h6>Location Bulk Upload</h6>
  <p>
    Location bulk upload replacement criteria have been increased from a 92%
    match to a 95% match. This should provide higher confidence on the address
    being replaced via the address normalization service. Additionally, the user
    entered address is now being stored in the database for tracking purposes.
  </p>
  <ng-container *appIfRoles="Role.sv_admin">
    <h6>Location Updates</h6>
    <p>
      Advanced Search – will now have options available to admin users to search
      for location that have been Geocoded or have not been Geocoded.
    </p>
    <p>
      Admin users can now also see the Latitude and Longitude of resolved
      locations from the Edit/View window on individual locations.
    </p>
    <h6>System Monitoring</h6>
    <p>
      Additional support has been added for attachment replication. Attachments
      that have not made it from low to high will now have messages show up
      within the System Monitoring section.
    </p>
  </ng-container>
</div>
