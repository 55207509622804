import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FilterParams, Organization, Role } from '@app/shared/models';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { AdminOnlyRoles } from '@app/shared/models/role-permissions';
import { OrgAdvancedSearch } from '@app/organizations/collection/org-advanced-search';
import { AppConfigService } from '@shared/services';
import { AuthService } from '@app/auth/auth.service';
import {
  OrgSortFieldLabels,
  OrgTableColumns,
  OrgTableName,
} from '../constants/org.const';

@Component({
  selector: 'app-organizations-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class OrganizationsCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<Organization> =
    {} as PageableCollection<Organization>;
  @Input() placeholder: string = 'Name | Short Name';
  @Input() workspaceView: boolean = false;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  orgActionButton: ActionButton = {
    label: 'New Organization',
    menuIcon: 'note_add',
    roles: AdminOnlyRoles,
    orgEdit: true,
    color: 'primary',
  };

  actionButtons: ActionButton[] = [];

  constructor(user: AuthService, config: AppConfigService) {
    const lowToggle = config.get('lowEnvFeatures', false);
    if (lowToggle && user.isAdmin) {
      this.actionButtons.push(this.orgActionButton);
    }
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly OrgAdvancedSearch = OrgAdvancedSearch;
  protected readonly OrgTableColumns = OrgTableColumns;
  protected readonly OrgSortFieldLabels = OrgSortFieldLabels;
  protected readonly OrgTableName = OrgTableName;
}
