<div class="grid-collection">
  <div class="grid-header">
    <app-search-toolbar
      viewDefKey="location"
      [filterFields]="LocationAdvancedSearch"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamsChange($event)"
      [actionButtons]="actionButtons"
      [columns]="LocationTableColumns"
      [columnLabels]="OrgLocationSortLabels"
      [tableName]="LocationTableName"
      [filterPlaceholder]="placeholder"
      hideViewToggle
      [loading]="loading"
      >Locations</app-search-toolbar
    >
  </div>
  <ng-container *ngIf="pageableCollection">
    <div [ngClass]="workspaceView ? 'workspace-main' : 'grid-main'">
      <div class="container">
        <ng-container *ngIf="pageableCollection.content.length; else noData">
          <app-location-collection-table
            [locations]="pageableCollection.content"
            [filterParams]="filterParams"
            (filterParamsChange)="onFilterParamsChange($event)"
          ></app-location-collection-table>
        </ng-container>
        <ng-template #noData>
          <app-no-result-found></app-no-result-found>
        </ng-template>
      </div>
    </div>
    <div class="grid-footer">
      <app-paginator
        [filterParams]="filterParams"
        (filterParamsChange)="onFilterParamsChange($event)"
        [pageIndex]="pageableCollection.number"
        [pageSize]="pageableCollection.size"
        [totalElements]="pageableCollection.totalElements"
      >
      </app-paginator>
    </div>
  </ng-container>
</div>
