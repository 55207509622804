import { ACTION_COLUMN } from '@app/shared/constants/standard-column.const';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { Role } from '@app/shared/models/role';
import { OrgViewRoles } from '@app/shared/models/role-permissions';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const ORGANIZATION_TABLE_NAME = 'organizationTable';

export interface OrganizationColumnDefs {
  name: CitadelColumnDef;
  shortName: CitadelColumnDef;
  parentOrg: CitadelColumnDef;
  subOrgs: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  lastModifiedDate: CitadelColumnDef;
  fnRecordCount: CitadelColumnDef;
  contactsCount: CitadelColumnDef;
  restrictedAccess: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const ORGANIZATION_COLUMNS: OrganizationColumnDefs = {
  name: {
    label: 'Name',
    hidden: false,
    isSortable: true,
    sortField: 'name',
    columnIndex: 0,
  },
  shortName: {
    label: 'Short Name',
    hidden: false,
    isSortable: true,
    sortField: 'shortName',
    columnIndex: 1,
  },
  parentOrg: {
    label: 'Parent Organization',
    hidden: false,
    isSortable: true,
    sortField: 'parentOrganization.name',
    columnIndex: 2,
  },
  subOrgs: {
    label: 'Sub Orgs',
    hidden: false,
    isSortable: true,
    sortField: 'subOrgCount',
    columnIndex: 3,
  },
  createdDate: {
    label: 'Created Date',
    hidden: false,
    isSortable: true,
    sortField: 'createdDate',
    columnIndex: 4,
  },
  lastModifiedDate: {
    label: 'Last Modified Date',
    hidden: false,
    isSortable: true,
    sortField: 'lastModifiedDate',
    columnIndex: 5,
  },
  fnRecordCount: {
    label: 'FN Records',
    hidden: false,
    isSortable: true,
    sortField: 'fnRecordCount',
    columnIndex: 6,
  },
  contactsCount: {
    label: 'Contacts',
    hidden: false,
    isSortable: true,
    sortField: 'contactsCount',
    columnIndex: 7,
  },
  restrictedAccess: {
    label: 'Restricted Access',
    hidden: false,
    isSortable: true,
    sortField: 'restrictedAccess',
    columnIndex: 8,
  },
  actions: {
    ...ACTION_COLUMN,
    requiredRoles: [Role.sv_admin],
    restrictedToNetwork: NetworkDomain.U,
  },
};

export type ORGANIZATION_COLUMN_KEYS = keyof typeof ORGANIZATION_COLUMNS;

export interface OrganizationPrefSaved extends BaseSavedDef {
  showColumn: { [key in ORGANIZATION_COLUMN_KEYS]: boolean };
}

export interface OrganizationTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in ORGANIZATION_COLUMN_KEYS]: CitadelColumnDef };
}

export const ORGANIZATION_TAB: OrganizationTabDef = {
  viewDefKey: 'organization',
  defaultMineOnly: false,
  viewType: 'card',
  columns: ORGANIZATION_COLUMNS,
  viewName: 'Organizations',
  pageLink: '/organizations',
  roles: OrgViewRoles,
  tableName: '',
  domains: [NetworkDomain.J, NetworkDomain.U],
};
