<div class="my-2">
  <mat-table
    class="header-primary-color"
    [dataSource]="data"
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container
      [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.systemUser.sortField"
    >
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.systemUser === false"
        mat-sort-header
        >{{ USER_ADMIN_APPROVAL_COLUMNS.systemUser.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.systemUser === false"
        *matCellDef="let row"
      >
        {{ row.systemUser | fullname: "surnameFirst" }}</mat-cell
      >
    </ng-container>
    <ng-container [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.email.sortField">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.email === false"
        mat-sort-header
        >{{ USER_ADMIN_APPROVAL_COLUMNS.email.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.email === false"
        *matCellDef="let row"
      >
        {{ row.systemUser.email }}</mat-cell
      >
    </ng-container>
    <ng-container
      [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.organization.sortField"
    >
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.organization === false"
        mat-sort-header
        >{{ USER_ADMIN_APPROVAL_COLUMNS.organization.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.organization === false"
        *matCellDef="let row"
      >
        {{ row.orgId.shortName }}</mat-cell
      >
    </ng-container>
    <ng-container
      [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.approver.sortField"
    >
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.approver === false"
        mat-sort-header
        >{{ USER_ADMIN_APPROVAL_COLUMNS.approver.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.approver === false"
        *matCellDef="let row"
        >{{ row.contact | fullname: "surnameFirst" | default }}</mat-cell
      >
    </ng-container>
    <ng-container
      [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.approvalDate.sortField"
    >
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.approvalDate === false"
        mat-sort-header
        >{{ USER_ADMIN_APPROVAL_COLUMNS.approvalDate.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userApproval?.showColumn?.approvalDate === false"
        *matCellDef="let row"
      >
        {{ row.approvalDate }}</mat-cell
      >
    </ng-container>
    <ng-container
      [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.expirationDate.sortField"
    >
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="
          savedPrefs?.userApproval?.showColumn?.expirationDate === false
        "
        mat-sort-header
        >{{ USER_ADMIN_APPROVAL_COLUMNS.expirationDate.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="
          savedPrefs?.userApproval?.showColumn?.expirationDate === false
        "
        *matCellDef="let row"
      >
        <span *ngIf="!row.endedDate">
          {{ row.expirationDate }}
          <span
            class="pl-1"
            [class.text-danger]="parseInt(row.expirationDate | daysUntil) &lt;= 30"
            >({{ row.expirationDate | daysUntil }} days)</span
          >
        </span>
        <span
          class="text-danger d-flex align-items-center"
          *ngIf="row.endedDate"
          >Inactive
          <mat-icon
            class="pl-1"
            color="primary"
            svgIcon="information"
            matTooltip="{{ row.endedComment }} ({{ row.endedDate }})"
          ></mat-icon
        ></span>
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="USER_ADMIN_APPROVAL_COLUMNS.actions.sortField"
    >
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="savedPrefs?.userApproval?.showColumn?.actions === false"
        >Actions</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let row"
        [hidden]="savedPrefs?.userApproval?.showColumn?.actions === false"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
        >
          <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu" data-cy="account-menu">
          <button
            *ngIf="!row.endedDate"
            mat-menu-item
            appAddApproval
            [user]="row.systemUser"
            (approvalSubmit)="extendApproval($event)"
            data-cy="approval-extend-button"
            id="approval-extend-button"
            aria-label="Extend Approval"
          >
            <mat-icon svgIcon="account-check-outline"></mat-icon> Extend
            Approval
          </button>
          <button
            mat-menu-item
            appAddApproval
            [approval]="row"
            id="approval-view-button"
            data-cy="approval-view-button"
            aria-label="View Approval"
          >
            <mat-icon svgIcon="account-eye-outline"></mat-icon> View Approval
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
</div>
