import { SortSelect } from '@app/shared/constants/shared.const';
import { without } from 'lodash';

/**
 * @deprecated
 */
export enum FarSortField {
  EVENT_TITLE = 'eventTitle',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  SPONSOR_SURNAME = 'sponsorSurname',
  VISITS = 'locationCount',
  ASSOC_FV_STATUS = 'status',
  PURPOSE_TYPE = 'purposeType',
  NETWORK = 'networkDomain',
  ORG = 'orgName',
}
/**
 * @deprecated
 */
export const FarSortFieldLabels: { [key in FarSortField]: string } = {
  [FarSortField.ORG]: 'Organization',
  [FarSortField.EVENT_TITLE]: 'Event Title',
  [FarSortField.CREATED_DATE]: 'Created Date',
  [FarSortField.LAST_MODIFIED_DATE]: 'Last Modified Date',
  [FarSortField.SPONSOR_SURNAME]: 'Sponsor Surname',
  [FarSortField.VISITS]: 'Visits',
  [FarSortField.ASSOC_FV_STATUS]: 'Foreign National State',
  [FarSortField.PURPOSE_TYPE]: 'Purpose Type',
  [FarSortField.NETWORK]: 'Network',
};

/**
 * @deprecated
 */
export const FARTableColumns: string[] = [
  FarSortField.ORG,
  FarSortField.EVENT_TITLE,
  FarSortField.PURPOSE_TYPE,
  FarSortField.CREATED_DATE,
  FarSortField.SPONSOR_SURNAME,
  FarSortField.VISITS,
  FarSortField.ASSOC_FV_STATUS,
  'additionalData',
  'actions',
];

export const FarSorts: SortSelect[] = without(
  Object.values(FarSortField),
  // Add non-sortable fields here
  FarSortField.ASSOC_FV_STATUS
).map((value) => {
  return { label: FarSortFieldLabels[value], value };
});

/**
 * @deprecated
 */
export const FARTableName = 'farTable';
