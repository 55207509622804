<div id="release-2-7-2" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.2 Release Notes</h3>
  <h6>Foreign National View</h6>
  <p>
    In the Foreign Access Request section that shows which FARs a FN has been
    attached to, the Add button has been updated to a menu with two options: 1)
    Add Available and 2) Add New. The Add New works as previously, the Add
    Available will now allow a User to add a Foreign National to multiple FARs
    that have already been created.
  </p>
  <h6>Metrics</h6>
  <p>
    This page has been updated to remove the pre-existing No Data Available
    views, and instead an empty graph will display that will show all dates of
    the date range selected with zeros if there is no data available for the
    range.
  </p>
  <p>
    Date Range Selector has been updated to add a maximum date range allowed:
  </p>
  <ul>
    <li>DAY – cannot exceed a range of 31 days.</li>
    <li>WEEK – cannot exceed a range of 180 days.</li>
    <li>MONTH – cannot exceed a range of 365 days.</li>
  </ul>
  <h6>Bulk Upload</h6>
  <p>
    Invalid Birth Dates should now be checked as part of the Bulk Upload process
    and rejected accordingly.
  </p>
</div>
