import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { ProfileComponent } from './profile/profile.component';
import { FarViewRoles } from '@shared/models/role-permissions';
import { Roles } from '@shared/models';

const routes: Routes = [
  {
    path: 'profile',
    title: 'Profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    data: {
      roles: Roles,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
