import { SortSelect } from '@app/shared/constants/shared.const';
import { without } from 'lodash';

/**
 * @deprecated
 */
export enum UserFeedbackSortField {
  FEEDBACK = 'feedback',
  SUBJECT_TITLE = 'subjectTitle',
  FEEDBACK_TYPE = 'feedbackType',
  NAME = 'user.surname',
  EMAIL = 'user.email',
  ORG = 'auditMetadata.owningOrganizationShortName',
  CREATED_DATE = 'createdDate',
}

export const UserFeedbackTableColumns: string[] = [
  UserFeedbackSortField.FEEDBACK,
  UserFeedbackSortField.FEEDBACK_TYPE,
  UserFeedbackSortField.SUBJECT_TITLE,
  UserFeedbackSortField.NAME,
  UserFeedbackSortField.EMAIL,
  UserFeedbackSortField.ORG,
  UserFeedbackSortField.CREATED_DATE,
  'actions',
];

export const UserFeedbackSortFieldLabels: {
  [key in UserFeedbackSortField]: string;
} = {
  [UserFeedbackSortField.FEEDBACK]: 'Feedback',
  [UserFeedbackSortField.FEEDBACK_TYPE]: 'Feedback Type',
  [UserFeedbackSortField.SUBJECT_TITLE]: 'Subject Title',
  [UserFeedbackSortField.NAME]: 'Name',
  [UserFeedbackSortField.EMAIL]: 'Email',
  [UserFeedbackSortField.ORG]: 'Organization',
  [UserFeedbackSortField.CREATED_DATE]: 'Created Date',
};

export const UserFeedbackSorts: SortSelect[] = without(
  Object.values(UserFeedbackSortField)
).map((value) => {
  return { label: UserFeedbackSortFieldLabels[value], value };
});

export const UserFeedbackTableName = 'userFeedbackTable';
