<div
  id="release-2-7-10"
  style="border-bottom: 1px solid lightgray"
  class="mb-2"
>
  <h3>2.7.10 Release Notes</h3>
  <h6>FAR Support for Foreign National Bulk Uploads</h6>
  <p>
    The ability to Bulk Upload FAR data from the Foreign National Bulk Upload
    Spreadsheet has been added. Required fields are all location fields and
    ‘Date of Visit From’. Any missing FAR data from these fields will cause the
    whole file to be rejected with a validation error, and no Foreign Nationals
    or FARs will be created until the missing FAR fields are fixed.
  </p>

  <p>
    Additionally, the spreadsheet has been updated to add two fields: Purpose
    Type and Event Title. Support will continue for the old spreadsheet,
    however, since Purpose Type and Event Title are fields required to create a
    FAR, any FAR without a Purpose Type specified will be defaulted to ‘In
    Person Meeting’. For ‘Event Title’, that will be autogenerated by the system
    using the ‘Location Name’ and the Submitting User’s Organization.
  </p>
  <p>
    If the ‘Date of Visit To’ field is missing, this will be auto filled with
    the data from the ‘Date of Visit From’ field.
  </p>
  <p>
    Visit POC requires a minimum of Visit POC Given Name, Surname, and Email.
    Any missing data here will cause the whole line to be rejected.
  </p>
  <p>
    If identical FAR data is entered in the FAR fields for different Foreign
    Nationals, only one FAR will be created and all Foreign Nationals that have
    the same FAR data will be grouped together. Note: the FAR data must be
    <b>EXACTLY</b> identical. Any difference, even minute ones, will cause a
    different FAR to be created.
  </p>
  <p>
    Foreign National data will continue to work as previous, with a minimum of
    at least Given Name and Surname, to create a new Foreign National within the
    system provided that no FAR data is entered within the spreadsheet. Foreign
    Nationals missing data will continue to be marked as Ineligible.
  </p>
  <h6>Group Management</h6>
  <p>
    Foreign National Baseball card and Additional Data Column will now display
    the total number of groups a FN is attached to. Additionally, the FN View
    will now display a list of Groups the Foreign National is attached to.
  </p>
  <p>Foreign Nationals can now be removed manually created groups.</p>
  <p>
    Hot links for Foreign National Count column in the Group Mgmt table has been
    updated with the additional of a link icon next to the number to make it
    more visible.
  </p>
  <p>
    The Group Management Default sort order has been changed from Name to
    Created Date newest to oldest.
  </p>
  <h6>Filter Updates</h6>
  <p>
    Foreign Nationals and Foreign Access Requests can now be filtered on whether
    they have attachments.
  </p>
  <p>
    Foreign Nationals can now be filtered by Groups from the Advanced Filter
    screen.
  </p>
  <p>
    Foreign Nationals Only Mine and Created By filters should now be working
    again as intended.
  </p>
  <h6>Foreign Nationals</h6>
  <p>
    Diplomatic Id section has been added to Foreign National Creation in the
    Travel step, and information added can be viewed in the FN Creation Review
    Step. The Id Number is a required field, with Issue and Expiration Dates
    being optional. Additionally, users can view and add a Diplomatic Id from
    the FN View. A Foreign National can only have one Diplomatic ID, and all
    Diplomatic Ids used will be considered issued from the United States.
  </p>
  <p>
    <b>Note:</b> there are no changes to the current Screening Eligibility rules
    that would allow a Diplomatic Id to substitute for a passport. That
    functionality will come at a later date once corresponding work for DACA Ids
    is completed.
  </p>
  <p>
    United Nations, NATO, and European Union have been added as options to be
    used in Passport Issuing Country. These special organizations can only be
    used Passport Issuing Country and will not appear in Birth Country,
    Residence Country, etc.
  </p>
  <p>
    Expediated Icon has been added back into the Foreign National table and
    Foreign National next to the Foreign National’s name. Additionally, users
    can once again set a Foreign National as expediated by editing the Foreign
    National’s Biographical Information.
  </p>
  <h6>Locations</h6>
  <p>
    Network column has been added along with the ability to sort and hide the
    column. To view this column, users will need update their Location User
    Preferences manually (clicking one of the Select All/Deselect All will force
    this), which will allow users to set their defaults for this view. Since
    this is a new column, the user preferences at the start will have this
    column set to not display.
  </p>
  <ng-container
    *appIfRoles="[Role.sv_org_admin, Role.sv_admin, Role.sv_help_desk]"
  >
    <h6>Org Admin Approvals</h6>
    <p>
      Several bug fixes have been deployed to fix the issues with filtering on
      Expiration dates, canceling out of windows and issues with submission
      being prevented after adding an approver.
    </p>
  </ng-container>
</div>
