import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { ConfirmDialogComponent } from '@app/shared/components';
import { AppConfigValue } from '@app/shared/constants/shared.const';
import {
  FilterParams,
  ForeignVisitor,
  Role,
  ScreeningDateFormat,
  ScreeningResult,
  User,
} from '@app/shared/models';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { AppConfigService, ScreeningService } from '@app/shared/services';
import {
  SCREENING_COLUMNS,
  SCREENING_TAB,
  ScreeningColumnDefs,
} from '@app/user-preference/config/screening.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-screening-table',
  templateUrl: './screening-table.component.html',
  styleUrls: ['./screening-table.component.scss'],
})
export class ScreeningTableComponent implements OnInit, OnDestroy {
  @Input() fvs: ForeignVisitor[];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @AppConfigValue('highEnvFeatures')
  private highEnvFeatures: boolean = false;
  ScreeningDateFormat = ScreeningDateFormat;
  private readonly configService: AppConfigService = inject(AppConfigService);
  private readonly authService: AuthService = inject(AuthService);
  private currentUser: User = this.authService.getUser() as User;

  Role = Role;

  ScreeningField = ScreeningField;
  ScreeningResult = ScreeningResult;
  private ngUnsubscribe = new Subject<void>();

  currentNetWork = this.highEnvFeatures ? NetworkDomain.J : NetworkDomain.U;
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  SCREENING_COLUMNS: ScreeningColumnDefs = SCREENING_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    SCREENING_TAB
  );

  constructor(
    private screeningService: ScreeningService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  onSortChange(sort: Sort | null) {
    if (sort !== null) {
      this.filterParams.sortBy = sort.active;
      this.filterParams.direction = sort.direction.toUpperCase();
    }
    this.filterParamsChange.emit(this.filterParams);
  }

  rescreen(fv: ForeignVisitor) {
    this.screeningService
      .rescreen({ id: fv.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for ${fv.givenName} ${fv.surname}.`,
      performAction: () => this.rescreen(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }
}

export enum ScreeningField {
  SURNAME = 'surname',
  VIP = 'vip',
  STATUS = 'latestScreening.status',
  NOTES = 'latestScreening.notes',
  NETWORK_DOMAIN = 'networkDomain',
  REQUESTED_DATE = 'latestScreening.requestedDate',
  COMPLETED_DATE = 'latestScreening.completedDate',
  MODIFIED_DATE = 'latestScreening.lastModifiedDate',
  RESULT = 'latestScreening.result',
  TOTAL_SCREENINGS = 'totalScreenings',
}

export const fvColumns: string[] = [
  ScreeningField.SURNAME,
  ScreeningField.REQUESTED_DATE,
  ScreeningField.MODIFIED_DATE,
  ScreeningField.COMPLETED_DATE,
  ScreeningField.STATUS,
  ScreeningField.RESULT,
  ScreeningField.NOTES,
  ScreeningField.NETWORK_DOMAIN,
  ScreeningField.TOTAL_SCREENINGS,
  'actions',
];

export const ScreeningFieldLabels: { [key: string]: string } = {
  [ScreeningField.SURNAME]: 'Foreign National',
  [ScreeningField.VIP]: 'Expedited',
  [ScreeningField.NOTES]: 'Notes Available',
  [ScreeningField.NETWORK_DOMAIN]: 'Network',
  [ScreeningField.STATUS]: 'Screening Status',
  [ScreeningField.REQUESTED_DATE]: 'Requested Date',
  [ScreeningField.COMPLETED_DATE]: 'Completed Date',
  [ScreeningField.MODIFIED_DATE]: 'Modified Date',
  [ScreeningField.RESULT]: 'Screening Result',
  [ScreeningField.TOTAL_SCREENINGS]: 'Total Screenings',
};
