import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationAdvancedSearch } from '@app/locations/collection/location-advanced-search';
import { FilterParams, OrgLocation, Role } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';

import { ActivatedRoute } from '@angular/router';
import {
  OrgLocationSortLabels,
  OrgLocationSorts,
} from '@app/organizations/constants/org.const';
import {
  LocationTableColumns,
  LocationTableName,
} from '../constants/locations.const';

const actionButtons: ActionButton[] = [
  {
    label: 'Bulk Upload',
    bulkUpload: 'Org',
    menuIcon: 'input',
    roles: [Role.sv_admin, Role.sv_org_admin, Role.sv_org_user],
    color: 'primary',
  },
  {
    label: 'New Location',
    menuIcon: 'note_add',
    locationEdit: true,
    roles: [Role.sv_admin, Role.sv_org_admin, Role.sv_org_user],
  },
];
@Component({
  selector: 'app-locations-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class LocationsCollectionComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<OrgLocation> =
    {} as PageableCollection<OrgLocation>;
  @Input() placeholder: string = 'Filter Criteria (e.g): Name, Address';
  @Input() workspaceView: boolean = false;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  actionButtons: ActionButton[] = actionButtons;
  OrgLocationSorts = OrgLocationSorts;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (JSON.stringify(this.filterParams) !== JSON.stringify(params)) {
        let newParams = new FilterParams({ ...this.filterParams, ...params });
        this.onFilterParamsChange(newParams);
      }
    });
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly LocationAdvancedSearch = LocationAdvancedSearch;
  protected readonly LocationTableColumns = LocationTableColumns;
  protected readonly OrgLocationSortLabels = OrgLocationSortLabels;
  protected readonly LocationTableName = LocationTableName;
}
