import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import {
  FilterParams,
  Organization,
  UserPreferenceBlob,
} from '@app/shared/models';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import {
  CrudService,
  OrganizationService,
  Repository,
} from '@app/shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-organizations',
  templateUrl: 'organizations.component.html',
  styleUrls: ['organizations.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useClass: OrganizationService },
  ],
})
export class OrganizationsComponent implements OnInit {
  loading: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
    direction: 'ASC',
  });
  organizations$: Observable<PageableCollection<Organization>> =
    this.repo.collection$.pipe(
      tap((_) => {
        this.loading = false;
      })
    );

  constructor(private repo: Repository<Organization>) {}
  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
