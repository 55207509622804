<div class="my-2">
  <mat-table
    class="header-primary-color"
    [dataSource]="fars"
    multiTemplateDataRows
    matSort
    [matSortActive]="
      filterParams.sort && filterParams.sort.active
        ? filterParams.sort.active
        : ''
    "
    [matSortDirection]="
      filterParams.sort && filterParams?.sort?.direction
        ? filterParams.sort.direction
        : ''
    "
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="FAR_COLUMNS.organization.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="savedPrefs?.far?.showColumn?.organization === false"
        mat-sort-header
      >
        {{ FAR_COLUMNS.organization.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.far?.showColumn?.organization === false"
        class="justify-content-center"
        *matCellDef="let fv"
        [matTooltip]="
          fv.foreignAccessRequestMetadata
            ? fv.foreignAccessRequestMetadata.owningOrganizationName ||
              fv.foreignAccessRequestMetadata.owningOrganizationShortName
            : 'N/A'
        "
        >{{
          fv.foreignAccessRequestMetadata
            ? (fv.foreignAccessRequestMetadata.owningOrganizationShortName ||
                fv.foreignAccessRequestMetadata.owningOrganizationName
              | shorten: 8:"...")
            : "N/A"
        }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.eventTitle.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.far?.showColumn?.eventTitle === false"
        >{{ FAR_COLUMNS.eventTitle.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-start"
        *matCellDef="let far"
        [hidden]="savedPrefs?.far?.showColumn?.eventTitle === false"
      >
        <div class="d-flex align-items-center">
          <div
            [matTooltip]="far?.eventTitle | titlecase | default"
            style="line-break: anywhere"
          >
            {{ far?.eventTitle | titlecase | default }}
          </div>
          <div>
            <mat-icon
              class="ml-1 read-only"
              *ngIf="!far.isEditable"
              color="primary"
              matTooltip="Read Only Record"
              svgIcon="pencil-off-outline"
            >
            </mat-icon>
            <mat-icon
              *ngIf="far.isCanceled"
              svgIcon="cancel"
              class="canceled-table ml-1 d-flex"
              matTooltip="FAR Canceled"
            ></mat-icon>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.purposeType.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.far?.showColumn?.purposeType === false"
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ FAR_COLUMNS.purposeType.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.far?.showColumn?.purposeType === false"
        class="justify-content-center"
        *matCellDef="let far"
        style="text-align: center"
      >
        {{ far?.purposeType | titlecase | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.createdDate.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.far?.showColumn?.createdDate === false"
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ FAR_COLUMNS.createdDate.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.far?.showColumn?.createdDate === false"
        class="justify-content-center"
        *matCellDef="let far"
        style="text-align: center"
      >
        {{ far?.createdDate | date: ScreeningDateFormat }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.visits.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.far?.showColumn?.visits === false"
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ FAR_COLUMNS.visits.label }}
      </mat-header-cell>
      <mat-cell
        [hidden]="savedPrefs?.far?.showColumn?.visits === false"
        class="justify-content-center"
        *matCellDef="let far"
        style="text-align: center"
      >
        <span *ngIf="far?.locationCount === 0">0</span>
        <button
          mat-button
          color="primary"
          matTooltip="Click to Toggle Display"
          *ngIf="far?.locationCount > 0"
          (click)="toggleRow(far)"
        >
          {{ far?.locationCount }}
          <mat-icon iconPositionEnd svgIcon="arrow-expand-vertical"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.sponsorSurname.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.far?.showColumn?.sponsorSurname === false"
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        >{{ FAR_COLUMNS.sponsorSurname.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let far"
        [hidden]="savedPrefs?.far?.showColumn?.sponsorSurname === false"
      >
        <ng-container
          *ngIf="
            !far?.sponsor?.givenName && !far?.sponsor?.surname;
            then noName;
            else hasName
          "
        >
        </ng-container>
        <ng-template #hasName>
          <button
            mat-button
            color="primary"
            matTooltip="Sponsor Details"
            #sponsorTrigger="matMenuTrigger"
            [matMenuTriggerFor]="sponsorMenu"
          >
            <div>
              <span *ngIf="far.sponsor?.surname"
                >{{ far.sponsor?.surname | shorten: 20:"..." }}, </span
              ><span *ngIf="far.sponsor?.givenName">
                {{ far.sponsor?.givenName | shorten: 20:"..." }}</span
              >
              <span *ngIf="!far.sponsor?.givenName && far.sponsor?.surname">
                FNU</span
              >
            </div>
          </button>
          <mat-menu #sponsorMenu>
            <div class="px-2">
              <dl>
                <dd class="mb-1 text-truncate d-flex align-items-center">
                  <mat-icon class="mr-1" matTooltip="Sponsor Phone Number"
                    >smartphone</mat-icon
                  ><ng-container *ngIf="far.sponsor as sponsor">
                    <span [matTooltip]="sponsor.phone || ''">
                      {{ sponsor.phone | default }}
                    </span>
                  </ng-container>
                </dd>
                <dd class="mb-1 text-truncate d-flex align-items-center">
                  <mat-icon class="mr-1" matTooltip="Sponsor Email Address"
                    >email</mat-icon
                  >
                  <ng-container *ngIf="far.sponsor as sponsor">
                    <span [matTooltip]="sponsor.email || ''">
                      {{ sponsor.email | shorten: 20:"..." | default }}
                    </span>
                  </ng-container>
                </dd>
              </dl>
            </div>
          </mat-menu>
        </ng-template>
        <ng-template #noName>N/A</ng-template>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.assocFVStatus.sortField">
      <mat-header-cell
        [hidden]="savedPrefs?.far?.showColumn?.assocFVStatus === false"
        class="justify-content-center"
        *matHeaderCellDef
        >{{ FAR_COLUMNS.assocFVStatus.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let far"
        [hidden]="savedPrefs?.far?.showColumn?.assocFVStatus === false"
      >
        <app-far-fvs-rollup
          [hideBackgroundColor]="true"
          [foreignAccessRequestScreeningCounts]="
            far.foreignAccessRequestScreeningCounts
          "
          [size]="'small'"
        ></app-far-fvs-rollup>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="additionalData">
      <mat-header-cell
        [hidden]="savedPrefs?.far?.showColumn?.additionalData === false"
        class="justify-content-center"
        *matHeaderCellDef
        >{{ FAR_COLUMNS.additionalData.label }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let far"
        [hidden]="savedPrefs?.far?.showColumn?.additionalData === false"
      >
        <app-far-metadata [far]="far" [tableDisplay]="true"></app-far-metadata>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="FAR_COLUMNS.actions.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="savedPrefs?.far?.showColumn?.actions === false"
        >Actions</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let far"
        [hidden]="savedPrefs?.far?.showColumn?.actions === false"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
          matTooltip="Click For Actions"
          matTooltipPosition="right"
          id="far-action-button"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="viewRow(far?.id)" id="view-button">
            <mat-icon>visibility</mat-icon>
            View
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let far" [attr.colspan]="dataColumns.length">
        <div
          class="contact-details"
          [@detailExpand]="far === expandedFar ? 'expanded' : 'collapsed'"
        >
          <app-itinerary-table
            [foreignAccessRequestLocations]="far.farLocations"
          ></app-itinerary-table>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="dataColumns; sticky: true"
    ></mat-header-row>
    <mat-row class="far" *matRowDef="let row; columns: dataColumns"></mat-row>
    <mat-row
      class="locations"
      *matRowDef="let row; columns: ['expandedDetail']"
    ></mat-row>
  </mat-table>
</div>
