<div id="release-2-7-3" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.7.3 Release Notes</h3>
  <h6>Foreign Access Request Creation</h6>
  <p>
    All FARs will now once again be required to provide an End Date. End Date
    will still not be allowed to be longer than one year apart from the Start
    Date. Additionally, a Start Date that is more than one year from the current
    date a FAR is being created will no longer be allowed.
  </p>
  <p>
    Purpose Type selections order has been updated. Facility Access has been
    moved to the bottom of the selection list and has had a new help icon with
    additional tooltip text explaining the intended purpose added.
  </p>
  <h6>Foreign National</h6>
  <p>
    Error Status has been renamed to Review Pending. This will now show up with
    a Dark Blue color and new Icon.
  </p>
  <p>
    When creating new Foreign Nationals, blank empty addresses should no longer
    cause an empty address object to be created in the database for the Foreign
    National’s Contact Information (there will be no noticeable change to the
    user for this functionality).
  </p>
  <h6>Locations</h6>
  <p>
    Location will now require a complete address should any address information
    be added in (Location will still support only a completely empty address in
    the case of something like a Virtual Meeting should someone want to put a
    location in for that).
  </p>
  <p>
    Address Line 2 will remain not required; however, data entered the field
    will make the rest of the address fields required as intended.
  </p>
  <h6>Location Bulk Upload</h6>
  <p>
    As an Admin, when Bulk Uploading locations, if an admin were to select an
    organization that is not their own, the Owning Organization should now also
    reflect the organization selected from the dropdown and not just the
    organization attached to the location (viewable under Last Modifying User
    Details).
  </p>
</div>
