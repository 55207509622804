<div class="my-2">
  <mat-table
    [dataSource]="data"
    class="header-primary-color"
    aria-label="'feedback-table"
    #feedbackTable
    multiTemplateDataRows
    matSort
    [matSortActive]="
      filterParams.sort && filterParams.sort.active
        ? filterParams.sort.active
        : ''
    "
    [matSortDirection]="
      filterParams.sort && filterParams.sort.direction
        ? filterParams.sort.direction
        : ''
    "
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.feedback.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.userFeedback?.showColumn?.feedback === false"
      >
        {{ USER_FEEDBACK_COLUMNS.feedback.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let feedback"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.feedback === false"
      >
        <ng-container
          *ngIf="feedback.feedback; then hasFeedback; else noFeedback"
        ></ng-container>
        <ng-template #hasFeedback>
          <button
            mat-icon-button
            #noteTrigger="matMenuTrigger"
            [matMenuTriggerFor]="feedbackMenu"
          >
            <mat-icon color="primary" [matTooltip]="'View Feedback'"
              >feedback</mat-icon
            >
          </button>
          <mat-menu #feedbackMenu>
            <div class="container">
              <div class="row">
                <div class="col">
                  <span>
                    {{ feedback.feedback }}
                  </span>
                </div>
              </div>
            </div>
          </mat-menu>
        </ng-template>
        <ng-template #noFeedback
          ><span [matTooltip]="'No Feedback Available'"> N/A </span>
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.feedbackType.sortField">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center white-arrow"
        style="text-align: center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.feedbackType === false"
        mat-sort-header
      >
        {{ USER_FEEDBACK_COLUMNS.feedbackType.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        style="text-align: center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.feedbackType === false"
        *matCellDef="let feedback"
      >
        <ng-container>
          {{
            feedback.feedbackType ? (feedback.feedbackType | titlecase) : "N/A"
          }}
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.subjectTitle.sortField">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.subjectTitle === false"
        mat-sort-header
      >
        {{ USER_FEEDBACK_COLUMNS.subjectTitle.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.subjectTitle === false"
        *matCellDef="let feedback"
      >
        {{ feedback.subjectTitle ? feedback.subjectTitle : "N/A" }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.name.sortField">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.name === false"
        mat-sort-header
      >
        {{ USER_FEEDBACK_COLUMNS.name.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.name === false"
        *matCellDef="let feedback"
      >
        {{ feedback.auditMetadata.givenName }}
        {{ feedback.auditMetadata.surname }}
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.email.sortField">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.email === false"
        mat-sort-header
      >
        {{ USER_FEEDBACK_COLUMNS.email.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let row"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.email === false"
      >
        {{ row.auditMetadata.email }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.organization.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.userFeedback?.showColumn?.organization === false"
      >
        {{ USER_FEEDBACK_COLUMNS.organization.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let feedback"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.organization === false"
      >
        {{ feedback.auditMetadata.owningOrganizationShort | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.createdDate.sortField">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="savedPrefs?.userFeedback?.showColumn?.createdDate === false"
      >
        {{ USER_FEEDBACK_COLUMNS.createdDate.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        style="text-align: center"
        *matCellDef="let feedback"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.createdDate === false"
      >
        {{ feedback.createdDate | date: "MMM d, y, HH:mm:ss" | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="USER_FEEDBACK_COLUMNS.actions.sortField">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="savedPrefs?.userFeedback?.showColumn?.actions === false"
      >
        {{ USER_FEEDBACK_COLUMNS.actions.label }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let feedback"
        [hidden]="savedPrefs?.userFeedback?.showColumn?.actions === false"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
          matTooltipPosition="right"
          id="feedback-action-button"
        >
          <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <ng-container>
            <button
              mat-menu-item
              [appUserFeedbackView]="feedback.userFeedbackId"
              (appUserFeedbackViewed)="onEdited(feedback)"
              id="view-button"
            >
              <mat-icon>visibility</mat-icon>
              View
            </button>
          </ng-container>
          <button
            mat-menu-item
            (click)="deleteFeedback(feedback.userFeedbackId)"
            id="delete-button"
          >
            <mat-icon>delete</mat-icon> Delete
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="dataColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
</div>
