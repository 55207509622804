import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Role, Roles, User } from '@app/shared/models';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  BaseControlComponent,
  RolesSelectControlComponent,
} from '@app/shared/controls';
import { AbstractControl, NgForm, ValidationErrors } from '@angular/forms';
import {
  RoleAssignableBy,
  UserCrossOrgEditRoles,
} from '@shared/models/role-permissions';
import { AuthService } from '@app/auth/auth.service';
import { isEqual } from 'lodash';
import { AppConfigValue } from '@app/shared/constants/shared.const';
@Component({
  selector: 'app-user-form',
  templateUrl: './accounts-user-form.component.html',
  styleUrls: ['./accounts-user-form.component.scss'],
})
export class AccountsUserFormComponent
  extends BaseControlComponent<User>
  implements OnInit
{
  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;
  @ViewChild('userForm') userForm: NgForm;
  @ViewChild('roleChipList') roleChipList: RolesSelectControlComponent;
  @Input() value: User;
  @Input() readOnly: boolean = false;
  @Input() currentUser: User | null;
  @AppConfigValue('roles')
  roles: Role[] = Roles;
  UserCrossOrgEditRoles = UserCrossOrgEditRoles;
  appearance: MatFormFieldAppearance = 'outline';
  Role = Role;

  hideRoleSelect = false;
  assignableRoles: Role[];

  constructor(private injector: Injector, private authService: AuthService) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.value.id && this.currentUser && this.currentUser.organization) {
      this.value.organization = this.currentUser.organization;
    }

    this.assignableRoles = (Object.keys(RoleAssignableBy) as Role[]).filter(
      (r) => this.authService.hasAnyRole(...RoleAssignableBy[r])
    );

    //  If new user and only 1 assignable role, auto add it
    if (!this.value.id && this.assignableRoles.length === 1) {
      this.value.roles = [...this.assignableRoles];
    }

    //  If only 1 assignable role, and user has them, then hide role select
    if (
      this.assignableRoles.length === 1 &&
      isEqual(this.value.roles, this.assignableRoles)
    ) {
      this.hideRoleSelect = true;
    }
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return _control.value ? null : { roles: true };
  }
}
