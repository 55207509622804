import { NetworkDomain } from '@app/shared/models/network-domain';
import { SystemAlertsEditRoles } from '@app/shared/models/role-permissions';
import { ACTION_COLUMN } from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const SYSTEM_ALERTS_TABLE_NAME = 'alert';
export interface SystemAlertsColumnDefs {
  status: CitadelColumnDef;
  message: CitadelColumnDef;
  starts: CitadelColumnDef;
  ends: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const SYSTEM_ALERTS_COLUMNS: SystemAlertsColumnDefs = {
  status: {
    label: 'Status',
    hidden: false,
    isSortable: false,
    sortField: '',
    columnIndex: 0,
  },
  message: {
    label: 'Message',
    hidden: false,
    isSortable: false,
    sortField: '',
    columnIndex: 1,
  },
  starts: {
    label: 'Starts',
    hidden: false,
    isSortable: false,
    sortField: '',
    columnIndex: 2,
  },
  ends: {
    label: 'Ends',
    hidden: false,
    isSortable: false,
    sortField: '',
    columnIndex: 3,
  },
  actions: ACTION_COLUMN,
};

export type SYSTEM_ALERT_COLUMN_KEYS = keyof typeof SYSTEM_ALERTS_COLUMNS;

export interface SystemAlertPrefSaved extends BaseSavedDef {
  showColumn: { [key in SYSTEM_ALERT_COLUMN_KEYS]: true };
}

export interface SystemAlertTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in SYSTEM_ALERT_COLUMN_KEYS]: CitadelColumnDef };
}

export const SYSTEM_ALERT_TAB: SystemAlertTabDef = {
  viewDefKey: 'systemAlert',
  defaultMineOnly: false,
  viewType: 'table',
  columns: SYSTEM_ALERTS_COLUMNS,
  viewName: 'System Alerts',
  tableName: SYSTEM_ALERTS_TABLE_NAME,
  pageLink: '/systemalerts',
  roles: SystemAlertsEditRoles,
  domains: [NetworkDomain.J, NetworkDomain.U],
};
