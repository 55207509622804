import { Component, Input } from '@angular/core';
import { FAR } from '@shared/models';

@Component({
  selector: 'app-far-header',
  templateUrl: './far-header.component.html',
  styleUrls: ['./far-header.component.scss'],
})
export class FarHeaderComponent {
  @Input() far: FAR;
  @Input() listView: boolean = false;

  getVisitIcon(): string {
    if (this.far && this.far.locationCount) {
      if (this.far.locationCount < 10) {
        return 'numeric-' + this.far.locationCount + '-box';
      } else if (this.far.locationCount > 9) {
        return 'numeric-9-plus-box';
      } else return 'numeric-0-box';
    } else return 'numeric-0-box';
  }
}
