import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role, User } from '@app/shared/models';
import { UserMetrics } from '@app/user-profile/constants/user.const';
import { environment } from '@environments/environment';
import { UNFILTERED_ROLES } from '@shared/helpers';
import { AuthEvent } from '@shared/models/auth-event.model';
import { SessionStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Crud, CrudChangeType, CrudService } from './crud.service';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/systemUsers`,
  hasIdPathUpdate: true,
  entity: 'User Account',
})
export class UserService extends CrudService<User> {
  @SessionStorage()
  filteredRoles: Role[];

  constructor(http: HttpClient) {
    super(http);
  }

  manageUser(id: string, status: string): Observable<User> {
    let params: HttpParams = new HttpParams();
    params = params.set('status', status);

    return this.http
      .put<User>(`${this.apiUrl}/${id}/manage`, null, {
        params,
        context: this.context,
      })
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: CrudChangeType.update,
            model: m,
            modelId: m.id,
          })
        )
      );
  }

  public getCurrent(unfilteredRoles = false): Observable<User> {
    const context = new HttpContext().set(UNFILTERED_ROLES, unfilteredRoles);
    return this.http.get<User>(`${environment.apiUrl}/systemUsers/current`, {
      context,
    });
  }

  public getAuthEvents(): Observable<AuthEvent[]> {
    return this.http.get<AuthEvent[]>(
      `${environment.apiUrl}/systemUsers/auth_events`
    );
  }

  public getUsersAuthEvents(id: string): Observable<AuthEvent[]> {
    return this.http.get<AuthEvent[]>(
      `${environment.apiUrl}/systemUsers/${id}/auth_events`
    );
  }

  public getUserMetrics(): Observable<UserMetrics> {
    return this.http.get<UserMetrics>(
      `${environment.apiUrl}/systemUsers/metrics`
    );
  }

  public updateProfile(model: User): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/current`, model).pipe(
      tap((m) =>
        this.changeEvent$.emit({
          type: CrudChangeType.update,
          model: m,
          modelId: model.id,
        })
      )
    );
  }

  public resendPassword(id: string, user: User): Observable<User> {
    return this.http.put<User>(
      `${environment.apiUrl}/systemUsers/${id}/reset_user`,
      user
    );
  }

  public resetPassword(id: string, permanent: boolean): Observable<any> {
    return this.http.put<string>(
      `${environment.apiUrl}/systemUsers/${id}/reset_password?permanent=${permanent}`,
      null
    );
  }
}
