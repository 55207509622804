import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { TabDetails, User, UserPreference } from '@app/shared/models';
import {
  AdminViewRoles,
  FVViewRoles,
  FarViewRoles,
  GroupViewRoles,
  LocViewRoles,
  OrgViewRoles,
  ScreeningRoles,
} from '@app/shared/models/role-permissions';
import { AlertService } from '@app/shared/services';
import { ViewDetails } from '@app/shared/services/view.service';

export const ViewNamesConstants = [
  'Home',
  'Foreign Nationals (FN)',
  'Foreign Access Request (FAR)',
  'Screening Review',
  'Locations',
  'Group Management',
  'Metrics',
  'System Monitoring',
  'Organizations',
  'System Alerts',
  'User Administration',
];

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { DeactivateDialogComponent } from '@app/shared/deactivation/components/deactivate-dialog/deactivate-dialog.component';
import { CanDeactivateWithUnsavedChanges } from '@app/shared/deactivation/has-unsaved-changes';
import { UserPrefViewDefinitions } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceService } from '@app/user-preference/service/user-preference.service';
import { isEqual } from 'lodash';
import { catchError, firstValueFrom, of } from 'rxjs';

export const AdditionalTableColumns = ['Additional Data', 'Actions'];

@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss'],
})
export class UserPreferenceComponent
  implements OnInit, CanDeactivateWithUnsavedChanges
{
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  tabTitle: string = ViewNamesConstants.at(0)!;
  tabIndex: number = 0;

  ScreeningRoles = ScreeningRoles;
  LocViewRoles = LocViewRoles;
  OrgViewRoles = OrgViewRoles;
  GroupViewRoles = GroupViewRoles;
  AdminViewRoles = AdminViewRoles;
  FarViewRoles = FarViewRoles;
  FVViewRoles = FVViewRoles;

  viewDetails: ViewDetails | undefined;
  checkedColumns: string[] | undefined;
  userId: string;
  isUpdated: boolean = false;
  displayViews: any[] = [];
  displayLabels: any;
  tableName: string;
  tableColumns: any;
  pageLink: string;
  preferenceView: string;
  userPreference: UserPreference | undefined;
  tabDetails: Array<TabDetails> = [];
  isCancel = false;
  private originalFormValue: UserPreference;
  protected user: User;

  userPreferenceForm!: FormGroup;
  newArray: FormArray;
  prefview = '';
  private readonly deactivateDialog: MatDialog = inject(MatDialog);
  viewDefs: UserPrefViewDefinitions;

  constructor(
    private authService: AuthService,
    private userPreferenceService: UserPreferenceService,
    private alert: AlertService,
    private route: ActivatedRoute
  ) {}
  @HostListener('window:beforeunload', ['$event'])
  async canDeactivateWithUnsavedChanges(e?: Event): Promise<boolean> {
    /**
     * @TODO prefviews is being reset somewhere.
     * I don't think we care about this one, so set it to the new value
     *
     */
    const newValue: unknown = this.userPreferenceForm.value as UserPreference;
    const oldValue: unknown = {
      ...this.originalFormValue,
    };

    if (isEqual(oldValue, newValue)) return true;

    /**
     * If this is triggered by a browser event (e.g., beforeunload),
     * stop the default event (which allows the unload to continue).
     * Beforeunload has it's own dialog which cannot be overwritten
     * consistently.
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
     */
    if (e) {
      e?.preventDefault();
      return firstValueFrom(of(false));
    }

    const dialogRef: MatDialogRef<DeactivateDialogComponent> =
      this.deactivateDialog.open(DeactivateDialogComponent);

    return await firstValueFrom(dialogRef.afterClosed());
  }

  ngOnInit(): void {
    // Get the prefetched data
    this.userPreference = this.route.snapshot.data
      .userPreference as UserPreference;
    this.viewDefs = this.route.snapshot.data
      .viewDefs as UserPrefViewDefinitions;
    this.userPreferenceForm = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;

    this.user = this.authService.getUser() as User;
  }

  onSubmit() {
    return;
  }

  saveChanges() {
    const id: string | undefined = this.userPreference?.id;

    if (!id) {
      return this.userPreferenceService
        .postPreference(this.userPreferenceForm.value)
        .pipe(
          catchError((err: Error) => {
            this.isUpdated = false;
            throw err;
          })
        )
        .subscribe((result) => {
          this.userPreference = result;
          this.originalFormValue = this.userPreferenceForm.value;
          this.alert.successAlert('User preferences created successfully');
          this.userPreferenceService.navigateToDefaultPage();
        });
    }

    return this.userPreferenceService
      .updatePreference(this.userPreferenceForm.value)
      .pipe(
        catchError((err: Error) => {
          this.isUpdated = false;
          throw err;
        })
      )
      .subscribe((result) => {
        this.userPreference = result;
        this.originalFormValue = this.userPreferenceForm.value;
        this.alert.successAlert('User preferences updated successfully');
        this.userPreferenceService.navigateToDefaultPage();
      });
  }

  cancel() {
    /**
     * The canDeActivate guard will handle the logic already about whether to allow moving away from the form
     */
    this.userPreferenceService.navigateToDefaultPage();
  }

  get fvForm(): FormGroup {
    return this.userPreferenceForm.get('fv') as FormGroup;
  }

  get farForm(): FormGroup {
    return this.userPreferenceForm.get('far') as FormGroup;
  }

  get screeningForm(): FormGroup {
    return this.userPreferenceForm.get('screen') as FormGroup;
  }

  get locationForm(): FormGroup {
    return this.userPreferenceForm.get('location') as FormGroup;
  }

  get groupForm(): FormGroup {
    return this.userPreferenceForm.get('group') as FormGroup;
  }

  get userAdministrationForm(): FormGroup {
    return this.userPreferenceForm.get('userAdministration') as FormGroup;
  }

  get organizationForm(): FormGroup {
    return this.userPreferenceForm.get('organization') as FormGroup;
  }
}
