import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AsyncJob,
  FilterParams,
  PageableCollection,
  Role,
  User,
} from '@app/shared/models';
import { AuthService } from '@app/auth/auth.service';
import { ActivatedRoute, IsActiveMatchOptions, Router } from '@angular/router';
import {
  AdminOnlyRoles,
  AdminViewRoles,
  FarViewRoles,
  FVViewRoles,
  GroupViewRoles,
  LocViewRoles,
  MetricsViewRoles,
  MonitoringViewRoles,
  ScreeningRoles,
  SystemAlertsEditRoles,
  UserApproverRoles,
} from '@shared/models/role-permissions';
import { AppConfigService } from '@shared/services/app-config.services';
import {
  AlertService,
  AsyncBulkUploadObservable,
  AsyncService,
  UserService,
} from '@shared/services';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-application-toolbar',
  templateUrl: './application-toolbar.component.html',
  styleUrls: ['./application-toolbar.component.scss'],
})
export class ApplicationToolbarComponent implements OnInit, OnDestroy {
  FVViewRoles = FVViewRoles;
  FarViewRoles = FarViewRoles;
  LocViewRoles = LocViewRoles;
  ScreeningRoles = ScreeningRoles;
  AdminViewRoles = AdminViewRoles;
  MetricsViewRoles = MetricsViewRoles;
  SystemAlertsEditRoles = SystemAlertsEditRoles;
  UserApproverRoles = UserApproverRoles;
  GroupViewRoles = GroupViewRoles;
  AdminOnlyRoles = AdminOnlyRoles;
  homeLinkOptions: IsActiveMatchOptions = {
    fragment: 'exact',
    matrixParams: 'exact',
    paths: 'exact',
    queryParams: 'ignored',
  };

  @Input() systemUser: User;

  classification?: string;
  showBanner: boolean;
  disableUserAdmin?: boolean;
  lowEnvFeatures?: boolean;
  helpUrl?: string;
  rolesFiltered = false;
  asyncJobs: PageableCollection<AsyncJob>;
  asyncPollActive: boolean = false;
  inProgressCount: number = 0;
  defaultFilterParams = new FilterParams({
    sortBy: 'createdDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 5,
    read: false,
  });
  loading: boolean = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public auth: AuthService,
    private config: AppConfigService,
    public userService: UserService,
    private asyncService: AsyncService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private asyncJobPoll: AsyncBulkUploadObservable,
    private router: Router
  ) {
    this.rolesFiltered = !!this.userService.filteredRoles?.length;
    this.disableUserAdmin = config.get('disableUserAdmin');
    this.classification = config.get('classification');
    this.helpUrl = this.config.get('helpLink');
    this.lowEnvFeatures = this.config.get('lowEnvFeatures', false);
    this.showBanner =
      (this.classification?.length &&
        this.classification.toUpperCase() !== 'UNCLASSIFIED') ||
      false;
  }

  Role = Role;

  ngOnInit(): void {
    this.asyncService
      .find(
        new FilterParams({
          statuses: ['In Progress'],
          read: false,
          pageSize: 1,
        })
      )
      .pipe(take(1))
      .subscribe((data) => {
        this.asyncPollActive = true;
        this.inProgressCount = data.totalElements!;
        this.asyncPoll();
      });

    this.asyncJobPoll.AsyncBulkUploadObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      if (data) {
        this.inProgressCount++;
        if (!this.asyncPollActive) this.asyncPoll();
        else this.refresh();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  asyncPoll() {
    this.loading = true;
    if (this.auth.isAuthenticated()) {
      let sources: Observable<PageableCollection<AsyncJob>>[] = [
        this.asyncService.find(this.defaultFilterParams),
        this.asyncService.find(
          new FilterParams({
            statuses: ['In Progress'],
            read: false,
            pageSize: 1,
          })
        ),
      ];

      forkJoin(sources)
        .pipe(
          take(1),
          map(([data, inProgressData]) => {
            this.asyncJobs = data;
            let self = this;
            window.setTimeout(function () {
              self.loading = false;
            }, 500);
            if (inProgressData.totalElements! < this.inProgressCount) {
              this.alertService.infoAlert(
                'A Bulk Upload Job has been completed. View Job status from notifications menu. Successfully completed job results can be viewed via notifications menu option or refreshing the corresponding job type page.',
                {
                  duration: 10000,
                }
              );
              this.inProgressCount = inProgressData.totalElements!;
            }
            if (inProgressData.totalElements) {
              this.asyncPollActive = true;
              setTimeout(function () {
                self.asyncPoll();
              }, 15000);
            } else this.asyncPollActive = false;
          }),
          catchError((err) => {
            //Continue Polling on Error
            let self = this;
            setTimeout(function () {
              self.asyncPoll();
            }, 15000);
            return '';
          })
        )
        .subscribe();
    }
  }

  navigateToGroup(item: AsyncJob) {
    let filter: any = {};
    let destination = '';
    let queryField = '';
    let queryParam = '';

    if (item.type === 'Foreign Visitor Bulk Upload') {
      if (this.router.url === '/') destination = 'fvs';
      else destination = '../fvs';
      queryField = 'foreignVisitorGroupId';
      queryParam = item.fvGroupId!;
    } else {
      if (this.router.url === '/') destination = 'locations';
      else destination = '../locations';
      queryField = 'asyncJobIds';
      queryParam = item.id!;
    }

    if (queryField) {
      filter[queryField] = [queryParam];
      this.router.navigate([destination], {
        relativeTo: this.route,
        queryParams: filter,
      });
    }
  }

  markAllAsRead() {
    let jobsIds: string[] = [];
    for (let item of this.asyncJobs.content)
      if (item.status !== 'In Progress') jobsIds.push(item.id!);
    this.asyncService
      .markAsRead(jobsIds)
      .pipe(take(1))
      .subscribe((res) => {
        this.defaultFilterParams.pageNum = 0;
        this.refresh();
      });
  }

  refresh(isTrue?: boolean) {
    this.loading = true;
    //Reset to Page 1 if all items cleared
    if (isTrue && this.asyncJobs.numberOfElements === 1) {
      this.defaultFilterParams.pageNum = 0;
    }

    this.asyncService
      .find(this.defaultFilterParams)
      .pipe(take(1))
      .subscribe((data) => {
        this.asyncJobs = data;
        let self = this;
        window.setTimeout(function () {
          self.loading = false;
        }, 500);
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.defaultFilterParams = filterParams;
    this.refresh();
  }

  logout() {
    this.auth.logout();
  }

  protected readonly MonitoringViewRoles = MonitoringViewRoles;
}
