<div id="release-2-6-2" style="border-bottom: 1px solid lightgray" class="mb-2">
  <h3>2.6.2 Release Notes</h3>
  <h6>Foreign Access Request View</h6>
  <ul>
    <li>
      The Add Menu in the Foreign Nationals section has been updated to add Bulk
      Upload as an option. Foreign Nationals can now be directed uploaded to a
      FAR.
    </li>
    <li>
      Foreign Nationals Section has been switched over to use the Foreign
      Nationals filter service. Quick Filters have been adjusted and the Machine
      Red, Red, and Green filters will be available as options regardless of
      what status or results are available. Additional filters may be added at a
      future date.
    </li>
    <li>
      A Paginator will display in the FN section if there are more than 5
      Foreign Nationals attached to the FAR. Page Size is defaulted to 10
      Foreign Nationals per page.
    </li>
  </ul>
  <h6>Foreign Access Request Card and Table View</h6>
  <p>
    FARs in this view will no longer return a complete list of Foreign Nationals
    attached to the FAR, instead it will only return the counts based on the FAR
    card statuses listed (Machine Red, Red, Green, and Other).
  </p>
  <p>
    <b>Note on FAR Changes:</b> : the removal of the complete list of Foreign
    Nationals on FARs was done due to performance reasons. Once the ability to
    Bulk Upload was added, when multiple FARs started to have hundreds to
    thousands of FNs attached to them, this impacted the UI’s ability to
    retrieve data in a timely manner and ground the application’s performance to
    a halt (and in some cases, outright crashed the application).
  </p>
  <p>
    There should not be any noticeable change in functionality in the FAR Card
    and Table Views; however, the changes to the Foreign National section of the
    FAR View were done for this reason.
  </p>
  <h6>Foreign Nationals View</h6>
  <ul>
    <li>
      <b>FN Employment</b> - A user with only Org User role should be able to
      update an Employment record with an Address. Previously, if an Org User
      tried to update an existing Employment record that contained an address,
      they would get a forbidden error. This only affected users with Org User
      role.
    </li>
    <li>
      A Foreign National with commas in the comments section should now be able
      to be deleted as USP as intended.
    </li>
  </ul>
  <h6>Locations</h6>
  <ul>
    <li>
      Bulk Upload - Locations with invalid Postal Codes longer than allowed
      should now get a more user-friendly error message explaining the issue.
    </li>
    <li>Logging has been added to track changes to locations.</li>
  </ul>
  <h6>EVC Screening and Cross Domain Replication</h6>
  <ul>
    <li>
      Adjustments have been made to attempt to address the issue where EVC
      responds quicker than Cross Domain transfers a In Process state back down
      to the low side, causing low side records to get stuck in an In Process
      state while the corresponding high side record has a result.
    </li>
    <li>
      Any tag metadata on Attachments to Foreign Nationals will have that
      metadata stripped to prevent future issues with McXD.
    </li>
    <li>
      Foreign National Screenings should be sent to EVC even if for some reason
      the profile photo attachment associated with that record cannot be
      retrieved.
    </li>
    <li>
      FAR Event Title, and Purpose Type will be added to the Screening Request
      Comments for EVC. Description will be added to the Travel Type comments.
    </li>
  </ul>
</div>
