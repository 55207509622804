import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  CitadelValidationError,
  CitadelValidationErrors,
} from '@app/shared/models/citadel-validation-errors.model';
import { AppConfigService } from '@app/shared/services';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { FarLocation } from '@shared/models';
import { Subject, takeUntil } from 'rxjs';
import { ItineraryControlObservable } from '../../services/itinerary-control-observable.service';
/**
 * @todo Move to FAR code
 */

@Component({
  selector: 'app-itinerary-control',
  templateUrl: './itinerary-control.component.html',
  styleUrls: ['./itinerary-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ItineraryControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ItineraryControlComponent,
      multi: true,
    },
  ],
})
export class ItineraryControlComponent
  extends BaseControlComponent<FarLocation>
  implements Validator, OnInit, OnDestroy
{
  @ViewChild('itineraryForm')
  itineraryForm: NgForm;
  @Input() locationRequired: boolean = true;
  @Input() owningFarId: string = '';
  private ngUnsubscribe = new Subject<void>();
  errorMessage: string = '';
  showFarChangeWarning: boolean = false;
  farScreeningEligibility: boolean = false;
  descriptionExisted: boolean = false;

  constructor(
    private itineraryControl: ItineraryControlObservable,
    private config: AppConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.itineraryControl.ItineraryControlObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      this.locationRequired = event;
    });
    this.descriptionExisted = false;
  }

  showWarning() {
    if (this.value.id && this.farScreeningEligibility)
      this.showFarChangeWarning = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * returns the opposite of isvalid
   */
  get valid(): boolean {
    const errors: CitadelValidationErrors | null = this.itineraryForm?.errors;
    if (!errors) return true;
    return !this.invalid;
  }

  /**
   * Overrides the default behavior to only prevent submissions on blocking errors
   * @see CitadelValidationError
   */
  get invalid(): boolean {
    const errors: CitadelValidationErrors | null = this.itineraryForm?.errors;
    if (!errors) return false;
    const hasBlockingErrors: boolean =
      Object.keys(errors).filter((key: string) => {
        const error: CitadelValidationError = errors[key];
        return error.blocking;
      }).length > 0;
    return hasBlockingErrors;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return this.valid ? null : control.errors;
  }
}
