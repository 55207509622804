<div
  class="far-card__fvs-rollup p-2 component"
  [ngClass]="hideBackgroundColor ? 'background-white' : ''"
>
  <div class="row justify-content-between mx-3">
    <app-screening-fab-button
      class="{{ reviewedRedRollup.status }} mr-2"
      [item]="reviewedRedRollup"
      [size]="size"
    >
    </app-screening-fab-button>
    <app-screening-fab-button
      class="{{ machineRedRollup.status }} mr-2"
      [item]="machineRedRollup"
      [size]="size"
      [customTooltip]="'Machine Red Visitors - Human Review Required'"
    >
    </app-screening-fab-button>
    <app-screening-fab-button
      class="{{ greenRollup.status }} mr-2"
      [item]="greenRollup"
      [size]="size"
    >
    </app-screening-fab-button>
    <button
      mat-mini-fab
      matTooltip="All Other Visitors"
      disableRipple
      #otherScreeningFab
      [matBadgeSize]="size"
      [matBadge]="otherRollup.total"
      matBadgeColor="primary"
      [ngClass]="size + '__fab'"
      class="screening__fab mat-elevation-z0"
    >
      <mat-icon class="screening__fab__icon__medium" svgIcon="alpha-o-circle">
      </mat-icon>
    </button>
  </div>
</div>
