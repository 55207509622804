import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import { getArrayKeys } from '@app/shared/components/advanced-search/filter-config';
import {
  FilterParams,
  ForeignVisitor,
  PageableCollection,
} from '@app/shared/models';
import { CrudService, FvService, Repository } from '@app/shared/services';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-fv-workspace',
  templateUrl: './fv-workspace.component.html',
  styleUrls: ['./fv-workspace.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FvService }],
})
export class FvWorkspaceComponent implements OnInit {
  filterParams = this.repo.defaultFilterParams;
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly authService: AuthService = inject(AuthService);
  fvs$: Observable<PageableCollection<ForeignVisitor>> =
    this.repo.collection$.pipe(
      tap(() => {
        this.loading = false;
      })
    );

  loading = false;
  constructor(private repo: Repository<ForeignVisitor>) {}

  ngOnInit(): void {
    this.loading = true;
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    const userId: string = this.authService.getUser()?.id as string;
    this.filterParams.currentUserCreatedBy = savedValue?.fv?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot(...getArrayKeys(FvAdvancedSearch));
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
