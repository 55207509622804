import { NetworkDomain } from '@app/shared/models/network-domain';
import { GroupViewRoles } from '@app/shared/models/role-permissions';
import {
  ACTION_COLUMN,
  NETWORK_COLUMN,
} from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const GROUP_TABLE_NAME = 'groupTable';
export interface GroupColumnDefs {
  name: CitadelColumnDef;
  description: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  type: CitadelColumnDef;
  fnCount: CitadelColumnDef;
  networkDomain: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const GROUP_COLUMNS: GroupColumnDefs = {
  name: {
    label: 'Name',
    hidden: false,
    isSortable: true,
    sortField: 'name',
    columnIndex: 0,
  },
  description: {
    label: 'Description',
    hidden: false,
    isSortable: true,
    sortField: 'description',
    columnIndex: 1,
  },
  createdDate: {
    label: 'Created Date',
    hidden: false,
    isSortable: true,
    sortField: 'createdDate',
    columnIndex: 2,
  },
  type: {
    label: 'Group Type',
    hidden: false,
    isSortable: true,
    sortField: 'foreignVisitorGroupType',
    columnIndex: 3,
  },
  fnCount: {
    label: 'Foreign National Count',
    hidden: false,
    isSortable: true,
    sortField: 'foreignVisitorCount',
    columnIndex: 4,
  },
  networkDomain: {
    ...NETWORK_COLUMN,
    columnIndex: 5,
  },
  actions: ACTION_COLUMN,
};

export type GROUP_COLUMN_KEYS = keyof typeof GROUP_COLUMNS;

export interface GroupPrefSaved extends BaseSavedDef {
  showColumn: { [key in GROUP_COLUMN_KEYS]: boolean };
}

export interface GroupTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in GROUP_COLUMN_KEYS]: CitadelColumnDef };
}

export const GROUP_TAB: GroupTabDef = {
  viewDefKey: 'group',
  defaultMineOnly: false,
  viewType: 'card',
  columns: GROUP_COLUMNS,
  viewName: 'Group Management',
  pageLink: '/groups',
  roles: GroupViewRoles,
  tableName: '',
  domains: [NetworkDomain.J, NetworkDomain.U],
};
