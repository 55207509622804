import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';

//Components
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { AdminViewRoles } from '@shared/models/role-permissions';
import { AccountsComponent } from './accounts/accounts.component';
import { VIEW_DEF_DEFINITIONS } from '@app/user-preference/constants/view-properties.const';

const routes: Routes = [
  {
    path: 'admin',
    title: 'User Administration',
    component: AccountsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: AdminViewRoles,
      viewDefKey: VIEW_DEF_DEFINITIONS.userAdministration.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
