import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UserFeedbackDialogComponent } from '../user-feedback-dialog/user-feedback-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserFeedback } from '@app/shared/models/user-feedback.model';
import { User } from '@app/shared/models';

@Directive({
  selector: '[appUserFeedbackView]',
  standalone: true,
})
export class UserFeedbackViewDirective {
  @Input() userFeedback: UserFeedback;
  @Input() appUserFeedbackView: UserFeedback | undefined;
  @Output() appFeedbackDialogOpen = new EventEmitter<
    MatDialogRef<UserFeedbackDialogComponent, UserFeedback>
  >();
  @Output() appUserFeedbackViewed = new EventEmitter<UserFeedback>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['event'])
  onClick(_e: Event) {
    const dialog = UserFeedbackDialogComponent.openDialog(
      this.dialog,
      this.appUserFeedbackView
    );
    dialog.afterClosed().subscribe((result: UserFeedback) => {
      if (result) return this.appUserFeedbackViewed.emit(result);
    });

    this.appFeedbackDialogOpen.emit(dialog);
  }
}
