import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Address } from '../models';
import { Crud, CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/addresses`,
  entity: 'Address',
  hasIdPathUpdate: true,
})
export class AddressService extends CrudService<Address> {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAddressSuggestions(
    address: string,
    countryCode?: string
  ): Observable<any> {
    let url = `${environment.apiUrl}` + '/addresses/suggestion/' + address;
    if (countryCode) url += '?filterCountryCodes=' + countryCode;
    return this.http.get<any>(url);
  }

  public getSelectedAddress(placeId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}` + '/addresses/detail/' + placeId
    );
  }

  public resolveLocations() {
    return this.http.put<any>(
      `${environment.apiUrl}` + '/addresses/resolveLocations',
      {}
    );
  }
}
