import { SortSelect } from '@app/shared/constants/shared.const';
/**
 * @deprecated
 */
export enum UserSortField {
  SURNAME = 'surname',
  CREATED_DATE = 'createdDate',
  EMAIL = 'email',
  STATUS = 'accountStatus',
  ORGANIZATION = 'organization.shortName',
  PHONE = 'phone',
  LAST_LOGIN = 'lastLogin',
  ROLES = 'roles',
}

export const UserSortFieldLabels: { [key in UserSortField]: string } = {
  [UserSortField.SURNAME]: 'System User',
  [UserSortField.CREATED_DATE]: 'Created Date',
  [UserSortField.EMAIL]: 'Email',
  [UserSortField.STATUS]: 'Account Status',
  [UserSortField.ORGANIZATION]: 'Organization',
  [UserSortField.PHONE]: 'Phone',
  [UserSortField.LAST_LOGIN]: 'Last Login',
  [UserSortField.ROLES]: 'Roles',
};

export interface UserMetrics {
  totalCount?: number;
  preRegisteredAccountStatusCount?: number;
  activeAccountStatusCount?: number;
  inActiveAccountStatusCount?: number;
  expiringCount?: number;
  noLoginLast30DaysCount?: number;
}

export const UserSorts: SortSelect[] = Object.values(UserSortField).map(
  (value) => {
    return { label: UserSortFieldLabels[value], value };
  }
);

export const UserTableColumns: string[] = [
  UserSortField.SURNAME,
  UserSortField.EMAIL,
  UserSortField.PHONE,
  UserSortField.ORGANIZATION,
  UserSortField.STATUS,
  UserSortField.LAST_LOGIN,
  UserSortField.ROLES,
  'actions',
];

export const UserTableName = 'userTable';
