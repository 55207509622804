import { Component } from '@angular/core';
import { AppConfigValue } from '@app/shared/constants/shared.const';
import { Role } from '@app/shared/models';
import {
  AdminViewRoles,
  FarViewRoles,
  FVViewRoles,
  LocViewRoles,
} from '@shared/models/role-permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workspace-dashboard',
  templateUrl: './workspace-dashboard.component.html',
  styleUrls: ['./workspace-dashboard.component.scss'],
})
export class WorkspaceDashboardComponent {
  /**
   * @TODO Consider moving the tabs into child routes.  Will allow them to have separate queryparams and greater flexibility.
   * It might also allow some reduction in the code and greater reuse.
   * **/
  @AppConfigValue('disableUserAdmin')
  disableUserAdmin: boolean;
  insight$: Subscription;
  Role = Role;
  FVViewRoles = FVViewRoles;
  FarViewRoles = FarViewRoles;
  LocViewRoles = LocViewRoles;
  AdminViewRoles = AdminViewRoles;
}
