import { NetworkDomain } from '@app/shared/models/network-domain';
import { MetricsViewRoles } from '@app/shared/models/role-permissions';
import { BaseViewDef } from '../model/config-view-definitions.model';

export interface MetricsTabDef extends BaseViewDef {}

export const METRICS_TAB: MetricsTabDef = {
  viewDefKey: 'metrics',
  viewName: 'Metrics',
  pageLink: '/metrics',
  roles: MetricsViewRoles,
  columns: {},
  tableName: '',
  domains: [NetworkDomain.J, NetworkDomain.U],
};
