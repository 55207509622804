import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserPreferenceColumnCheckboxesComponent } from '@app/user-preference/components/user-preference-column-checkboxes/user-preference-column-checkboxes.component';
import { FarCardComponent } from '@shared/components/far-card/far-card.component';
import { FarEventComponent } from '@shared/components/far-card/far-event/far-event.component';
import { FarFvsRollupComponent } from '@shared/components/far-card/far-fvs-rollup/far-fvs-rollup.component';
import { FarHeaderComponent } from '@shared/components/far-card/far-header/far-header.component';
import { FarMetadataComponent } from '@shared/components/far-card/far-metadata/far-metadata.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgPipesModule } from 'ngx-pipes';
import { CmtModule } from '../cmt/cmt.module';
import { DeactivateDialogComponent } from '../deactivation/components/deactivate-dialog/deactivate-dialog.component';
import { DirectivesModule } from '../directives/directives.module';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import {
  AvatarComponent,
  CreateFarDirective,
  FarFromFvDialogComponent,
  FieldComponent,
  ItineraryControlComponent,
  ItineraryDialogComponent,
  ItineraryDisplayComponent,
  OrgLocationSelectComponent,
  ProfilePicComponent,
  ProfilePicSelectDirective,
  QuickFarControlComponent,
} from './';
import { ScreeningModule } from './screening/screening.module';
import { FARDateValidatorDirective } from '../directives';

const SharedComponents: any[] = [
  FarFromFvDialogComponent,
  CreateFarDirective,
  ItineraryControlComponent,
  ItineraryDialogComponent,
  ItineraryDisplayComponent,
  OrgLocationSelectComponent,
  QuickFarControlComponent,
  AvatarComponent,
  ProfilePicComponent,
  ProfilePicSelectDirective,
  FieldComponent,
  FarCardComponent,
  FarHeaderComponent,
  FarMetadataComponent,
  FarFvsRollupComponent,
  FarEventComponent,
];

@NgModule({
  declarations: [...SharedComponents, DeactivateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    CmtModule,
    DirectivesModule,
    PipesModule,
    NgPipesModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    ScreeningModule,
    UserPreferenceColumnCheckboxesComponent,
    FARDateValidatorDirective,
  ],
  exports: [...SharedComponents, ScreeningModule, FARDateValidatorDirective],
})
export class ComponentsModule {}
